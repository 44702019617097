import { Button, CssBaseline, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import DocumentCard from "../../../../components/V3/DocumentCard";
import UploadCard from "../../../../components/V3/UploadCard";
import loanAgreeIco from "../../../../assets/tabs/loanAgreeIco.svg";
import stampIco from "../../../../assets/tabs/stampIco.svg";
import dualFormIco from "../../../../assets/tabs/dualFormIco.svg";
import ContinueUpload from "../../../../components/V2/ContinueUpload";
import MultiDocUpload from "../../../../components/V2/MultiDocUpload";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { uploadDocument } from "../../../../api/agent";
import MenuAppBar from "../../../../components/Appbar";
import { useNavigate } from "react-router-dom";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { UserContext } from "../../../../Contexts/UserContext";
import { l } from "../../../../util/languageConvertor";
import { GlobalContext } from "../../../../Contexts/GlobalContext";

const LoanDocuments = ({ application_id, openStatus }) => {
  const { user } = useContext(UserContext);
  const [multiData, setMultiData] = useState({ show: false });
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();

  const { langState, docPopupState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [docPopup, setDocPopup] = docPopupState;

  const { getApplicationDetails } = useApplication();

  const handleUpload = async (file, name) => {
    setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        application_id,
        `?override=true`
      );
      if (data?.mandatory_completed) {
        setDocPopup({ open: true, type: "DONE" });
      }
      getApplicationDetails(application_id, true);
    } catch (error) {}
    setLoading("");
  };

  return (
    <>
      <MenuAppBar
        home={false}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
        leftContent={
          <Button
            color="secondary"
            onClick={() => {
              MixPanelAudit.intercept("check_status_click",{
                distinct_id : user?.mobile_number[0],
                agent_name : user?.name,
                application_id
              })
              openStatus();
            }}
          >
            {l("Check Status",lang)}
          </Button>
        }
      />
      <CssBaseline />
      <div>
        <Grid container spacing={2} className="mt-1">
          <Grid item xs={6} sm={6}>
            {/* <DocumentCard
            tempState={""}
            text={"Loan agreement"}
            isRequired={true}
            icon={loanAgreeIco}
          /> */}

            <ContinueUpload
              icon={loanAgreeIco}
              isRequired={true}
              name={l(`Loan Agreement`, lang)}
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 30,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "loan_agreement",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "loan_agreement");
              }}
              documents={journeyData?.documents?.loan_agreement}
              started={journeyData?.documents?.loan_agreement?.[0]}
              completed={journeyData?.documents?.loan_agreement?.[0]?.completed}
              status={journeyData?.documents?.loan_agreement?.[0]?.isApproved}
              pages={journeyData?.documents?.loan_agreement?.length}
              isMulti={
                journeyData?.documents?.loan_agreement?.[0]?.meta_page_document
              }
              setState={setMultiData}
              isLoading={loading === "loan_agreement"}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            {/* <DocumentCard
              tempState={""}
              text={"Stamp paper"}
              isRequired
              icon={stampIco}
            /> */}

            <ContinueUpload
              icon={stampIco}
              isRequired={true}
              name={l(`Stamp Paper`, lang)}
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 5,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "stamp_paper",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "stamp_paper");
              }}
              documents={journeyData?.documents?.stamp_paper}
              started={journeyData?.documents?.stamp_paper?.[0]}
              completed={journeyData?.documents?.stamp_paper?.[0]?.completed}
              status={journeyData?.documents?.stamp_paper?.[0]?.isApproved}
              pages={journeyData?.documents?.stamp_paper?.length}
              isMulti={
                journeyData?.documents?.stamp_paper?.[0]?.meta_page_document
              }
              setState={setMultiData}
              isLoading={loading === "stamp_paper"}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Dual Form", lang)}
              isRequired={true}
              icon={dualFormIco}
              tempState={
                journeyData?.documents?.dual_form?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.dual_form?.length > 0}
              status={
                journeyData?.documents?.dual_form?.slice(-1)[0]?.isApproved
              }
              isLoading={loading === "dual_form"}
              setFile={(file) => {
                handleUpload(file, "dual_form");
              }}
            />
          </Grid>
        </Grid>
      </div>

      {multiData?.show && (
        <MultiDocUpload
          state={multiData}
          setState={setMultiData}
          onClose={() => {
            getApplicationDetails(application_id, true);
          }}
        />
      )}
    </>
  );
};

export default LoanDocuments;
