import { environment } from "../config";

/**
 * Represents a MixPanelAudit object.
 * @constructor
 */
const MixPanelAudit = function () {
  const _ = this;
  _.transports = [];

  /**
   * Initializes the MixPanelAudit object with transports.
   * @param {Array} transports - An array of transport objects.
   */
  _.init = function (transports) {
    for (const transport of transports) {
      _.addTransport(transport);
    }
  };

  /**
   * Builds an API audit record.
   * @param {string} event - The event to log.
   * @param {object} properties - Additional properties to log.
   * @returns {object|null} - An API audit record object, or null if event and properties are not provided.
   */
  _.buildApiAuditRec = function (event, properties) {
    properties = {
      ...properties,
      type: "client",
      environment: ["production", "beta"].includes(environment)
        ? "production"
        : "development",
    };
    if (event && properties) {
      return {
        event,
        properties,
      };
    }
    return null;
  };

  /**
   * Emits data to all registered transports.
   * @param {object} eventPackage - The event package to emit.
   */
  _.emitData = function (eventPackage) {
    for (const transport of _.transports) {
      transport.track(eventPackage);
      // transport.identify(eventPackage?.properties?.mobile || "visitor");
    }
  };

  /**
   * Logs an API event with MixPanelAudit.
   * @param {string} event - The event to log.
   * @param {object} properties - Additional properties to log.
   */
  _.logApi = function (event, properties) {
    let eventPackage = _.buildApiAuditRec(event, properties);
    _.emitData(eventPackage);
  };

  /**
   * Intercepts an API event and logs it with MixPanelAudit.
   * @param {string} event - The event to log.
   * @param {object} properties - Additional properties to log.
   */
  _.intercept = function (event, properties) {
    // make proper payload in future
    _.logApi(event, properties);
  };

  /**
   * Adds a transport to the list of registered transports.
   * @param {object} transport - The transport object to add.
   */
  _.addTransport = function (transport) {
    _.transports.push(transport);
  };

  return _;
};

export default new MixPanelAudit();
