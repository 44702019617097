import { Grid } from "@mui/material";
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import { useEffect } from "react";
import CaptureUpload from "../../components/CaptureV2";
import { kycContext } from "../../Contexts/KycContext";
import { UserContext } from "../../Contexts/UserContext";
import { validateGST } from "../../validations/docValidations";

const GstPage = forwardRef((props, ref) => {
  const { kycDataState, gstDataState } = useContext(kycContext);
  const { user } = useContext(UserContext);
  const [gstData, setGstData] = gstDataState;

  const [tradeError, setTradeError] = useState(false);
  const [tradeErrorTxt, setTradeErrorTxt] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    gstin: "",
    tradeLicense: "",
  });

  const [gstResponse, setGstResponse] = useState({
    avail: false,
    msg: "",
    loading: false,
  });

  const isValid = () => {
    let valid = true;

    if (gstData.gstin === "" && gstData.tradeLicense === "") {
      valid = false;
    }

    if (gstData.gstin !== "" && !gstResponse.avail) {
      validateGST(gstData.gstin, setGstResponse);
      valid = false;
    }

    // if (gstData.tradeLicense === "") {
    //   valid = false;
    //   setTradeError(true);
    // }
    return valid;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      if (isValid()) {
        return true;
      }
    },
  }));


  return (
    <Grid container spacing={2}>
      {!user?.gstStage?.gst && (
        <Grid item xs={12} sm={12}>
          <label className="form-label">GSTIN</label>

          <input
            className="form-input w-100"
            autoComplete="given-name"
            name="gstinnumber"
            maxlength="15"
            required
            fullWidth
            placeholder="Enter your GSTIN"
            value={gstData.gstin}
            onChange={(e) => {
              if (
                /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)
              )
                return;
              setGstData((st) => ({
                ...st,
                gstin: e.target.value.toUpperCase(),
              }));
              if (e.target.value.length >= 15) {
                validateGST(e.target.value.toUpperCase(), setGstResponse);
              }
              if (e.target.value.length > 0 && e.target.value.length < 15) {
                setGstResponse((p) => ({ ...p, avail: false, msg: "" }));
              }
            }}
            onBlur={(e) => {
              if (e.target.value !== "") {
                validateGST(e.target.value.toUpperCase(), setGstResponse);
              }
            }}
          />

          {!gstResponse.avail && !gstResponse.loading && (
            <span className="error">{gstResponse.msg}</span>
          )}
        </Grid>
      )}

      {!user?.gstStage?.trade_license && (
        <Grid item xs={12} sm={12} className="w-100">
          <label className="form-label">Trade License</label>
          <CaptureUpload
            error={tradeError}
            errorTxt={tradeErrorTxt}
            setFile={(file) => {
              console.log(file);
              setTradeError(false);
              setTradeErrorTxt("");
              setGstData((prev) => ({ ...prev, tradeLicense: file }));
            }}
            tempState={gstData.tradeLicense}
          />
        </Grid>
      )}
    </Grid>
  );
});

export default GstPage;
