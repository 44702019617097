import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { UserContext } from "../Contexts/UserContext";
import { ImExit } from "react-icons/im";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useLogout from "../hooks/AuthScreens/Agent/useLogout";
import coinIco from "../assets/coin.png";

// import { incentiveData } from "../api/agent";
import { useState } from "react";
import ChangeLanguageMenu from "./ChangeLangueMenu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge } from "@mui/material";
import { GlobalContext } from "../Contexts/GlobalContext";

export default function MenuAppBar({
  home,
  action,
  text,
  bigText,
  active,
  draft,
  noBack,
  shadow,
  leftContent,
}) {
  const [auth, setAuth] = React.useState(true);
  const [coin, setCoin] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { user } = React.useContext(UserContext);
  const { notiCountState } = React.useContext(GlobalContext);
  const [notiCount, setNotiCount] = notiCountState;

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDraft = () => {
    navigate("/draft-applications");
  };

  const { logoutUser } = useLogout();

  // const getCoinData = async () => {
  //   try {
  //     let { data } = await incentiveData(false, true, "");
  //     if (data?.status) {
  //       setCoin(data?.totalAgentIncentiveData?.total_coins || 0);
  //     }
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   getCoinData();
  // }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {home ? (
        <AppBar
          position="fixed"
          sx={{ backgroundColor: "white", boxShadow: 0 }}
        >
          <div className="w-100 d-flex justify-content-center">
            <Toolbar
              className={`w-100 response-wrapper ${active === 0 && "mt-3"}`}
            >
              <p className={`appbar-name ${active === 0 && "name-home"}`}>
                {text}
              </p>

              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              ></IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>

              <div className="d-flex">
                {/* {user?.incentives_enabled && (
                  <span
                    className="pointer"
                    onClick={() => {
                      navigate("/rewards");
                    }}
                  >
                    <img src={coinIco} width="30px" />{" "}
                    <span style={{ color: "black", fontWeight: 500 }}>
                      {coin}
                    </span>
                  </span>
                )} */}

                {/* <NotificationsIcon sx={{ color: "rgba(102, 112, 128, 1)" }} /> */}

                <ChangeLanguageMenu />

                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  className="ms-1 d-none"
                  onClick={() => {
                    navigate('/notifications')
                  }}
                  color="inherit"
                >
                  <Badge
                    badgeContent={notiCount}
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "white",
                        backgroundColor: "green",
                      },
                    }}
                  >
                    <NotificationsNoneIcon
                      sx={{ color: "rgba(102, 112, 128, 1)" }}
                    />
                  </Badge>
                  {/* <PowerSettingsNewIcon
                    sx={{ color: "rgba(102, 112, 128, 1)" }}
                  /> */}
                </IconButton>
              </div>
            </Toolbar>
          </div>
        </AppBar>
      ) : (
        <AppBar
          position="fixed"
          sx={
            shadow
              ? { backgroundColor: "white" }
              : { backgroundColor: "white", boxShadow: 0 }
          }
        >
          <div className="w-100 d-flex justify-content-center">
            <Toolbar className="w-100 response-wrapper">
              {!noBack && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    action && action();
                  }}
                >
                  <ArrowBackIcon sx={{ color: "black" }} />
                </IconButton>
              )}
              <p className={`appbar-name`}>{text}</p>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, color:'black' }}
              >{bigText}</Typography>

              {draft && (
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleDraft}
                    color="inherit"
                  >
                    <ExitToAppIcon sx={{ color: "#161716" }} />
                  </IconButton>
                </div>
              )}

              {leftContent && <div>{leftContent}</div>}
            </Toolbar>
          </div>
        </AppBar>
      )}
    </Box>
  );
}
