import "../App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import UserContextProvider, { UserContext } from "../Contexts/UserContext";
import GlobalContextProvider, {
  GlobalContext,
} from "../Contexts/GlobalContext";
import PrivateRoute from "../pages/PrivateRoute";
import Register from "../pages/Register";
import Landing from "../pages/Landing";
import Home from "../pages/HomeLayout";
import NotFound from "../pages/NotFound";
import KycLayout from "../pages/kycSteps/KycLayout";
import KycContextProvider from "../Contexts/KycContext";
import OtpScreen from "../pages/OtpScreen";
import ResetPassword from "../pages/ResetPassword";
import RegisterName from "../pages/RegisterName";
import PublicRoute from "../pages/PublicRoute";
import { Fragment, useContext, useEffect } from "react";
import PermissionModal from "../components/PermissionModal";
import usePermission from "../hooks/usePermission";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { withSnackbar, useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useState } from "react";
import CustomerContextProvider from "../Contexts/CustomerContext";
import RecentApplication from "../pages/Applications/RecentApplications";
import ViewApplications from "../pages/ViewApplication/ViewLayout";
import Profile from "../pages/HomeScreens/Profile";
import CustomerProfile from "../pages/ViewApplication/Profile";
import LoanDetails from "../pages/ViewApplication/LoanDetails";
// import AllApplications from "../pages/Applications/AllApplications";
import Collections from "../pages/ViewApplication/Collections";
import AllCollections from "../pages/Collections/Collections";
import Test from "../pages/Test";
import PageUnder from "../pages/PageUnder";
import LoanJourney from "./v1/LoanRoutes";
import LoanContextProvider from "../Contexts/LoanJourneyContext";
import DraftApplication from "../pages/Applications/v1/DraftApplications";
import AllApplications from "../pages/Applications/v1/AllApplications";
import RewardSummary from "../pages/Rewards/RewardSummary";
import ImageEditor from "../components/V2/Edittor/ImageEditor";
import RewardsFlyer from "../pages/Rewards/RewardsFlyer";
import LoginScreen from "../pages/Login";
import LanguageSelection from "../pages/LanguageSelection";
import { environment } from "../config";
import { switchCookie } from "../api/agent";
import NotificationScreen from "../pages/HomeScreens/Notification";
import NotificationPopup from "../components/V3/NotificationPopup";

export default function AgentFlow() {
  const { globalLoaderState } = useContext(GlobalContext);
  const [globalLoader, setGlobalLoader] = globalLoaderState;

  return (
    <UserContextProvider>
      <KycContextProvider>
        <CustomerContextProvider>
          <LoanContextProvider>
            <Routes>
              <Route element={<PublicRoute />}>
                <Route exact path="" element={<LoginScreen />} />
                <Route path="register" element={<Register />} />
                <Route path="otp/:vId" element={<OtpScreen />} />
                {/* <Route path="reset-password" element={<ResetPassword />} /> */}
              </Route>

              <Route element={<PrivateRoute />}>
                <Route path="complete-signup" element={<RegisterName />} />
                <Route path="home" element={<Home />} />
                <Route path="home/:tab" element={<Home />} />
                <Route path="kyc" element={<KycLayout />} />
                <Route path="kyc/:stage" element={<KycLayout />} />
                <Route path="sent-otp/:vId" element={<OtpScreen />} />
                <Route path="create-loan/*" element={<LoanJourney />} />

                  <Route
                    path="draft-applications"
                    element={<AllApplications />}
                  />

                  <Route
                    path="all-applications"
                    element={<AllApplications />}
                  />

                  <Route
                    path="all-applications/:filterType"
                    element={<AllApplications />}
                  />

                  <Route
                    path="/language-selection"
                    element={<LanguageSelection />}
                  />
                  <Route
                    path="/notifications"
                    element={<NotificationScreen />}
                  />
                </Route>

                <Route path="rewards" element={<RewardSummary />} />
                <Route
                  path="draft-applications"
                  element={<DraftApplication />}
                />

                <Route path="all-applications" element={<AllApplications />} />

                {/* <Route element={<NotFound />} /> */}
              </Routes>
              <NotificationPopup />
            </LoanContextProvider>
          </CustomerContextProvider>
        </KycContextProvider>
      </UserContextProvider>
  );
}
