import { Dialog } from "@mui/material";
import React, { useContext } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";

const GlobalLoader = () => {
  const { globalLoaderState } = useContext(GlobalContext);
  const [globalLoader, setGlobalLoader] = globalLoaderState;
  
  return (
    <Dialog
      fullScreen
      open={globalLoader}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          backdropFilter: "blur(20px)",
        },
      }}
      // onClose={handleClose}
    >
      <div className="wrapper">
        <div className="loader">
          <div className="loader__part loader__part--1"></div>
          <div className="loader__part loader__part--2"></div>
          <div className="loader__part loader__part--3"></div>
        </div>
      </div>
    </Dialog>
  );
};

export default GlobalLoader;
