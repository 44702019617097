import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logout } from '../api';
import { UserContext } from '../Contexts/UserContext';
import { useContext } from 'react';

export default function useLogout() {

    let history = useNavigate();

    const {setUser} = useContext(UserContext)

    const logoutUser = async () => {
        try {

           await logout()
           setUser(null)

        } catch(err) {
            console.log(err);
        } 
    }

    return {
        logoutUser
    }

}