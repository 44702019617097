import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Buffer } from "buffer";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/Camera";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { FiCamera, FiImage, FiX } from "react-icons/fi";
import { GrGallery } from "react-icons/gr";

import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Webcam from "react-webcam";
import { CircularProgress, Grid } from "@mui/material";
import ImageCropper from "../components/Crop";
import CropIcon from "@mui/icons-material/Crop";
import usePermission from "../hooks/usePermission";
import PermissionModal from "./PermissionModal";
import { useContext } from "react";
import { GlobalContext } from "../Contexts/GlobalContext";
// import { getUserLocation } from "../util/locationUtility";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CatureUpload({
  setFile,
  camera,
  setError,
  onSelect,
  text,
  error,
  getLocation,
  preview,
  tempState,
  selfie,
}) {
  const { showPermissionState } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isSelfie, setSelfie] = useState(true);
  const [captureImage, setImage] = useState("");
  const [confirmFilename, setConfirmFilename] = useState("");

  const fileInput = useRef();
  const sliderBottom = useRef();
  const backdrop = useRef();

  const [cropImgTemp, setTempCrop] = useState("");
  const [croppedImg, setCropImg] = useState("");
  const [location, setLocation] = useState({ status: "", lat: "", long: "" });

  const { permissions, getUserLocation } = usePermission();
  const [showPermission, setShowPermission] = showPermissionState;

  const handleClickOpen = () => {
    setIsCameraOpen(true);
  };
  const handleClose = () => {
    // setOpen(false);
    setIsCameraOpen(false);
  };

  const handleRecapture = () => {
    onSelect && onSelect();
    setConfirmFilename("");
    setImage("");
    setFile && setFile("");
    handleClickOpen();
  };

  useEffect(() => {
    if (!open) {
      setIsCameraOpen(false);
      setImage("");
    }
  }, [open]);

  useEffect(() => {
    if (isCameraOpen) {
      getUserLocation(setLocation);
    }
  }, [isCameraOpen]);

  useEffect(() => {
    if (croppedImg !== "") {
      onSelect && onSelect();
      setFile && setFile(croppedImg);
    }
  }, [croppedImg]);

  useEffect(() => {
    if (location.status === "success") {
      getLocation && getLocation(location);
    }
  }, [location]);

  return (
    <>
      <>
        {tempState === "" ? (
          <div
            className="capture-only pointer"
            onClick={() => {
              if (permissions.camera === "PROMPT") {
                setShowPermission((st) => ({
                  ...st,
                  state: true,
                  action: handleClickOpen,
                }));
              } else {
                handleClickOpen();
              }
            }}
          >
            <div className="capture-content-v2">
              <CameraAltIcon
                className="me-2"
                sx={{ color: "rgba(108, 99, 255, 1)" }}
              />
              <span className="input-placeholder-capture">
                {text ? text : "Click here to Upload/Capture"}{" "}
                <span className="mand-star">*</span>
              </span>

              {error && (
                <>
                  <br></br>
                  <span className="error ms-4 mt-2">
                    <ErrorOutlineIcon sx={{ fontSize: "15px", mr: 1 }} />
                    Upload required file
                  </span>
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <span className="input-placeholder-capture">
              {text ? text : ""} <span className="mand-star">*</span>
            </span>
            <div
              className="w-100 bg-dark d-flex justify-content-center"
              style={{ borderRadius: "8px", overflow: "hidden" }}
            >
              <img
                src={tempState}
                alt=""
                width={"100%"}
                height={"200px"}
                style={{ objectFit: "contain" }}
              />
            </div>

            <p className="mb-0 text-center mt-2">
              <span className="recapture-btn" onClick={handleRecapture}>
                <CameraAltIcon className="me-2" /> Press to Re-capture
              </span>
            </p>
          </>
        )}
      </>

      {/* /////// CAMERA ///// */}

      {isCameraOpen && (
        <div className="camera-screen">
          <CloseIcon
            className="camera-close"
            onClick={() => {
              setIsCameraOpen(false);
            }}
          />

          <div className="camera-loading">
            <div className="content-center">
              <CircularProgress />
            </div>
            Camera Loading
          </div>
          <Webcam
            audio={false}
            className={"webcamera"}
            screenshotQuality={1}
            height={"100%"}
            screenshotFormat="image/jpeg"
            width={"100%"}
            videoConstraints={{
              // width: 1280,
              // height: 720,
              facingMode: `${isSelfie ? "user" : "environment"}`,
            }}
          >
            {({ getScreenshot }) => (
              <>
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}

                <div className={`action-buttons-holder`}>
                  <div className="responsive-div">
                    <div className="action-buttons w-100">
                      {!selfie && (
                        <span
                          className="cam-flip-btn-v2"
                          onClick={() => {
                            setSelfie(!isSelfie);
                          }}
                        >
                          <FlipCameraIosIcon />
                        </span>
                      )}

                      <button
                        className="capture-btn"
                        onClick={() => {
                          const imageSrc = getScreenshot();
                          setImage(imageSrc);
                          setIsCameraOpen(false);
                          onSelect && onSelect();
                        }}
                      >
                        <CameraIcon className="me-2" />
                        Capture photo
                      </button>
                    </div>
                  </div>
                </div>

                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
              </>
            )}
          </Webcam>
        </div>
      )}

      {captureImage !== "" && (
        <div className="crop-screen">
          <CloseIcon
            className="crop-close"
            onClick={() => {
              setImage("");
            }}
          />
          <div className="content-center">
            <ImageCropper
              onImageCropped={(result) => {
                setTempCrop(result);
              }}
              imageToCrop={captureImage}
            />
          </div>
          <div className={`action-buttons-holder`}>
            <div className="responsive-div">
              <div className="action-buttons w-100">
                <button
                  className="capture-btn"
                  onClick={() => {
                    setCropImg(cropImgTemp);
                    setConfirmFilename("capture.jpg");
                    setImage("");
                  }}
                >
                  <CropIcon className="me-2" />
                  Crop and Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
