import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <IconButton
      aria-label="menu"
      style={{
        position: "fixed",
        bottom: 10,
        right: 10,
        height: 50,
        width: 50,
        display: visible ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        background: "#573a7a",
        color: "white",
      }}
      onClick={scrollToTop}
    >
      <KeyboardArrowUpIcon />
    </IconButton>
  );
};

export default ScrollButton;
