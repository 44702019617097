import {
  Autocomplete,
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slide,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeDefaultBank,
  fetchAllBanks,
  proceedApplication,
  proceedPayment,
} from "../api/agent";
import MenuAppBar from "../components/Appbar";
// import CustomInputAutocomplete from "../components/Autocomplete/AutoCompleteBank";
// import CircularProgress from "../../../../components/CircularProgress";
import { GlobalContext } from "../Contexts/GlobalContext";
import { LoanContext } from "../Contexts/LoanJourneyContext";
import useApplication from "../hooks/useApplication";
import erickIco from "../assets/ericksha.gif";
import bgCircle from "../assets/bgCircle.svg";
import OtpLogin from "./OtpLogin";
// import LoginSplashScreen from "./LoginSplashScreen";
import loginManIco from "../assets/loginImage.svg";
import { isMobValid } from "../validations/fieldValidations";
import useAuth from "../hooks/AuthScreens/Agent/useAuth";
import MixPanelAudit from "../util/MixPanelAudit";
import { l } from "../util/languageConvertor";
import { environment } from "../config";
const statusColor = {
  pending: "#274384",
  unsuccessfull: "#FF0000",
  successfull: "#2AB514",
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const LoginScreen = forwardRef((props, ref) => {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { bottomNav, showRewardState, langState } = useContext(GlobalContext);

  const [lang, setLang] = langState;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSplash, setOpenSplash] = useState(true);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");

  const { loginUser, signUpUser } = useAuth();

  // const handleSubmit = async () => {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenSplash(false);
    }, 2000);
  }, []);

  const handleSignin = async () => {
    let error = isMobValid(mobile);
    if (error === "") {
      setLoading(true);
      let response = await loginUser(mobile);
      if (response) {
        setOpen(true);
      }
      setLoading(false);
    } else {
      setError(error);
    }
    MixPanelAudit.intercept("login_submit_button_clicked", {
      distinct_id: mobile,
    });
  };

  return (
    <>
      <style>
        {`body{
            background-color: #573A7A;
        }`}
      </style>
      {!openSplash && (
        <>
          <div className="login-wrapper">
            <Box
              className="kyc-wrapper w-100 bottom-content"
              // sx={{ pt: 2 }}
              ref={ref}
            >
              <CssBaseline />
              <div className="main-wrapper">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    {/* <div className="strip">&nbsp;</div> */}

                    <p className="login-icon-holder mb-0">
                      <img
                        src={loginManIco}
                        className="login-icon-man"
                        alt=""
                        width={"200px"}
                      />
                    </p>

                    <p className="login-info-txt">
                      {/* Enter your<br></br>mobile number */}
                      {l("Enter your mobile number", lang)}
                    </p>
                    {/* <span style={{ color: "gray" }}>
                      We'll verify your mobile
                    </span> */}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <div className="mobile-text-wrapper mt-4">
                      <input
                        autoFocus
                        type="text"
                        maxLength={10}
                        inputMode="numeric"
                        placeholder="000 000 0000"
                        className="login-mobile w-100"
                        value={mobile}
                        onChange={(e) => {
                          if (
                            e.target.value !== "" &&
                            !/^\d+$/.test(e.target.value)
                          ) {
                            return false;
                          }
                          setError("");
                          if (e.target.value.length > 10) return false;
                          setMobile(e.target.value);
                        }}
                        onBlur={(e) => {
                          setError(isMobValid(e.target.value) || "");
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleSignin();
                          }
                        }}
                      />

                      <div className="country-code">+91</div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {!isLoading ? (
                      <Button className="login-btn" onClick={handleSignin}>
                        {l("Submit", lang)}
                      </Button>
                    ) : (
                      <Button
                        className="w-100"
                        style={{ color: "white" }}
                        disabled
                      >
                        Loading...
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
          <div
            className="circle-img"
            style={{ position: "absolute", bottom: 0, right: 0 }}
          >
            <img src={bgCircle} className="bg-circle" alt="" />
          </div>
        </>
      )}

      <Dialog
        fullScreen
        open={openSplash}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#573A7A",
          },
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {environment === "beta" ? (
            <div>
              <p className="text-center">
                <img src={erickIco} alt="" className="gifcorrect" width={200} />
              </p>
              <p className="text-center" style={{ color: "white" }}>
                <span
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "white",
                    color: "purple",
                    borderRadius: "5px",
                  }}
                >
                  Beta
                </span>{" "}
                Version
              </p>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={erickIco} alt="" className="gifcorrect" width={200} />
              {/* <CircularProgress style={{ color: "white" }} /> */}
            </div>
          )}
        </div>
      </Dialog>

      {/* <LoginSplashScreen open={openSplash} /> */}

      {open && <OtpLogin open={open} handleClose={handleClose} />}
    </>
  );
});

export default LoginScreen;