import { Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";
import MixPanelAudit from "../../util/MixPanelAudit";
import { l } from "../../util/languageConvertor";
import { GlobalContext } from "../../Contexts/GlobalContext";

const LoanScreen = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  return (
    <>
      <div className="content-center">
        <Grid container spacing={2} className="p-3 response-wrapper">
          <Grid item xs={12} sm={12} className="mt-4">
            {/* <Button
              className="w-100 home-btn"
              variant="outlined"
              onClick={() => {
                navigate("/recent-applications");
              }}
            >
              Recently Submitted
            </Button> */}

            <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                MixPanelAudit.intercept("draft_click", {
                  distinct_id: user?.mobile_number[0],
                  agent_name: user?.name,
                });
                navigate("/draft-applications");
              }}
            >
              {l("Draft Applications", lang)}
            </Button>

            <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                navigate("/all-applications");
              }}
            >
              {l("All Applications", lang)}
            </Button>

            {/* <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                navigate("/all-applications");
              }}
            >
              All Applications
            </Button>

            <Button className="w-100 home-btn mt-3" variant="outlined" onClick={() => navigate("/under-construction")}>
              Amount Recieved
            </Button>

            <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                navigate("/collections");
              }}
            >
              Collections
            </Button> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoanScreen;
