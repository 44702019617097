import { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  getEncryptedToken,
  switchCookie,
  verifyAuth,
} from "../../../api/agent";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { environment } from "../../../config";

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { globalLoaderState, isBetaEnabled } = useContext(GlobalContext);
  const [globalLoader, setGlobalLoader] = globalLoaderState;
  const [token, setToken] = useState("");
  const { search } = useLocation();
  const navigate = useNavigate()

  const setupCookie = async (jwttoken) => {
    setGlobalLoader(true);
    console.log("Setting up cookie " + jwttoken);
    try {
      let {data} = await switchCookie({
        token:jwttoken,
      });
      window.location.reload()
    } catch (err) {
      console.log(err);
    }
    setGlobalLoader(false);
  };

  const handleRedirection = async (link) => {
    let token = "";
    try {
      let { data } = await getEncryptedToken();
      token = data?.token;
      console.log(token);
    } catch (error) {}
    console.log("Redirect-> " + `${link}?redirect=${encodeURIComponent(token)}`);
    window.open(`${link}?redirect=${encodeURIComponent(token)}`, "_self");
  };

  const handleEnvironment = async (user) => {
    console.log("Calledddd", user);
    if (isBetaEnabled) {
      if (user?.is_beta_tester && environment === "production") {
        handleRedirection("https://www.beta.erick.embifi.in");
      } else if (environment === "beta") {
        if (!user?.is_beta_tester) {
          handleRedirection("https://www.erick.embifi.in");
        }
      }
    } else if (environment === "beta") {
      handleRedirection("https://www.erick.embifi.in");
    }
  };

  useEffect(() => {
    async function findUser() {
      setLoading(true);
      try {
        let { data } = await verifyAuth();
        setLoading(false);
        handleEnvironment({ ...data.data, type: "AGENT" });
        setUser(data.data);
      } catch (err) {
        const searchParams = new URLSearchParams(search);
        const isRedirect = searchParams.get("redirect");
        if (isRedirect?.length > 0) {
          setupCookie(isRedirect);
        } else {
          setUser(null);
        }

        setLoading(false);
      }
    }

    findUser();
  }, []);

  //   useEffect(()=>{
  //     if(user){
  //         localStorage.setItem("userData", JSON.stringify(user));
  //     }else{
  //         localStorage.removeItem("userData");
  //     }
  //   },[user])

  return {
    user,
    setUser,
    isLoading,
    handleEnvironment
  };
}
