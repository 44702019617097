// import { validateGst, validatePan } from "../api";
export const validatePAN = async (pan_no, setResponse, type) => {
  if (pan_no === "") {
    setResponse({ avail: false, msg: "Cannot be blank", loading: false });
    return;
  }

  let pan_pattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  if (pan_pattern.test(pan_no)) {
    if (type === "PPAN" && pan_no[3] !== "P") {
      setResponse({
        avail: false,
        msg: "Enter personal PAN only",
        loading: false,
      });
      return;
    }
    if (type === "CPAN" && pan_no[3] === "P") {
      setResponse({
        avail: false,
        msg: "Enter Corporate PAN only",
        loading: false,
      });
      return;
    }

    setResponse({ avail: true, msg: "", loading: false });
    return;
  }

  setResponse({ avail: false, msg: "Invalid PAN format", loading: false });
};

export const validateGST = async (gst_no, setResponse) => {
  let gst_pattern =
    /^([0][1-9]|[1-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
  if (gst_pattern.test(gst_no)) {
    setResponse((prev) => ({ ...prev, loading: true, avail: true }));
    return;
  }
  setResponse({ avail: false, msg: "Invalid GST format", loading: false });
};

export const validateAadhar = (num) => {
  if (/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(num)) {
    return "";
  }
  return "Invalid aadhar number";
};

export const isAnnualIncomeValid = (val) => {
  if (val[0] === "0") {
    return "Income Should not start with 0";
  }
  if (Number(val) > 1000000) {
    return "";
  }
  return "Should be greater than 10,00,000";
};

export const isVehicleCost = (val) => {
  if (val[0] === "0") {
    return "Vehicle cost should not start with 0";
  }
  if (val < 60000) return "Minimum amount should be 60,000";
  return "";
};

// export const maxLoanAmount = (val) => {
//   let percent_val = val * 0.85;
//   let max_value = 150000;

//   if (percent_val < max_value) return percent_val;
//   return max_value;
// };

export const maxLoanAmount = (ex_price, on_price) => {
  let ex_percentage = Number(ex_price) * 0.85;
  let on_percentage = Number(on_price) * 0.75;
  let fix_value = 135000;

  console.log(ex_percentage+" "+on_percentage+" "+fix_value);
  return Math.min(ex_percentage, on_percentage, fix_value);
};

export const maxLoanAmountVani = (ex_price, on_price) => {
  let ex_percentage = Number(ex_price) * 0.85;
  let on_percentage = Number(on_price) * 0.75;
  let fix_value = 110000;

  console.log(ex_percentage+" "+on_percentage+" "+fix_value);
  return Math.min(ex_percentage, on_percentage, fix_value);
};

export const isRegistrationValid = (val) => {
  if (/^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{3,4}$/.test(val)) return "";
  return "Please Enter valid registrion number";
};

export const isValidChasis = (val) => {
  if (
    /^(([a-h,A-H,j-n,J-N,p-z,P-Z,0-9]{9})([a-h,A-H,j-n,J-N,p,P,r-t,R-T,v-z,V-Z,0-9])([a-h,A-H,j-n,J-N,p-z,P-Z,0-9])(\d{6}))$/.test(
      val
    )
  )
    return "";
  return "Please Enter valid Chasis number";
};

export const isCostValid = (val) => {
  if (val[0] === "0") return "Cost should not start with 0";
  if (val.length === 0) return "Cannot be blank";
  if (val.length < 3) return "Invalid value";
  return "";
};
