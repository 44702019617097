import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import MenuAppBar from "../components/Appbar";
import Home from "./HomeScreens/Home";
import KycPop from "../components/KycPop";
import { UserContext } from "../Contexts/UserContext";
import Profile from "./HomeScreens/Profile";
import LoanScreen from "./HomeScreens/Loan";
import { useNavigate, useParams } from "react-router-dom";
import ViewProfile from "./HomeScreens/ViewProfile";
import { getNotification, incentiveData } from "../api/agent";
import RewardWallet from "../components/V2/Reward/RewardWallet";
import RewardsFlyer from "./Rewards/RewardsFlyer";
import { l } from "../util/languageConvertor";
import { GlobalContext } from "../Contexts/GlobalContext";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

export default function FixedBottomNavigation() {
  const { tab } = useParams();
  const [index, setIndex] = useState();
  const [value, setValue] = useState(tab ? tab : "");
  const [openFlyer, setOpenFlyer] = useState(false);
  const { user } = useContext(UserContext);

  const { langState, notiCountState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [notiCount, setNotiCount] = notiCountState;

  const [appText, setText] = useState("");
  const navigate = useNavigate();

  const [coins, setCoins] = useState({});

  const ref = useRef(null);

  useEffect(() => {
    switch (index) {
      case 0:
        setText(
          <span>
            {`${l("Hello", lang)}, ${user?.name?.split(" ")[0]}`}{" "}
            <span class="wave">👋</span>
          </span>
        );
        break;
      case 1:
        setText(`Loan Details`);
        break;
      case 2:
        setText(`Profile`);
        break;
    }
  }, [index, lang]);

  useEffect(() => {
    switch (tab) {
      case "loan":
        setIndex(1);
        break;
      case "profile":
        setIndex(2);
        break;
      case "profile-view":
        setIndex(2);
        break;
      default:
        setIndex(0);
        break;
    }
  }, [tab]);

  const getCoinData = async () => {
    try {
      let { data } = await incentiveData(false, true, "");
      if (data?.status) {
        setCoins({
          application: data?.applicationIncentiveData?.total_coins,
          overall: data?.totalAgentIncentiveData?.total_coins,
        });
      }
    } catch (err) {}
  };

  const getNotificationCount = async () => {
    try {
      let { data } = await getNotification(`count=true`);
      setNotiCount(data?.count || 0);
    } catch (err) {}
  };

  useEffect(() => {
    // getCoinData();
    const isFlyerShown = localStorage.getItem("is_flyer_shown");
    if ([null, undefined]?.includes(isFlyerShown)) {
      setOpenFlyer(true);
      localStorage.setItem("is_flyer_shown", true);
    }
    getNotificationCount();
  }, []);

  return (
    <>
      <Box sx={{ pb: 7, pt: value === 0 ? 8 : 3 }} ref={ref}>
        {index === 0 && (
          <MenuAppBar home={true} text={appText} active={index} />
        )}
        <CssBaseline />
        <div>
          {user?.kycStage === "none" && <KycPop />}
          {!tab && <Home />}
          {tab === "loan" && <LoanScreen />}
          {tab === "profile" && <Profile />}
          {tab === "profile-view" && <ViewProfile />}
        </div>
        <Paper
          sx={{
            height: "auto",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            pb: 1,
            pt: 1,
            backgroundColor: "#573A7A",
            borderRadius: 0,
          }}
          elevation={3}
        >
          <BottomNavigation
            className="bottom-nav"
            showLabels
            value={index}
            sx={{
              bgcolor: "purple",
              "& .Mui-selected": {
                "& .MuiBottomNavigationAction-label": {
                  color: "white",
                  transition: "none",
                  lineHeight: "20px",
                },
                "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
                  color: "white",
                },
              },
            }}
            onChange={(event, newValue) => {
              setIndex(newValue);
              switch (newValue) {
                case 0:
                  setText(
                    <span>
                      {`${l("Hello", lang)}, ${user?.name?.split(" ")[0]}`}{" "}
                      <span class="wave">👋</span>
                    </span>
                  );
                  setValue("");
                  navigate("/home");
                  break;
                // case 1:
                //   setText(`Loan Details`);
                //   setValue("loan");
                //   navigate("/home/loan");
                //   break;
                case 1:
                  setText(`Profile`);
                  setValue("profile");
                  navigate("/home/profile");
                  break;
              }
            }}
          >
            <BottomNavigationAction
              label={<span style={{ color: "white" }}>{l("Home", lang)}</span>}
              icon={
                <AiOutlineHome style={{ fontSize: "25", color: "white" }} />
              }
            />
            <BottomNavigationAction
              label={
                <span style={{ color: "white" }}>{l("Profile", lang)}</span>
              }
              icon={
                <PermIdentityIcon style={{ fontSize: "25", color: "white" }} />
              }
            />
          </BottomNavigation>
        </Paper>
      </Box>

      {/* {user?.incentives_enabled && <RewardWallet coin={coins?.overall} />} */}
      {/* <RewardsFlyer
        open={openFlyer}
        handleClose={() => {
          setOpenFlyer(false);
        }}
      /> */}
    </>
  );
}
