import React, { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { l } from "../../util/languageConvertor";

const CustomerCheckBox = ({ onChange, active }) => {
  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const refCheck = useRef(null);

  useEffect(() => {
    refCheck.current.checked = active === "rejected" ? true : false;
  }, [active]);
  return (
    <div>
      <div className="custom-checkbox">
        <input
          ref={refCheck}
          id="status"
          type="checkbox"
          name="status"
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <label for="status">
          <div
            className="status-switch"
            data-unchecked={l("Pending", lang)}
            data-checked={l("Rejected", lang)}
          ></div>
        </label>
      </div>
    </div>
  );
};

export default CustomerCheckBox;
