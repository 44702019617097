import {
  BottomNavigation,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { forwardRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom/dist";
import {
  checkOcr,
  chequeOcr,
  preDisbursementBankDocs,
  preDisbursementCoCustomer,
  updateBankDetails,
} from "../../../api/agent";
import MenuAppBar from "../../../components/Appbar";
import CatureUpload from "../../../components/CaptureV2";
import ConfirmChequeData from "../../../components/ConfirmChequeData";
import MultipleUpload from "../../../components/MultipleUpload";
import SuccessCard from "../../../components/SuccessCard";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import useApplication from "../../../hooks/useApplication";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CustomInputAutocomplete from "../../../components/Autocomplete/AutoComplete2";
import { IncredoBankList } from "../../../util/constants";
import CustomInputAutocomplete2 from "../../../components/AutoComplete2";
import { isBlank, isIFSCValid } from "../../../validations/fieldValidations";
import { l } from "../../../util/languageConvertor";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const ocrInitial = {
  done: false,
  account_number: "",
  cheque_number: "",
  ifsc_code: "",
};

const bankDataInitial = {
  debit_card: "",
  account_name: "",
  bank_name: {
    name: "",
    ifsc: "",
    code: "",
  },
  account_no: "",
  account_type: "",
  confirm_account_no: "",
  ifsc_code: "",
};

const BankDetails = forwardRef((props, ref) => {
  const { bottomNav, showRewardState, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [isReward, showReward] = showRewardState;
  const [overallStatus, setOverallStatus] = useState(false);

  const navigate = useNavigate();
  const { application_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);
  const [isCheque, setCheque] = useState(true);
  const [confirm, setConfirm] = useState(false);

  const [ocrReponses, setOcrResponses] = useState([
    ocrInitial,
    ocrInitial,
    ocrInitial,
  ]);

  const [error, setError] = useState({
    debit_card: "",
    cheque: "",
    account_name: "",
    account_no: "",
    account_type: "",
    confirm_account_no: "",
    ifsc_code: "",
    bank_name: "",
  });

  const refs = {
    debit_card: useRef(),
    cheque: useRef(),
    account_name: useRef(),
    account_no: useRef(),
    account_type: useRef(),
    confirm_account_no: useRef(),
    ifsc_code: useRef(),
    bank_name: useRef(),
  };

  const [docs, setDocs] = useState({
    cheques: [],
  });

  const [isDisabled, setDisbaled] = useState(false);

  const [ocrData, setOcr] = useState({
    open: false,
    loading: false,
  });

  const [bankData, setBankData] = useState(bankDataInitial);

  const handleOcr = async (file, i) => {
    setOcr({ ...ocrData, loading: true, open: true });
    let payload = {
      file,
    };
    try {
      let { data } = await chequeOcr(payload);
      console.log(data);
      if (data?.status) {
        // setBankData({
        //   ...bankData,
        //   bank_name: "",
        //   account_name: "",
        //   account_no: data?.data?.["Account Number"],
        //   account_type: "savings",
        //   confirm_account_no: data?.data?.["Account Number"],
        //   ifsc_code: data?.data?.["IFSC Code"],
        // });

        let obj = [...ocrReponses];
        obj[i] = {
          done: true,
          account_number: data?.data?.["Account Number"],
          cheque_number: data?.data?.["Cheque Number"],
          ifsc_code: data?.data?.["IFSC Code"],
        };

        let status = handleValidateOcr(obj, i, [obj[i].cheque_number]);
        obj[i].status = status;
        // if (i === 2 && status === true) {
        //   setConfirm(true);
        // }
        setOcrResponses(obj);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error",
      });
    }
    setOcr({ ...ocrData, loading: false, open: false });
  };

  const isValid = async () => {
    let valid = true;

    console.log(bankData);

    let errors = {
      debit_card: isBlank(bankData?.debit_card),
      account_name: isBlank(bankData?.account_name),
      account_no: isBlank(bankData?.account_no),
      account_type: isBlank(bankData?.account_type || ""),
      ifsc_code: isIFSCValid(bankData?.ifsc_code),
      bank_name: isBlank(bankData?.bank_name?.name || ""),
      cheque: isCheque
        ? docs?.cheques?.length !== 3
          ? "Please upload all cheques"
          : ""
        : "",
    };

    // console.log(bankData?.confirm_account_no);

    errors.confirm_account_no =
      bankData?.confirm_account_no === ""
        ? "Cannot be blank"
        : bankData?.account_no !== bankData?.confirm_account_no
        ? "Account number does not match"
        : "";

    // console.log(errors);

    setError((prev) => ({ ...prev, ...errors }));

    let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        if (!first) {
          first = true;
          refs[i]?.current?.focus();
          window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        }
        valid = false;
      }
    }

    return valid;
  };

  useEffect(() => {
    console.log(ocrReponses);
    if (ocrReponses.length === 3) {
      if (
        ocrReponses[0].status &&
        ocrReponses[1].status &&
        ocrReponses[2].status
      ) {
        setBankData({
          ...bankDataInitial,
          account_no: ocrReponses[0].account_number,
          account_type: "Savings",
          confirm_account_no: ocrReponses[0].account_number,
          ifsc_code: ocrReponses[0].ifsc_code,
        });
      } else {
        setBankData(bankDataInitial);
      }
    } else {
      setBankData(bankDataInitial);
    }
  }, [ocrReponses]);

  const handleValidateOcr = (obj, index, numberArrays) => {
    console.log("Indexes -> ", index);
    if (index > 0) {
      if (obj[index - 1].account_number !== obj[index].account_number) {
        enqueueSnackbar("Account number does not match", { variant: "error" });
        return false;
      }
      // if(obj[index-1].cheque_number === obj[index].cheque_number) {
      //   return false;
      // }

      console.log(numberArrays);

      if (numberArrays.includes(obj[index - 1].cheque_number)) {
        enqueueSnackbar("Cheque number repeated", { variant: "error" });
        return false;
      }
      numberArrays.push(obj[index - 1].cheque_number);
      return handleValidateOcr(obj, index - 1, numberArrays);
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    if (!(await isValid())) {
      return;
    }

    setLoading(true);
    let docArray = [];
    for (let key in docs) {
      if (docs[key] !== "") {
        docArray.push({
          name: key,
          file: docs[key].filter(function (e) {
            return e;
          }),
        });
      }
    }

    let payload = {
      hasDebitCard: bankData?.debit_card,
      chequesUploaded: isCheque,
      chequeData: {
        account_number: bankData?.account_no,
        account_type: bankData?.account_type,
        ifsc_code: bankData?.ifsc_code,
        bank_name: bankData?.bank_name?.name,
        beneficiary_name: bankData?.account_name,
      },
    };

    if (isCheque) {
      payload = { ...payload, docs: docArray, document_included_g: true };
    }

    // console.log(payload);

    try {
      let { data } = await updateBankDetails(payload, application_id);
      if (data?.status) {
        if (data?.is_coin_credited) {
          showReward({
            show: true,
            coin: data?.coin,
          });
        }
        setJourneyData({ ...journeyData, loaded: false });
        console.log(data?.pennyDropFailed);
        if (data?.pennyDropFailed !== null) {
          if (!data?.pennyDropFailed) {
            enqueueSnackbar("Penny drop successfull", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Penny drop failed", {
              variant: "error",
            });
          }
        }
        navigate("/create-loan/status/" + application_id);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error",
      });
    }

    setLoading(false);
  };

  const { getApplicationDetails } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  // useEffect(() => {
  //   if (journeyData?.documents?.cheques?.length) {
  //     setDisbaled(
  //       journeyData?.documents?.cheques?.length <= 0 && !ocrData?.confirm
  //     );
  //   } else {
  //     setDisbaled(!ocrData?.confirm);
  //   }
  // }, [journeyData, ocrData]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  useEffect(() => {
    let valid = true;
    for (let i = 0; i < ocrReponses?.length; i++) {
      if (!ocrReponses[i]?.status) {
        valid = false;
        break;
      }
    }
    if (!valid) {
      setBankData(bankDataInitial);
    }
    setConfirm(valid);
  }, [ocrReponses]);

  return (
    <>
      <Box className="kyc-wrapper w-100" sx={{ pb: 7, pt: 7 }} ref={ref}>
        <MenuAppBar
          text={l("Bank Documents", lang)}
          shadow={true}
          home={false}
          action={() =>
            navigate("/create-loan/loan-creation/" + application_id)
          }
          draft={true}
        />
        <CssBaseline />
        <div className="main-wrapper">
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <label className="form-label mt-3">
                  {l("Debit Card", lang)}:
                </label>
              </Grid>

              <Grid item xs={12} sm={12} className="pt-0">
                <RadioGroup
                  ref={refs.debit_card}
                  className="mb-1"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setBankData({
                      ...bankData,
                      debit_card: e.target.value,
                    });
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item>
                      <FormControlLabel
                        value={"Yes"}
                        control={<Radio sx={radioStyle} />}
                        label={
                          <span style={{ fontSize: "10px" }}>
                            {l("Yes", lang)}
                          </span>
                        }
                        sx={labelStyle}
                      />
                    </Grid>

                    <Grid item>
                      <FormControlLabel
                        value={"No"}
                        control={<Radio sx={radioStyle} />}
                        label={
                          <span style={{ fontSize: "10px" }}>
                            {l("No", lang)}
                          </span>
                        }
                        sx={labelStyle}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value={"Applied"}
                        control={<Radio sx={radioStyle} />}
                        label={
                          <span style={{ fontSize: "10px" }}>
                            {l("Applied", lang)}
                          </span>
                        }
                        sx={labelStyle}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
                <span className="error">{error.debit_card}</span>
              </Grid>

              <Grid item xs={12} sm={12}>
                <label className="form-label mt-3">
                  {l("Upload Cheques", lang)}{" "}
                  <span className="mand-star">*</span>
                </label>
              </Grid>

              <Grid item xs={12} sm={12} className="pt-0">
                <RadioGroup
                  ref={refs.cheque}
                  className="mb-1"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  defaultValue={"Now"}
                  onChange={(e) => {
                    if (e.target.value === "Now") {
                      setCheque(true);
                    } else {
                      setCheque(false);
                    }
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item>
                      <FormControlLabel
                        value={"Now"}
                        control={<Radio sx={radioStyle} />}
                        label={
                          <span style={{ fontSize: "10px" }}>
                            {l("Now", lang)}
                          </span>
                        }
                        sx={labelStyle}
                      />
                    </Grid>

                    <Grid item>
                      <FormControlLabel
                        value={"Later"}
                        control={<Radio sx={radioStyle} />}
                        label={
                          <span style={{ fontSize: "10px" }}>
                            {l("Later", lang)}
                          </span>
                        }
                        sx={labelStyle}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
                {/* <span className="error">{fieldErrors.gender}</span> */}
              </Grid>
            </Grid>

            {isCheque && (
              <Grid container spacing={2}>
                <>
                  <Grid item xs={12} sm={4}>
                    <CatureUpload
                      // error={pUploadError}
                      // errorTxt={pErrorTxt}
                      text={l("Upload Cheque1", lang)}
                      setFile={(file) => {
                        //   setPError(false);
                        //   setPErrorTxt("");
                        setOcr({
                          ...ocrData,
                          confirm: false,
                        });
                        if (file === "") {
                          docs.cheques = [];
                          setOcrResponses([ocrInitial, ocrInitial, ocrInitial]);
                        } else {
                          docs.cheques[0] = file;
                          handleOcr(file, 0);
                        }

                        setDocs((prev) => ({
                          ...prev,
                          cheques: [...docs.cheques],
                        }));
                      }}
                      tempState={docs?.cheques?.[0] || ""}
                    />
                  </Grid>
                </>

                <Grid item xs={12} sm={4} className="mb-3">
                  <CatureUpload
                    // error={pUploadError}
                    // errorTxt={pErrorTxt}
                    text={l("Upload Cheque2", lang)}
                    setFile={(file) => {
                      //   setPError(false);
                      //   setPErrorTxt("");

                      if (file === "") {
                        docs.cheques[1] = "";

                        docs.cheques[2] = "";

                        let obj = [...ocrReponses];
                        obj[1] = ocrInitial;
                        obj[2] = ocrInitial;
                        setOcrResponses(obj);
                      } else {
                        docs.cheques[1] = file;
                        handleOcr(file, 1);
                      }

                      setDocs((prev) => ({
                        ...prev,
                        cheques: [...docs.cheques],
                      }));
                    }}
                    tempState={docs?.cheques?.[1] || ""}
                    disabled={!ocrReponses[0]?.status}
                  />
                </Grid>

                <Grid item xs={12} sm={4} disabled={true} className="mb-3">
                  <CatureUpload
                    // error={pUploadError}
                    // errorTxt={pErrorTxt}
                    text={l("Upload Cheque3", lang)}
                    setFile={(file) => {
                      //   setPError(false);
                      //   setPErrorTxt("");

                      if (file === "") {
                        docs.cheques[2] = file;

                        let obj = [...ocrReponses];
                        obj[2] = ocrInitial;
                        setOcrResponses(obj);
                      } else {
                        docs.cheques[2] = file;
                        handleOcr(file, 2);
                      }
                      setDocs((prev) => ({
                        ...prev,
                        cheques: [...docs.cheques],
                      }));
                    }}
                    tempState={docs?.cheques?.[2] || ""}
                    disabled={!ocrReponses[1]?.status}
                  />
                </Grid>

                <Grid item xs={12} sm={12} className="pt-0">
                  <span className="error">{error?.cheque}</span>
                </Grid>
              </Grid>
            )}

            {(!isCheque || confirm) && (
              <Grid container spacing={2} className="mt-3">
                <Grid item xs={5} sm={5}>
                  <label className="form-label mt-2" style={{ fontSize: 13 }}>
                    {l("Name in bank account", lang)}{" "}
                    <span className="mand-star">*</span>
                  </label>
                </Grid>
                <Grid item xs={7} sm={7}>
                  <input
                    type="text"
                    ref={refs?.account_name}
                    className="form-input w-100 px-3 input-blue"
                    required
                    placeholder={l("Name in bank account", lang)}
                    autoComplete="family-name"
                    value={bankData.account_name}
                    onChange={(e) => {
                      setBankData({
                        ...bankData,
                        account_name: e.target.value,
                      });
                    }}
                  />
                  <span className="error">{error?.account_name}</span>
                </Grid>

                <Grid item xs={5} sm={5}>
                  <label className="form-label mt-2" style={{ fontSize: 13 }}>
                    {l("Select Bank", lang)}{" "}
                    <span className="mand-star">*</span>
                  </label>
                </Grid>
                <Grid item xs={7} sm={7} ref={refs?.bank_name}>
                  <CustomInputAutocomplete
                    options={IncredoBankList}
                    text={l("Select Bank", lang)}
                    value={{
                      name: bankData?.bank_name?.name,
                      code: bankData?.bank_name?.code,
                      ifsc: bankData?.bank_name?.ifsc,
                    }}
                    onChange={(val) => {
                      setBankData({
                        ...bankData,
                        bank_name: val,
                      });
                    }}
                  />
                  <span className="error">{error?.bank_name}</span>
                </Grid>

                <Grid item xs={5} sm={5}>
                  <label className="form-label mt-2" style={{ fontSize: 13 }}>
                    {l("Account Number", lang)}{" "}
                    <span className="mand-star">*</span>
                  </label>
                </Grid>
                <Grid item xs={7} sm={7}>
                  <input
                    type="text"
                    ref={refs?.account_no}
                    className="form-input w-100 px-3 input-blue"
                    required
                    placeholder={l("Account Number", lang)}
                    autoComplete="family-name"
                    value={bankData.account_no}
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        !/^\d+$/.test(e.target.value)
                      ) {
                        return false;
                      }
                      setBankData({
                        ...bankData,
                        account_no: e.target.value,
                      });
                    }}
                    inputMode="numeric"
                  />
                  <span className="error">{error?.account_no}</span>
                </Grid>

                <Grid item xs={5} sm={5}>
                  <label className="form-label mt-2" style={{ fontSize: 13 }}>
                    {l("Confirm Account Number", lang)}{" "}
                    <span className="mand-star">*</span>
                  </label>
                </Grid>
                <Grid item xs={7} sm={7}>
                  <input
                    type="text"
                    ref={refs?.confirm_account_no}
                    className="form-input w-100 px-3 input-blue"
                    required
                    placeholder={l("Confirm Account Number", lang)}
                    autoComplete="family-name"
                    value={bankData?.confirm_account_no}
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        !/^\d+$/.test(e.target.value)
                      ) {
                        return false;
                      }
                      setBankData({
                        ...bankData,
                        confirm_account_no: e.target.value,
                      });
                    }}
                    inputMode="numeric"
                  />
                  <span className="error">{error.confirm_account_no}</span>
                </Grid>

                <Grid item xs={5} sm={5}>
                  <label className="form-label mt-2" style={{ fontSize: 13 }}>
                    {l("Account Type", lang)}{" "}
                    <span className="mand-star">*</span>
                  </label>
                </Grid>
                <Grid item xs={7} sm={7} ref={refs?.account_type}>
                  <CustomInputAutocomplete
                    options={[
                      {
                        name: "Savings",
                      },
                      {
                        name: "Current",
                      },
                    ]}
                    value={{ name: bankData?.account_type }}
                    text={l("Account Type", lang)}
                    className="input-blue"
                    onChange={(val) => {
                      setBankData({
                        ...bankData,
                        account_type: val?.name,
                      });
                    }}
                  />
                  <span className="error">{error?.account_type}</span>
                </Grid>

                <Grid item xs={5} sm={5}>
                  <label className="form-label mt-2" style={{ fontSize: 13 }}>
                    {l("IFSC Code", lang)} <span className="mand-star">*</span>
                  </label>
                </Grid>
                <Grid item xs={7} sm={7}>
                  <input
                    ref={refs?.ifsc_code}
                    type="text"
                    className="form-input w-100 px-3 input-blue"
                    required
                    placeholder={l("IFSC Code", lang)}
                    autoComplete="family-name"
                    value={bankData.ifsc_code}
                    maxLength={11}
                    onChange={(e) => {
                      setBankData({
                        ...bankData,
                        ifsc_code: (e.target.value || "").toUpperCase(),
                      });
                    }}
                  />
                  <span className="error">{error.ifsc_code}</span>
                </Grid>
              </Grid>
            )}
          </>
        </div>

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className={`btn-submit mb-2 mt-2 d-flex justify-content-center align-items-center`}
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                // disabled={isDisabled}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Submit", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
      {ocrData?.open && <ConfirmChequeData data={ocrData} setData={setOcr} />}
    </>
  );
});

export default BankDetails;
