import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

import {
  BottomNavigation,
  Button,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingOver from "../../components/LoadingOver";
import { GlobalContext } from "../../Contexts/GlobalContext";
import CatureUpload from "../../components/CaptureV2";
import CustomInputAutocomplete from "../../components/AutoComplete";
import { LoanContext } from "../../Contexts/LoanJourneyContext";
import { createCoCustomer, incentiveData } from "../../api/agent";
import { useSnackbar } from "notistack";
import useApplication from "../../hooks/useApplication";
import CustomInputAutocomplete2 from "../../components/AutoComplete2";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";

export default function RewardSummary() {
  const [isLoading, setLoading] = useState(false);
  const [isCurrent, setCurrent] = useState(true);
  const [applicationIncentive, setApplicationIncentive] = useState([]);
  const [allIncentive, setAllIncentive] = useState([]);
  const [indices, setIndices] = useState({});
  const [coins, setCoins] = useState({});

  const { application_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const { state, pathname } = useLocation();
  const { path } = state;

  const navigate = useNavigate();

  const getIncentiveData = async (f1, f2, id) => {
    setLoading(true);
    if (true) {
      try {
        let { data } = await incentiveData(f1, f2, id ? id : "");
        if (data?.status) {
          setApplicationIncentive(data?.applicationIncentiveHistory);
          setAllIncentive(data?.allIncentives);
          setCoins({
            application: data?.applicationIncentiveData?.total_coins,
            overall: data?.totalAgentIncentiveData?.total_coins,
          });
        }
      } catch (err) {
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      }
    }
    setLoading(false);
  };

  // const { getApplicationDetails } = useApplication();

  useEffect(() => {
    if (application_id) {
      setCurrent(true);
      getIncentiveData(true, true, application_id);
      // getApplicationDetails(application_id);
    } else {
      getIncentiveData(true, true);
      setCurrent(false);
    }
  }, [application_id]);

  useEffect(() => {
    let indexData = {};
    if (applicationIncentive?.length > 0) {
      applicationIncentive?.map((val, i) => {
        switch (val?.stage) {
          case "KYC":
            indexData = { ...indexData, KYC: i };
            break;
          case "DEEP_USER_PROFILING":
            indexData = { ...indexData, DEEP_USER_PROFILING: i };
            break;
          case "FIELD_INVESTIGATION":
            indexData = { ...indexData, FIELD_INVESTIGATION: i };
            break;
          case "DOCUMENTS_UPLOAD_1":
            indexData = { ...indexData, DOCUMENTS_UPLOAD_1: i };
            break;
          case "DOCUMENTS_UPLOAD_2":
            indexData = { ...indexData, DOCUMENTS_UPLOAD_2: i };
            break;
          case "BANK_DOCUMENTS":
            indexData = { ...indexData, BANK_DOCUMENTS: i };
            break;
          case "PAYMENT_METHOD":
            indexData = { ...indexData, PAYMENT_METHOD: i };
            break;
          case "FINAL_RC":
            indexData = { ...indexData, FINAL_RC: i };
            break;
        }
      });
    }
    setIndices(indexData);
  }, [applicationIncentive]);

  const StepDiv = ({ title, index }) => {
    return (
      <div className={`step ${index !== undefined && "step-active"}`}>
        <div>
          <div className="circle">
            <DoneIcon className="done-ico" />
          </div>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <div className="title">{title}</div>
            </Grid>
            <Grid item xs={6} sm={6} className="info-div">
              <Grid container spacing={2}>
                {applicationIncentive[index]?.recieving_date && (
                  <Grid item xs={6} sm={6}>
                    <div className="reward-date">
                      <p className="text-start">
                        {moment(
                          applicationIncentive[index]?.recieving_date
                        ).format("h:mm a Do MMMM YYYY")}
                      </p>
                    </div>
                  </Grid>
                )}
                {applicationIncentive[index]?.recieved_coins && (
                  <Grid item xs={6} sm={6}>
                    <p className="coin-text" style={{ fontSize: "14px" }}>
                      +{applicationIncentive[index]?.recieved_coins} Coins
                    </p>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="content-center w-100">
        <Box sx={{ pb: 1 }} className="w-100 response-wrapper">
          <div className="tab-fixed">
            <Grid container className="reward-tabs-wrapper">
              {/* <Grid className="app-bar" item xs={12} sm={12}>
              <ArrowBackIcon
                className="pointer ms-2"
                sx={{ color: "black" }}
                onClick={() => {
                  navigate("/draft-applications");
                }}
              />
            </Grid> */}

              {application_id && (
                <>
                  <Grid
                    className={`tab ${isCurrent && "active"}`}
                    item
                    xs={6}
                    sm={6}
                    onClick={() => setCurrent(true)}
                  >
                    <div>
                      <p className="tab-name">Current Application</p>
                      <p className="text-center mb-0">
                        <span className="coin-value">
                          {coins?.application ? coins?.application : 0}/500
                        </span>
                        <span
                          className={`tab-subtext ${
                            isCurrent ? "white" : "purple"
                          }`}
                        >
                          e-Coins
                        </span>
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    className={`tab ${!isCurrent && "active"}`}
                    item
                    xs={6}
                    sm={6}
                    onClick={() => setCurrent(false)}
                  >
                    <div>
                      <p className="tab-name">All Applications</p>
                      <p className="text-center mb-0">
                        <span className="coin-value">{coins?.overall}</span>
                        <span
                          className={`tab-subtext ${
                            !isCurrent ? "white" : "purple"
                          }`}
                        >
                          e-Coins
                        </span>
                      </p>
                    </div>
                  </Grid>
                </>
              )}

              {!application_id && (
                <>
                  <Grid
                    className={`tab ${!isCurrent && "active"}`}
                    sx={{
                      justifyContent: "start !important",
                      paddingLeft: "20px",
                    }}
                    item
                    xs={12}
                    sm={12}
                  >
                    <div>
                      <p className="tab-name">All Applications</p>
                      <p className="text-center mb-0">
                        <span className="coin-value">{coins?.overall}</span>
                        <span
                          className={`tab-subtext ${
                            !isCurrent ? "white" : "purple"
                          }`}
                        >
                          e-Coins
                        </span>
                      </p>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>

          {/* <div style={{ marginTop: "130px", padding: "20px" }}>sdsf</div> */}

          <div
            className="main-wrapper w-100"
            style={{ paddingTop: "120px", paddingBottom: "60px" }}
          >
            <>
              {isCurrent && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <StepDiv
                      title={"Deep User Profiling"}
                      index={indices["DEEP_USER_PROFILING"]}
                    />
                    <StepDiv title={"KYC"} index={indices["KYC"]} />
                    <StepDiv
                      title={"Field Investigation"}
                      index={indices["FIELD_INVESTIGATION"]}
                    />
                    <StepDiv
                      title={"Document Upload Part 1"}
                      index={indices["DOCUMENTS_UPLOAD_1"]}
                    />
                    <StepDiv
                      title={"Document Upload Part 2"}
                      index={indices["DOCUMENTS_UPLOAD_2"]}
                    />
                    <StepDiv
                      title={"Bank Documents"}
                      index={indices["BANK_DOCUMENTS"]}
                    />
                    <StepDiv
                      title={"Payment Method"}
                      index={indices["PAYMENT_METHOD"]}
                    />
                    <StepDiv title={"Final RC"} index={indices["FINAL_RC"]} />
                  </Grid>
                </Grid>
              )}
              {!isCurrent && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    {allIncentive?.map((val) => {
                      return (
                        <Container
                          className="load-collapse-row collapse-shadow pointer"
                          onClick={() => {
                            // setSelectedApplication(val?.id);
                            // fetchStage(val?.id);
                            // setAppPath("ALL");
                            navigate(
                              `/create-loan/rewards/${val?.application_id}`,
                              { state: { path: pathname } }
                            );
                          }}
                        >
                          <Row className="mt-2">
                            <Col className="font-blue align-left ps-4">
                              {val?.application_id}
                            </Col>
                            {/* <Col className="font-blue align-left ps-4">
                              "sdf"
                            </Col> */}
                            <Col className="font-blue align-right pe-4">
                              <dv>
                                <p className="reward-date">
                                  {moment(val?.last_received_date).format(
                                    "Do MMMM YYYY"
                                  )}
                                </p>
                                <p className="coin-text">
                                  {val?.total_coins} Coins
                                </p>
                              </dv>
                            </Col>
                          </Row>
                        </Container>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </>
          </div>

          <Paper className="paper-bottom" elevation={3}>
            <BottomNavigation style={{ height: "auto", paddingTop: "3px" }}>
              <div className="button-wrapper w-100">
                <Button
                  className="btn-submit"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={() => navigate(path ? path : "/home")}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress
                        className="me-2 mb-1"
                        size={20}
                        style={{ color: "white" }}
                      />
                      Loading
                    </>
                  ) : (
                    "Back"
                  )}
                </Button>
              </div>
            </BottomNavigation>
          </Paper>
        </Box>
      </div>
      {isLoading && <LoadingOver />}
    </>
  );
}
