import React, { useContext } from "react";
import MenuAppBar from "../../components/Appbar";
import {
  Avatar,
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getNotification } from "../../api/agent";
import { useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { UserContext } from "../../Contexts/UserContext";

const NotificationScreen = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { tokenState } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [notification, setNotification] = useState([]);
  const [tokenData, setTokenData] = tokenState;
  const [loading, setLoader] = useState(false);
  const handleBack = () => {
    navigate("/home");
  };

  const fetchNotifications = async () => {
    setLoader(true);
    try {
      let { data } = await getNotification(`data=true`);
      setNotification(data?.notifications);
    } catch (err) {
      enqueueSnackbar(
        err?.response?.data?.message || "Cannot load notification. Try again"
      );
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);
  return (
    <div className="content-center">
      <Box className="w-100 kyc-wrapper" sx={{ pb: 7, pt: 7 }}>
        <MenuAppBar
          home={false}
          action={handleBack}
          bigText={"Notifications"}
        />
        <CssBaseline />
        <div className="main-wrapper w-100">
          <>
            {loading ? (
              <>
                <p className="text-center mt-5">
                  <CircularProgress color="secondary" size={50} />
                </p>
              </>
            ) : (
              <>
                {notification?.map((val) => {
                  const index = val?.send_to?.findIndex(
                    (item) => item?.recipient_id === user?.agent_code
                  );

                  return (
                    <div
                      className={`notification-card ${
                        !val?.send_to?.[index]?.is_read &&
                        "notification-not-read"
                      }`}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <p className="noti-heading">
                            {val?.notification?.title}
                          </p>
                          <p className="noti-body">{val?.notification?.body}</p>
                          <p className="noti-time">
                            {moment(val?.createdAt).fromNow()}
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          className="d-flex justify-content-end"
                        >
                          <Avatar
                            src={val?.notification?.image}
                            sx={{ width: 100, height: 100 }}
                          >
                            <NotificationsNoneIcon sx={{ fontSize: 45 }} />
                          </Avatar>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </>
            )}
          </>
        </div>
      </Box>
    </div>
  );
};

export default NotificationScreen;
