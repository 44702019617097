// export const environment = "development";
// export const environment = 'production'
export const environment = 'beta'
// export const environment = 'local'

export const baseURL = {
  development: "https://api.lms.embifi.in/api/v1",
  production: window.location.host?.includes("prod-demo.erick")
    ? "https://test.prod.api.lms.embifi.in/api/v1"
    : "https://prod.api.lms.embifi.in/api/v1",
  beta: "https://beta.api.lms.embifi.in/api/v1",
  local: "https://api.lms.embifi.in/api/v1",
};
