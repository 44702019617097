import { Button, CircularProgress, Dialog } from "@mui/material";
import React, { useContext } from "react";

const LoadingOver = ({ text }) => {
  return (
    <Dialog
      fullScreen
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className="kyc-dialog">
        <div className="dialog" style={{ width: "auto" }}>
          <p className="welcome text-center">
            <CircularProgress />
          </p>
          <p className="welcome mt-2">{text ? text : "Loading"}</p>
        </div>
      </div>
    </Dialog>
  );
};

export default LoadingOver;
