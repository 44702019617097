import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import Congrats from "./Congrats";
import Failed from "./Failed";
import Success from "./Success";
import UnderReview from "./UnderReview";
import Waiting from "./Waiting";
import PostDisbursement from "../../v3/PostDisbursement";

const LoanStatus = () => {
  const navigate = useNavigate();
  const { application_id } = useParams();

  const [status, setStatus] = useState("loading");
  const [loading, setLoading] = useState(true);

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  useEffect(() => {
    switch (journeyData?.stage) {
      case "PENNY_DROP_FAILED":
        setStatus("penny");
        break;
      case "WAITING_FOR_NBFC_APPROVAL":
        setStatus("waiting-approval");
        break;
      case "WAITING_FOR_DISBURSAL":
        setStatus("waiting");
        break;
      case "DISBURSED":
        setStatus("disbursed");
        break;
      case "LOADING":
        setStatus("loading");
        break;
      default:
        setStatus("failed");
    }
  }, [journeyData?.stage]);

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id, true, setLoading);
  }, []);

  return (
    <>
      <div className="response-wrapper w-100">
        {!loading && (
          <>
            {status === "penny" && <Failed />}
            {status === "waiting" && <Waiting />}
            {status === "waiting-approval" && (
              <Waiting text={"Waiting for NBFC Approval"} />
            )}
            {status === "disbursed" && (
              <PostDisbursement/>
              // <Success
              //   text={"Disbursement Completed"}
              //   action={() => {
              //     // navigate("/create-loan/progress/" + application_id);
              //   }}
              // />
            )}
            {status === "failed" && <Failed />}
          </>
        )}
      </div>
      {/* {status === "loading" && <LoadingOver />} */}
    </>
  );
};

export default LoanStatus;
