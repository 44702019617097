import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../../components/Appbar";
import rejectedIco from "../../../../assets/rejected.svg";

import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { addNewCoapplicant } from "../../../../api/agent";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import { l } from "../../../../util/languageConvertor";
// import TimeInput from "../../Components/DateInput";

const Rejected = forwardRef(({origin}, ref) => {


  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const {application_id} = useParams()
  const { bottomNav, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleBack = () => {
    navigate("/draft-applications");
  };

  const style = {
    reviewTxt: {
      fontSize: "30px",
      fontWeight: "900",
      color: "#151940",
    },
    tagTxt: {
      padding: "0 20px",
      color: "#151940",
    },

    remark: {
      color: "red",
      marginBottom:0,
      justifyContent:'center',
      display:'flex'
    },
    card:{
      marginTop:"20px",
      width:"80%"
    }
  };


  const handleCoCustomer = async () => {
    try {
      setLoading(true)
      let {data} = await addNewCoapplicant(application_id)
      navigate('/create-loan/co-customer/'+application_id)
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message,lang), {variant:"error"})
    }
    setLoading(false)
  }

  return (
    <>
      <Box className="" ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div
          className="main-wrapper"
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "90vh",
          }}
        >
          <div>
            <p className="text-center">
              <img src={rejectedIco} alt="wait" />
            </p>
            <p className="text-center" style={style?.reviewTxt}>
              {l("Application Rejected", lang)}
            </p>
            <p className="text-center" style={style?.tagTxt}>
              {l("Sorry we could not proceed with your application", lang)}
            </p>

            <p className="text-center" style={style?.remark}>
              <div style={style?.card}>{l(journeyData?.application_remark, lang)}</div>
            </p>

          </div>
        </div>

        {origin !== "customer" && (
          <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
            <BottomNavigation style={{ height: "auto" }}>
              <div className="button-wrapper w-100">
                <Button
                  className="btn-submit"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2, p:4 }}
                  onClick={() => handleCoCustomer()}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress
                        className="me-2 mb-1"
                        size={20}
                        style={{ color: "white" }}
                      />
                      {l("Loading",lang)}
                    </>
                  ) : (
                    l("Try Another Co-Customer/ Guarantor",lang)
                  )}
                </Button>
              </div>
            </BottomNavigation>
          </Paper>
        )}
      </Box>
    </>
  );
});

export default Rejected;
