import { Button, CssBaseline, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import DocumentCard from "../../../../components/V3/DocumentCard";
import UploadCard from "../../../../components/V3/UploadCard";
import form26Ico from "../../../../assets/tabs/form26Ico.svg";
import salesInvoiceIco from "../../../../assets/tabs/salesInvoiceIco.svg";
import form22Ico from "../../../../assets/tabs/form22Ico.svg";
import deliveryIco from "../../../../assets/tabs/deliveryIco.svg";
import chasisIco from "../../../../assets/tabs/chasisIco.svg";
import warrantyIco from "../../../../assets/tabs/warrantyIco.svg";
import insuranceIco from "../../../../assets/tabs/insuranceIco.svg";
import form20Ico from "../../../../assets/tabs/form20Ico.svg";
import form21Ico from "../../../../assets/tabs/form21Ico.svg";
import trcIco from "../../../../assets/tabs/trcIco.svg";
import { uploadDocument } from "../../../../api/agent";
import useApplication from "../../../../hooks/useApplication";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import MultiDocUpload from "../../../../components/V2/MultiDocUpload";
import ContinueUpload from "../../../../components/V2/ContinueUpload";
import SelectUpload from "../../../../components/V2/SelectUpload";
import MenuAppBar from "../../../../components/Appbar";
import { useNavigate } from "react-router-dom";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { UserContext } from "../../../../Contexts/UserContext";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { l } from "../../../../util/languageConvertor";

const VehicleDocuments = ({ application_id, openStatus }) => {
  const { user } = useContext(UserContext);
  const { journeyState } = useContext(LoanContext);
  const navigate = useNavigate();
  const [journeyData, setJourneyData] = journeyState;
  const { getApplicationDetails } = useApplication();
  const [multiData, setMultiData] = useState({ show: false });
  const [loading, setLoading] = useState("");

  const { langState, docPopupState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [docPopup, setDocPopup] = docPopupState;

  const handleUpload = async (file, name) => {
    setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        application_id,
        `?override=true`
      );

      if (data?.mandatory_completed) {
        setDocPopup({ open: true, type: "DONE" });
      }

      getApplicationDetails(application_id, true);
    } catch (error) {}
    setLoading("");
  };

  return (
    <>
      <MenuAppBar
        home={false}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
        leftContent={
          <Button
            color="secondary"
            onClick={() => {
              MixPanelAudit.intercept("check_status_click",{
                distinct_id : user?.mobile_number[0],
                agent_name : user?.name,
                application_id
              })
              openStatus();
            }}
          >
            {l("Check Status",lang)}
          </Button>
        }
      />
      <CssBaseline />
      <div>
        <Grid container spacing={2} className="mt-1">
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Form 26-35", lang)}
              isRequired={true}
              icon={form26Ico}
              tempState={
                journeyData?.documents?.form_26_35?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.form_26_35?.length > 0}
              status={
                journeyData?.documents?.form_26_35?.slice(-1)[0]?.isApproved
              }
              isLoading={loading === "form_26_35"}
              setFile={(file) => {
                handleUpload(file, "form_26_35");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Sales Invoice", lang)}
              icon={salesInvoiceIco}
              isRequired={true}
              tempState={
                journeyData?.documents?.sales_invoice?.slice(-1)[0]?.fileLink ||
                ""
              }
              isUpload={journeyData?.documents?.sales_invoice?.length > 0}
              status={
                journeyData?.documents?.sales_invoice?.slice(-1)[0]?.isApproved
              }
              isLoading={loading === "sales_invoice"}
              setFile={(file) => {
                handleUpload(file, "sales_invoice");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Form 22", lang)}
              icon={form22Ico}
              isRequired={true}
              tempState={
                journeyData?.documents?.form_22?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.form_22?.length > 0}
              status={journeyData?.documents?.form_22?.slice(-1)[0]?.isApproved}
              isLoading={loading === "form_22"}
              setFile={(file) => {
                handleUpload(file, "form_22");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Vehicle Delivery photo", lang)}
              isRequired={true}
              icon={deliveryIco}
              tempState={
                journeyData?.documents?.erick_delivery_photo?.slice(-1)[0]
                  ?.fileLink || ""
              }
              isUpload={
                journeyData?.documents?.erick_delivery_photo?.length > 0
              }
              status={
                journeyData?.documents?.erick_delivery_photo?.slice(-1)[0]
                  ?.isApproved
              }
              isLoading={loading === "erick_delivery_photo"}
              setFile={(file) => {
                handleUpload(file, "erick_delivery_photo");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Chassis Number Photo", lang)}
              icon={chasisIco}
              isRequired={true}
              tempState={
                journeyData?.documents?.chassis_number?.slice(-1)[0]?.fileLink ||
                ""
              }
              isUpload={journeyData?.documents?.chassis_number?.length > 0}
              status={
                journeyData?.documents?.chassis_number?.slice(-1)[0]?.isApproved
              }
              isLoading={loading === "chassis_number"}
              setFile={(file) => {
                handleUpload(file, "chassis_number");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Battery Warranty Card", lang)}
              isRequired={true}
              icon={warrantyIco}
              tempState={
                journeyData?.documents?.battery_warranty?.slice(-1)[0]
                  ?.fileLink || ""
              }
              isUpload={journeyData?.documents?.battery_warranty?.length > 0}
              status={
                journeyData?.documents?.battery_warranty?.slice(-1)[0]
                  ?.isApproved
              }
              isLoading={loading === "battery_warranty"}
              setFile={(file) => {
                handleUpload(file, "battery_warranty");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Insurance", lang)}
              isRequired={true}
              icon={insuranceIco}
              tempState={
                journeyData?.documents?.insurance?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.insurance?.length > 0}
              status={
                journeyData?.documents?.insurance?.slice(-1)[0]?.isApproved
              }
              isLoading={loading === "insurance"}
              setFile={(file) => {
                handleUpload(file, "insurance");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("Form 20", lang)}
              icon={form20Ico}
              isRequired={true}
              tempState={
                journeyData?.documents?.form_20?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.form_20?.length > 0}
              status={journeyData?.documents?.form_20?.slice(-1)[0]?.isApproved}
              isLoading={loading === "form_20"}
              setFile={(file) => {
                handleUpload(file, "form_20");
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <ContinueUpload
              icon={form21Ico}
              name={l(`Form 21`, lang)}
              onClick={() => {
                setMultiData({
                  ...multiData,
                  show: true,
                  count: 5,
                  collection: "loanapplications",
                  category: "pre_disbursement",
                  name: "form_21",
                });
              }}
              handleUpload={(file) => {
                handleUpload(file, "form_21");
              }}
              documents={journeyData?.documents?.form_21}
              started={journeyData?.documents?.form_21?.[0]}
              completed={journeyData?.documents?.form_21?.[0]?.completed}
              status={journeyData?.documents?.form_21?.[0]?.isApproved}
              pages={journeyData?.documents?.form_21?.length}
              isMulti={journeyData?.documents?.form_21?.[0]?.meta_page_document}
              setState={setMultiData}
              isLoading={loading === "form_21"}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DocumentCard
              text={l("TRC", lang)}
              isRequired={true}
              icon={trcIco}
              tempState={
                journeyData?.documents?.trc?.slice(-1)[0]?.fileLink || ""
              }
              isUpload={journeyData?.documents?.trc?.length > 0}
              status={journeyData?.documents?.trc?.slice(-1)[0]?.isApproved}
              isLoading={loading === "trc"}
              setFile={(file) => {
                handleUpload(file, "trc");
              }}
            />
          </Grid>
        </Grid>
      </div>

      {multiData?.show && (
        <MultiDocUpload
          state={multiData}
          setState={setMultiData}
          onClose={() => {
            getApplicationDetails(application_id, true);
          }}
        />
      )}
    </>
  );
};

export default VehicleDocuments;
