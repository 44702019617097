import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import CatureUpload from "../../../components/CaptureV2";
import MenuAppBar from "../../../components/Appbar";
import { useNavigate, useParams } from "react-router-dom";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import LoanJourneryContext, {
  LoanContext,
} from "../../../Contexts/LoanJourneyContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ReactComponent as LenderIco } from "../../../assets/tabs/lenderIco.svg";
import { ReactComponent as BankIco } from "../../../assets/tabs/bankIco.svg";
import { ReactComponent as CustomerIco } from "../../../assets/tabs/customerIco.svg";
import { ReactComponent as CoCustomerIco } from "../../../assets/tabs/coCustomerIco.svg";
import { ReactComponent as FiIcon } from "../../../assets/tabs/fiIcon.svg";
import { ReactComponent as LoanIco } from "../../../assets/tabs/loanIco.svg";
import { ReactComponent as PaymentIco } from "../../../assets/tabs/paymentIco.svg";
import { ReactComponent as ErickIco } from "../../../assets/tabs/erickIco.svg";
import { AiOutlineUser } from "react-icons/ai";
import { ImFilesEmpty } from "react-icons/im";
import { AiOutlineFileSearch } from "react-icons/ai";
import { SlWallet } from "react-icons/sl";
import { BsBank } from "react-icons/bs";
import FieldInvestigationV2 from "../v2/FieldInvestigation";
import PaymentMethod from "./Tabs/PaymentMethod";
import Customer from "./Tabs/Customers";
import CoCustomers from "./Tabs/CoCustomers";
import LoanDocuments from "./Tabs/LoanDocuments";
import VehicleDocuments from "./Tabs/VehicleDocuments";
import BankDocuments from "./Tabs/BankDocuments";
import LenderDetails from "./Tabs/LenderDetails";
import StatusDialog from "./Tabs/StatusDialog";
import useApplication from "../../../hooks/useApplication";
import FieldInvestigationDone from "../v2/FieldInvestigationDone";
import LoadingOver from "../../../components/LoadingOver";
import GlobalLoader from "../../../components/V3/GlobalLoader";
import MixPanelAudit from "../../../util/MixPanelAudit";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { l } from "../../../util/languageConvertor";
import DocPopup from "../../../components/V3/DocPopup";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const DocumentUpload = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { langState, docPopupState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [docPopup, setDocPopup] = docPopupState;

  const navigate = useNavigate();
  const { application_id, tab } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingOver, setLoadingOver] = useState(false);
  const [openStatus, setOpen] = useState({ open: false, default: "pending" });
  const [pendingCount, setPendingCount] = useState({});
  const [showTabs, setShowTabs] = useState(true);

  const handleClose = () => {
    setOpen({
      open: false,
      default: "pending",
    });
  };

  const BannerComponent = ({ val, outof }) => {
    return (
      <span className="banner">
        {val}/<span>{outof}</span>
      </span>
    );
  };

  const { getApplicationDetails, calculatePending } = useApplication();

  useEffect(() => {
    getApplicationDetails(application_id, true, setLoadingOver);
  }, []);

  useEffect(() => {
    let count = calculatePending({
      ...journeyData?.documents,
      ...journeyData?.customer_documents,
      ...journeyData?.co_customer_documents,
    });
    setPendingCount(count);
    // console.log(count);
    if (journeyData?.showRejectedPopup) {
      setDocPopup({
        open: true,
        type: "rejected",
      });
    }
  }, [journeyData]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate(`/draft-applications`);
    };
  });

  useEffect(() => {
    if (tab) {
      setActiveTab(Number(tab));
    }
  }, [tab]);

  return (
    <>
      <Box className="w-100 kyc-wrapper" sx={{ pb: 7, pt: 7 }} ref={ref}>
        <div className="main-wrapper w-100 documents-upload-wrapper p-0 bg-dark">
          <>
            {/* <Grid container spacing={2} className="mt-2">
              <Grid item xs={12} sm={12} className="mb-3"></Grid>
            </Grid> */}

            <Tabs
              selectedIndex={activeTab}
              onSelect={(index) => {
                setActiveTab(index);
                navigate(
                  `/create-loan/documents-upload/${application_id}/${index}`
                );
              }}
            >
              <TabList>
                <Tab>
                  <div style={{ position: "relative" }}>
                    <p className="text-center mb-0">
                      <LenderIco />
                    </p>
                    <p className="tab-title">{l("Lender", lang)}</p>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <p className="text-center mb-0">
                      <SlWallet />
                    </p>
                    <p className="tab-title">{l("Payment Method", lang)}</p>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <p className="text-center mb-0">
                      <AiOutlineFileSearch />
                    </p>
                    <p className="tab-title">
                      {l("Field Investigation", lang)}
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <p className="text-center mb-0">
                      <BannerComponent val={pendingCount?.customer} outof={3} />
                      <AiOutlineUser />
                    </p>
                    <p className="tab-title">{l("Customer Documents", lang)}</p>
                  </div>
                </Tab>
                {journeyData?.co_customer_exist && (
                  <Tab>
                    <div>
                      <p className="text-center mb-0">
                        <BannerComponent
                          val={pendingCount?.co_customer}
                          outof={3}
                        />
                        <CoCustomerIco />
                      </p>
                      <p className="tab-title">
                        {l("Co-Borrower documents", lang)}
                      </p>
                    </div>
                  </Tab>
                )}
                <Tab>
                  <div>
                    <p className="text-center mb-0">
                      <BannerComponent
                        val={pendingCount?.loan_document}
                        outof={3}
                      />
                      <ImFilesEmpty />
                    </p>
                    <p className="tab-title">{l("Loan Documents", lang)}</p>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <p className="text-center mb-0">
                      <BannerComponent
                        val={pendingCount?.vehicle_document}
                        outof={10}
                      />
                      <ErickIco />
                    </p>
                    <p className="tab-title">{l("Vehicle Documents", lang)}</p>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <p className="text-center mb-0">
                      <BannerComponent
                        val={pendingCount?.bank_document}
                        outof={3}
                      />
                      <BsBank />
                    </p>
                    <p className="tab-title">{l("Bank Documents", lang)}</p>
                  </div>
                </Tab>
              </TabList>

              <TabPanel>
                <LenderDetails application_id={application_id} />
              </TabPanel>
              <TabPanel>
                <PaymentMethod />
              </TabPanel>
              <TabPanel>
                {!journeyData?.is_field_investigation_done ? (
                  <FieldInvestigationV2 setLoader={setLoadingOver} />
                ) : (
                  <FieldInvestigationDone />
                )}
              </TabPanel>
              <TabPanel>
                <Customer
                  application_id={application_id}
                  openStatus={() => setOpen({ ...openStatus, open: true })}
                />
              </TabPanel>
              {journeyData?.co_customer_exist && (
                <TabPanel>
                  <CoCustomers
                    application_id={application_id}
                    openStatus={() => setOpen({ ...openStatus, open: true })}
                  />
                </TabPanel>
              )}
              <TabPanel>
                <LoanDocuments
                  application_id={application_id}
                  openStatus={() => setOpen({ ...openStatus, open: true })}
                />
              </TabPanel>
              <TabPanel>
                <VehicleDocuments
                  application_id={application_id}
                  openStatus={() => setOpen({ ...openStatus, open: true })}
                />
              </TabPanel>
              <TabPanel>
                <BankDocuments
                  application_id={application_id}
                  openStatus={() => setOpen({ ...openStatus, open: true })}
                />
              </TabPanel>
            </Tabs>
          </>
        </div>
      </Box>

      <StatusDialog
        open={openStatus?.open}
        handleClose={handleClose}
        application_id={application_id}
        defaultTab={openStatus?.default}
        coExist={journeyData?.co_customer_exist}
      />

      <DocPopup
        open={docPopup?.open}
        type={docPopup?.type}
        application_id={application_id}
        handleClose={() => {
          setDocPopup({ open: false, type: "" });
        }}
        checkStatus={() => {
          setOpen({ ...openStatus, open: true, default: 'rejected' });
        }}
      />

      {isLoadingOver && <LoadingOver />}
    </>
  );
});

export default DocumentUpload;
