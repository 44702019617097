import axios from "axios";
import { verifyPincode } from "../api";

export const isNameValid = (val) => {
  if (val === "") {
    return "Cannot be blank";
  }
  if (val[0] === " ") return "Must start with an Alphabet";
  if (!/^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/.test(val))
    return "Enter a valid name (Alphabets only. Atleast 3 char. Cannot end with space)";
  return "";
};

export const isEmailValid = (val) => {
  let emailRegx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (val === "") {
    return "";
  }
  if (!emailRegx.test(val)) {
    return "Email not valid";
  }
  return "";
};

export const isMobValid = (val) => {
  let phoneRegx = /^[6-9]\d{9}$/gi;
  if (val === "") {
    return "Cannot be blank";
  }
  if (!phoneRegx.test(val)) {
    return "Phone number not valid";
  }
  return "";
};

export const isBlank = (val) => {
  if (val === "") return "Cannot be blank";
  if (val[0] === " ") return "Cannot not start with space";
  return "";
};

export const isStartSpace = (val) => {
  if (val === "") return "";
  if (val[0] === " ") return "Cannot not start with space";
  return "";
};

export const isDobValid = (val, max) => {
  if (val === "") return "Select your DOB";
  if (new Date(val) > new Date(max)) return "Age must be above 18";
  return "";
};

// export const isPincodeValid = async (pincode, setResponse) => {
//   if (pincode.length < 6) {
//     setResponse((prev) => ({
//       ...prev,
//       status: false,
//       state: "",
//       msg:"Invalid pincode"
//     }));
//     return;
//   }
//   setResponse((prev) => ({ ...prev, loading: true }));
//   try {
//     let { data } = await axios.get(
//       `https://api.postalpincode.in/pincode/${pincode}`
//     );
//     setResponse((prev) => ({ ...prev, loading: false }));
//     if (data[0].Status === "Success") {
//       setResponse((prev) => ({
//         ...prev,
//         status: true,
//         state: data[0].PostOffice[0].State,
//         msg:""
//       }));
//     } else {
//       setResponse((prev) => ({
//         ...prev,
//         status: false,
//         msg:"Invalid pincode",
//         state: "",
//       }));
//     }
//   } catch (error) {
//     setResponse((prev) => ({
//       ...prev,
//       status: false,
//       msg:"Failed to check. Try again",
//       state: "",
//     }));
//   }
// };

export const isPincodeValid = async (pincode, setResponse) => {
  if (pincode.length < 6) {
    setResponse((prev) => ({
      ...prev,
      status: false,
      state: "",
      msg: "Invalid pincode",
    }));
    return;
  }
  setResponse((prev) => ({ ...prev, loading: true }));
  try {
    let { data } = await verifyPincode(pincode);
    setResponse((prev) => ({ ...prev, loading: false }));

    if (data?.status) {
      setResponse((prev) => ({
        ...prev,
        status: true,
        msg: "",
      }));
    } else {
      setResponse((prev) => ({
        ...prev,
        status: false,
        msg: "Invalid pincode",
        state: "",
      }));
    }
  } catch (error) {
    setResponse((prev) => ({
      ...prev,
      status: false,
      msg: "Failed to check. Try again",
      state: "",
    }));
  }
};

export const isAccValid = (val) => {
  if (!/^\d{9,18}$/.test(val)) return "Enter a valid account number";
  return "";
};

export const isIFSCValid = (val) => {
  console.log(val);
  if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(val)) return "Enter a valid IFSC code";
  return "";
};

export const isPassword = (val) => {
  let pattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (pattern.test(val)) {
    return "";
  }
  return "Must contain at least 8 characters, alteast one lower, uppercase and special char";
};

export const isAddressValid = (val) => {
  if (val === "") return "Cannot be blank";
  if (val.length < 8) return "Address is too short";
  if (/[a-zA-Z]/.test(val)) {
    return "";
  }
  return "Invalid Address";
};
