import { Button, CssBaseline, Dialog, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchQr,
  physicalMandate,
  uploadDocument,
  uploadMandate,
} from "../../../../api/agent";
import MenuAppBar from "../../../../components/Appbar";
import UploadCard from "../../../../components/V3/UploadCard";
import { baseURL, environment } from "../../../../config";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { getBase64 } from "../../../../util/convertFileBase";
import ReplayIcon from "@mui/icons-material/Replay";
import { l } from "../../../../util/languageConvertor";
import MixPanelAudit from "../../../../util/MixPanelAudit";

const qrDataInitial = {
  open: false,
  qr_code: "",
};

const CardComp = ({ heading, subtext, Buttons }) => {
  return (
    <div className="payment-card">
      <div className="upload-card-v3" style={{ height: "160px" }}>
        <p className="heading mb-0">{heading}</p>
        <p className="desc-text" style={{ color: "#6A6A6A" }}>
          {subtext}
        </p>
        <div className="button-holder">{Buttons}</div>
      </div>
    </div>
  );
};

const PaymentMethod = () => {
  const navigate = useNavigate();
  const fileInput = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { application_id } = useParams();
  const { journeyState } = useContext(LoanContext);
  const { bottomNav, showRewardState, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [journeyData, setJourneyData] = journeyState;
  const [isReward, showReward] = showRewardState;
  const [isUploaded, setIsUploaded] = useState(false);

  const [isDownloaded, setDownloaded] = useState(false);
  const [qrData, setQrData] = useState(qrDataInitial);
  const [isQrLoading, setQrLoading] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    enach: "pending",
    upi: "pending",
  });

  const handleSubmit = async () => {};

  const handleBack = async () => {
    navigate(`/draft-applications`);
  };

  const { getApplicationDetails, getStage, getRealStatus } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    setStatus({
      ...status,
      enach: getRealStatus(journeyData?.enach_status, "enach"),
      upi: getRealStatus(journeyData?.upi_status, "upi"),
    });
  }, [journeyData?.enach_status, journeyData?.upi_status]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  useEffect(() => {
    setIsUploaded(journeyData?.physical_nach_uploaded);
  }, [journeyData?.physical_nach_uploaded]);

  const handlePhysicalMandate = async () => {
    setLoading(true);
    try {
      console.log(journeyData?.physical_nach_status);
      if (journeyData?.physical_nach_status) {
        console.log(`physical_nach/${application_id}`);
        await handleDownload(
          `nach_form_unsigned/${application_id}`,
          `nach_form_${application_id}.pdf`
        );
        setDownloaded(true);
      } else {
        // console.log("Hereee");
        let { data } = await physicalMandate(application_id);
        await handleDownload(
          `nach_form_unsigned/${application_id}`,
          `nach_form_${application_id}.pdf`
        );
        setJourneyData({ ...journeyData, physical_nach_status: true });
        setDownloaded(true);
      }
      MixPanelAudit.intercept("Physical Mandate Downloaded", {
        application_id,
      });
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleDownload = async (key, fileName) => {
    await fetch(`${baseURL[environment]}/common/view?key=${key}`, {
      method: "get",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        application: "AGENT-ERICK-V2",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement("a");
        aElement.setAttribute("download", fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        // aElement.setAttribute('href', href);
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      });
  };

  // const uploadNach = async (file) => {
  //   console.log(journeyData?.mandate_id);
  //   fileInput.current.value = "";
  //   try {
  //     // console.log(await getBase64(file));
  //     let base64 = await getBase64(file);
  //     let { data } = await uploadMandate({
  //       document_include_g:true,
  //       mandate_id: journeyData?.mandate_id,
  //       application_id: application_id,
  //       file_data: base64,
  //     });
  //     enqueueSnackbar(data?.message, { variant: "success" });
  //   } catch (error) {
  //     enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
  //   }
  // };

  const uploadNach = async (file) => {
    // setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name: "nach_form",
              file: await getBase64(file),
            },
          ],
        },
        application_id,
        `?override=true`
      );
      getApplicationDetails(application_id, true);
    } catch (error) {}
    // setLoading("");
  };

  const handleQr = async () => {
    setQrLoading(true);
    try {
      let { data } = await fetchQr({
        application_id,
      });
      setQrData({
        open: true,
        qr_image:
          data?.insta_collect?.virtual_account
            ?.upi_qrcode_remote_file_location ||
          data?.data?.virtual_account?.upi_qrcode_remote_file_location,
      });
    } catch (error) {
      enqueueSnackbar(l(error?.response?.data?.message, lang), {
        variant: "error",
      });
    }
    setQrLoading(false);
  };

  const StatusChip = ({ text, color }) => {
    return (
      <span
        style={{
          background: color,
          color: "white",
          padding: "5px 20px",
          borderRadius: 20,
        }}
      >
        {text}
      </span>
    );
  };

  return (
    <>
      <MenuAppBar
        home={false}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
      />
      <CssBaseline />
      <div className="p-2">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <CardComp
              heading={l("UPI Autopay", lang)}
              subtext={l("Registration Using VPA", lang)}
              Buttons={
                <>
                  {["pending", "unsuccessfull"].includes(status?.upi) && (
                    <Button
                      className="btn"
                      onClick={() => {
                        navigate(
                          `/create-loan/payment-method/upi/${application_id}`
                        );
                      }}
                    >
                      {status?.upi === "unsuccessfull"
                        ? l("Try Again", lang)
                        : l("Register", lang)}
                    </Button>
                  )}
                  {["Link Sent"].includes(status?.upi) && (
                    <>
                      <ReplayIcon
                        className="me-2 pointer"
                        onClick={() => {
                          navigate(
                            `/create-loan/payment-method/upi/${application_id}`
                          );
                        }}
                      />
                      <StatusChip text={l("Link Sent", lang)} color="#c29644" />
                    </>
                  )}
                  {["successfull"].includes(status?.upi) && (
                    <StatusChip text={l("Success", lang)} color="#4caf50" />
                  )}
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CardComp
              heading={l("E-Nach", lang)}
              subtext={l("Debit card/ Net Banking", lang)}
              Buttons={
                <>
                  {["pending", "unsuccessfull"].includes(status?.enach) && (
                    <Button
                      className="btn"
                      onClick={() => {
                        navigate(
                          `/create-loan/payment-method/enach/${application_id}`
                        );
                      }}
                    >
                      {status?.enach === "unsuccessfull"
                        ? l("Try Again", lang)
                        : l("Register", lang)}
                    </Button>
                  )}
                  {["successfull"].includes(status?.enach) && (
                    <StatusChip text={l("Success", lang)} color="#4caf50" />
                  )}
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CardComp
              heading={l("Physical Nach", lang)}
              subtext={l("Debit card/ Net Banking", lang)}
              Buttons={
                isUploaded ? (
                  <>
                    <ReplayIcon
                      className="me-2 pointer"
                      onClick={() => {
                        setIsUploaded(false);
                        setDownloaded(true);
                      }}
                    />
                    <StatusChip text={l("Uploaded", lang)} color="#4caf50" />
                  </>
                ) : (
                  <>
                    <Button
                      className="btn"
                      disabled={isLoading}
                      onClick={() => {
                        handlePhysicalMandate();
                      }}
                    >
                      {!isLoading
                        ? isDownloaded
                          ? l("Download Again",lang)
                          : l("Download", lang)
                        : l("Loading", lang)}
                    </Button>
                    {isDownloaded && (
                      <>
                        <input
                          ref={fileInput}
                          type="file"
                          class="custom-file-input"
                          accept="image/*"
                          onChange={async (e) => {
                            if (
                              !e.target.files[0]?.type.match(/image.*/)
                            ) {
                              enqueueSnackbar("Supports Image files only", { variant: "error" });
                              return false;
                            }
                            uploadNach(e.target.files[0]);
                          }}
                          hidden
                        />
                        <Button
                          className="btn ms-3"
                          onClick={() => {
                            fileInput.current.click();
                          }}
                        >
                          {l("Upload", lang)}
                        </Button>
                      </>
                    )}
                  </>
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CardComp
              heading={l("Payment via QR", lang)}
              // subtext="Debit Card / Net Banking"
              Buttons={
                <>
                  <Button
                    className="btn ms-3"
                    onClick={() => {
                      handleQr();
                    }}
                    disabled={isQrLoading}
                  >
                    {isQrLoading ? l("Loading", lang) : l("View QR", lang)}
                  </Button>
                </>
              }
            />
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={qrData?.open}
        onClose={() => {
          setQrData(qrDataInitial);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img src={qrData?.qr_image} alt="QR" />
        <Button
          color="secondary"
          onClick={() => window.open(qrData?.qr_image, "_blank")}
        >
          Download
        </Button>
      </Dialog>
    </>
  );
};

export default PaymentMethod;
