import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { proceedPayment } from "../../../../api/agent";
import MenuAppBar from "../../../../components/Appbar";
// import CircularProgress from "../../../../components/CircularProgress";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";

const styles = {
  mainHead: {
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 40,
  },
  card: {
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    padding: "30px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  card_disabled: {
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    padding: "30px",
    borderRadius: "10px",
    opacity: 0.4,
  },
  col: {
    display: "flex",
    alignItems: "Center",
    fontSize: "18px",
    fontWeight: "500",
  },

  colProgress: {
    display: "flex",
    justifyContent: "end",
    alignItems: "Center",
  },

  cardHeading: {
    fontWeight: 400,
    marginBottom: 0,
  },

  cardDesc: {
    fontWeight: 400,
    marginBottom: 0,
    color: "gray",
  },

  statusTxt: {
    marginBottom: 0,
    marginRight: 10,
  },
};

const statusColor = {
  pending: "#573A7A",
  unsuccessfull: "#FF0000",
  successfull: "#2AB514",
};

const PaymentMethod = forwardRef((props, ref) => {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { bottomNav, showRewardState } = useContext(GlobalContext);
  const { application_id } = useParams();
  const [isReward, showReward] = showRewardState;
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    enach: "pending",
    upi: "pending",
  });

  const handleSubmit = async () => {};

  const handleBack = async () => {
    navigate(`/draft-applications`);
  };

  const { getApplicationDetails, getStage, getRealStatus } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    setStatus({
      ...status,
      enach: getRealStatus(journeyData?.enach_status, 'enach'),
      upi: getRealStatus(journeyData?.upi_status, 'upi'),
    });
  }, [journeyData?.enach_status, journeyData?.upi_status]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  const handleProceed = async () => {
    setLoading(true);
    try {
      const { data } = await proceedPayment(application_id);
      if (data?.status) {
        if (data?.is_coin_credited) {
          showReward({
            show: true,
            coin: data?.coin,
          });
        }
        navigate(`/create-loan/loan-status/${application_id}`);
      }
    } catch (error) {}
    setLoading(true);
  };

  return (
    <>
      <Box className="kyc-wrapper w-100" sx={{ pb: 7, pt: 7 }} ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="main-wrapper">
          <p style={styles?.mainHead}>Choose Payment Method</p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Container
                style={styles?.card}
                onClick={() => {
                  navigate(
                    `/create-loan/payment-method/enach/${application_id}`
                  );
                }}
              >
                <Row>
                  <Col xs={8} md={8} style={styles?.col}>
                    <div>
                      <p style={styles?.cardHeading}>e-NACH</p>
                      <p style={styles?.cardDesc}>Debit Card / Net Banking</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4} style={styles?.colProgress}>
                    <p
                      style={{
                        ...styles?.statusTxt,
                        color: statusColor[status.enach],
                      }}
                    >
                      {status?.enach}
                    </p>
                  </Col>
                </Row>
              </Container>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Container
                style={styles?.card}
                onClick={() => {
                  navigate(`/create-loan/payment-method/upi/${application_id}`);
                }}
              >
                <Row>
                  <Col xs={8} md={8} style={styles?.col}>
                    <div>
                      <p style={styles?.cardHeading}>UPI Autopay</p>
                      <p style={styles?.cardDesc}>Registration using VPA</p>
                    </div>
                  </Col>
                  <Col xs={4} md={4} style={styles?.colProgress}>
                    <p
                      style={{
                        ...styles?.statusTxt,
                        color: statusColor[status.upi],
                      }}
                    >
                      {status?.upi}
                    </p>
                  </Col>
                </Row>
              </Container>
            </Grid>
          </Grid>
        </div>

        <Paper className={`paper-bottom`} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className={`btn-submit ${
                  (isLoading ||
                    status.enach === "pending" ||
                    status.upi === "pending") &&
                  "btn-submit-disabled"
                }`}
                className={`btn-submit`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleProceed}
                // disabled={
                //   isLoading ||
                //   status.enach === "pending" ||
                //   status.upi === "pending"
                // }
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default PaymentMethod;
