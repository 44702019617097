export const FILE_URL = 'https://api.eriksha.embifi.in/v1/common/view?'
export const BanksList = [
  { bank: "ADCB" },
  { bank: "AG's Bank" },
  { bank: "ANZ Bank" },
  { bank: "AU Small Finance Bank" },
  { bank: "Abasaheb Patil Rendal Sahakari Bank Ltd" },
  { bank: "Abhinandan Urban Co-Op Bank Ltd" },
  { bank: "Abhudaya Co-Op bank Ltd." },
  { bank: "Acme Bank Ltd." },
  { bank: "Adarniya P.D.Patilsaheb Sahakari Bank Ltd" },
  { bank: "Adarsh Bank" },
  { bank: "Adarsh Mahila Mercantile Co-Op Bank" },
  { bank: "Adilabad District Co-Op Central Bank Ltd" },
  { bank: "Adv Shamraoji Shinde Satyashodhak Sahakari Bank Ltd" },
  { bank: "Agrasen Co-op Urban Bank Ltd" },
  { bank: "Agroha Co-Op Urban Bank Ltd" },
  { bank: "Ahmedabad District Co-Op. Bank Ltd" },
  { bank: "Ahmedabad Mercantile Co-op Bank Ltd." },
  { bank: "Ahmednagar Mercantile Co-Op. Bank Ltd" },
  { bank: "Ahmednagar Shahar Sahakari Bank" },
  { bank: "Airtel Payments Bank" },
  { bank: "Akhand Anand Co-Operative Bank Ltd" },
  { bank: "Akola District Central Co-Op. Bank Ltd" },
  { bank: "Akola Janata Commercial Co-Op. Bank Ltd" },
  { bank: "Alavi Co-Op Bank Ltd" },
  { bank: "Alibag Co-Op Urban Bank Ltd" },
  { bank: "Allahabad Bank" },
  { bank: "Almora Urban Co-Op Bank Ltd" },
  { bank: "Almora Zila Sahkari Bank" },
  { bank: "Aman Sahakari Bank Ltd" },
  { bank: "Amarnath Co-Op. Bank Ltd" },
  { bank: "Ambajogai Peoples Co-Op. Bank" },
  { bank: "Ambernath Jai-Hind Co-Op Bank Ltd" },
  { bank: "American Express Bank" },
  { bank: "Amreli Jilla Madhyastha Sahakari Bank Ltd" },
  { bank: "Amreli Nagarik Sahakari Bank" },
  { bank: "Anand Mercantile Co-Op Bank Ltd" },
  { bank: "Anandeshwari Nagrik Sahakari Bank" },
  { bank: "Anant Nagari Sahakari Bank" },
  { bank: "Anantapur District Co-Op Central Bank Ltd" },
  { bank: "Andhra Bank" },
  { bank: "Andhra Pradesh Grameena Vikas Bank" },
  { bank: "Andhra Pragathi Grameena Bank" },
  { bank: "Annasaheb Magar Sahakari Bank Ltd" },
  { bank: "Apna Sahakari Bank" },
  { bank: "Appasaheb Birnale Sahakari Bank Ltd" },
  { bank: "Arihant Co-operative Bank Ltd" },
  { bank: "Arunachal Pradesh Rural Bank" },
  { bank: "Arvind Sahakari Bank Ltd." },
  { bank: "Aryavart Bank" },
  { bank: "Ashok Nagari Sahakari Bank Ltd" },
  { bank: "Ashok Sahakari Bank Ltd" },
  { bank: "Ashokanagar Co-Op Bank Ltd" },
  { bank: "Ashta Peoples Co-Op Bank Ltd" },
  { bank: "Assam Gramin Vikash Bank Ltd" },
  { bank: "Associate Co-Op. Bank Ltd" },
  { bank: "Axis Bank" },
  { bank: "Ayshwarya Syndicate Souharda Credit Co-Op Ltd" },
  { bank: "Azad Urban Co-Op Bank Ltd" },
  { bank: "BNP Paribas" },
  { bank: "Badagabettu Co-Op Society Ltd" },
  { bank: "Balasinor Nagarik Sahakari Bank Ltd" },
  { bank: "Ballari District Central Co-Op Bank Ltd" },
  { bank: "Balotra Urban Co-Op Bank Ltd" },
  { bank: "Banaras Merchantile Co-Op Bank" },
  { bank: "Banas Bank" },
  { bank: "Banda Urban Co-Op Bank" },
  { bank: "Bandhan Bank" },
  { bank: "Bangiya Gramin Vikash Bank" },
  { bank: "Bank of Bahrain and Kuwait" },
  { bank: "Bank of Baroda" },
  { bank: "Bank of India" },
  { bank: "Bank of Maharashtra" },
  { bank: "Bankura District Central Co-Op Bank Ltd" },
  { bank: "Baramati Sahakari Bank" },
  { bank: "Baran Nagarik Sahkari Bank Ltd" },
  { bank: "Barclays Bank" },
  { bank: "Baroda Gujarat Gramin Bank" },
  { bank: "Baroda Rajasthan Kshetriya Gramin Bank" },
  { bank: "Baroda Uttar Pradesh Gramin Bank" },
  { bank: "Bassein Catholic Co-Op. Bank Ltd" },
  { bank: "Beawar Urban Co-Op Bank Ltd" },
  { bank: "Belagavi Shree Basaveshwar Co-Op Bank Ltd" },
  { bank: "Betul Nagrik Sahakari Bank" },
  { bank: "Bhadohi Urban Co-Op Bank" },
  { bank: "Bhadradri Co-Op Urban Bank" },
  { bank: "Bhagini Nivedita Sahakari Bank Ltd" },
  { bank: "Bhagyodaya Co-Op. Bank Ltd" },
  { bank: "Bhandara District Central Co-Op Bank Ltd" },
  { bank: "Bharati Sahakari Bank Ltd" },
  { bank: "Bharatpur Central Co-Op Bank Ltd" },
  { bank: "Bhausaheb Birajdar Nagari Sahakari Bank" },
  { bank: "Bhavani Sahakari Bank Ltd" },
  { bank: "Bhavani Urban Co-Op Bank Ltd" },
  { bank: "Bhilai Nagarik Sahakari Bank Maryadit" },
  { bank: "Bhilwara Urban Co-Op Bank Ltd" },
  { bank: "Bhingar Urban Co-Op Bank Ltd" },
  { bank: "Bhuj Commercial Co-Op Bank Ltd" },
  { bank: "Bicholim Urban Co-Op Bank Ltd" },
  { bank: "Bihar Gramin Bank Ltd" },
  { bank: "Birdev Sahakari Bank Ltd" },
  { bank: "Bombay Mercantile Co-Operative Bank Ltd." },
  { bank: "Brahmadeodada Mane Sahakari Bank Ltd" },
  { bank: "Buldana Urban Co-Op Credit Society" },
  { bank: "Business Co-Op Bank Ltd" },
  { bank: "CTBC Bank" },
  { bank: "Calicut City Service Co-Op Bank" },
  { bank: "Canara Bank" },
  { bank: "Capital Local Area Bank" },
  { bank: "Capital Small Finance Bank" },
  { bank: "Cardamom Merchants Co-Op Bank Ltd" },
  { bank: "Catholic Syrian Bank" },
  { bank: "Central Bank of India" },
  { bank: "Chaitanya Godavari Grameena Bank" },
  { bank: "Chamoli District Co-Op Bank" },
  { bank: "Chandrabhaga Urban Multistate Co-Op Credit Society" },
  { bank: "Chartered Sahakari Bank Niyamitha" },
  { bank: "Chhattisgarh Rajya Gramin Bank" },
  { bank: "Chhattisgarh State Co-Op Bank Ltd" },
  { bank: "Chikhali Urban Bank" },
  { bank: "Chittorgarh Urban Co-Op Bank Ltd" },
  { bank: "Chopda Peoples Co-Op Bank Ltd" },
  { bank: "Citibank" },
  { bank: "Citizen Credit Co-Op. Bank Ltd" },
  { bank: "Citizens Co-Op. Bank Ltd" },
  { bank: "City Union Bank" },
  { bank: "Coastal Bank" },
  { bank: "Col R D Nikam Sainik Sahakari Bank Ltd" },
  { bank: "Colour Merchants' Co-Op Bank Ltd" },
  { bank: "Contai Co-Op Bank Ltd" },
  { bank: "Corporation Bank" },
  { bank: "Cosmos Co-op. Bank Ltd." },
  { bank: "Cuddapah District Central Co-Op Bank Ltd" },
  { bank: "D Dhanashri Multistate Co-Op Credit Society Ltd" },
  { bank: "D Y Patil Sahakari Bank Ltd" },
  { bank: "DBS Bank" },
  { bank: "DCB" },
  { bank: "DMK Jaoli Bank" },
  { bank: "Dakshin Bihar Gramin Bank" },
  { bank: "Dapoli Urban Bank" },
  { bank: "Darussalam Co-Op Urban Bank Ltd" },
  { bank: "Daund Urban Co-Op Bank Ltd" },
  { bank: "Dausa Urban Co-Op Bank Ltd" },
  { bank: "Davangere Urban Co-Op Bank Ltd" },
  { bank: "Deccan Merchants Co-op Bank Ltd" },
  { bank: "Deendayal Nagari Sahakari Bank Ltd" },
  { bank: "Deepak Sahakari Bank Ltd" },
  { bank: "Delhi Nagrik Sehkari Bank Ltd" },
  { bank: "Dena Bank" },
  { bank: "Deogiri Nagari Sahakari Bank Ltd" },
  { bank: "Deutsche Bank" },
  { bank: "Development Co-Op Bank Ltd" },
  { bank: "Devi Gayatri Co-Op Urban Bank Ltd" },
  { bank: "Dhanalakshmi Bank Ltd." },
  { bank: "Dhanera Mercantile Co-Op Bank Ltd" },
  { bank: "Dharmavir Sambhaji Urban Co-Op Bank Ltd" },
  { bank: "Dhinoj Nagrik Co-Op Bank Ltd" },
  { bank: "Dhule Vikas Sahakari Bank Ltd" },
  { bank: "District Co-Op Central Bank Ltd Bidar" },
  { bank: "Dnyanradha Multi State Co-Op Credit Society Ltd" },
  { bank: "Doha Bank" },
  { bank: "Dombivli Nagari Sahakari Bank" },
  { bank: "Dr Jaiprakash Mundada Urban Co Op Bank Ltd" },
  { bank: "Dr. Annasaheb Chougule Urban Co-Op. Bank Ltd." },
  { bank: "Dr. Babasaheb Ambedkar Urban Co-Op Bank Ltd" },
  { bank: "Durgapur Steel Peoples Co-Op Bank Ltd" },
  { bank: "ESAF Small Finance Bank" },
  { bank: "Eastern & North-East Frontier Railway Co-Op Bank Ltd" },
  { bank: "Equitas Bank" },
  { bank: "Excellent Co-Op Bank" },
  { bank: "Federal Bank" },
  { bank: "Fin Growth Co-Op bank Ltd." },
  { bank: "Fincare Small Finance Bank" },
  { bank: "Fino Payment Bank" },
  { bank: "GP Parsik Bank" },
  { bank: "GS Mahanagar Co-Op. Bank Ltd" },
  { bank: "Gadhinglaj Urban Co-Op. Bank Ltd" },
  { bank: "Gandhibag Sahakari Bank Ltd" },
  { bank: "Gandhinagar Nagrik Co-Op Bank" },
  { bank: "Gautam Sahakari Bank Ltd" },
  { bank: "Goa State Co-Op Bank Ltd" },
  { bank: "Godavari Urban Co-Op Bank" },
  { bank: "Gondal Nagrik Sahakari Bank Ltd" },
  { bank: "Greater Bombay Co-op Bank Ltd" },
  { bank: "Guardian Bank Ltd" },
  { bank: "Guardian Souharda Sahakari Bank" },
  { bank: "Gujarat Ambuja Co-Op Bank Ltd." },
  { bank: "Gujarat Mercantile Co-Op Bank" },
  { bank: "Gujarat State Co-Op. Bank Ltd" },
  { bank: "Gulshan Mercantile Urban Co-Op Bank" },
  { bank: "Guntur District Co-Op Central Bank Ltd" },
  { bank: "Gurgaon Central Co-Op Bank Ltd" },
  { bank: "HCBL Co-operative Bank Ltd." },
  { bank: "HDFC Bank" },
  { bank: "HP State Co-Op Bank Ltd" },
  { bank: "HSBC" },
  { bank: "Hadagali Urban Co-Op Bank" },
  { bank: "Hanamasagar Urban Co-Op Bank Ltd" },
  { bank: "Hasti Co-Op Bank Ltd" },
  { bank: "Haveli Sahakari Bank Ltd" },
  { bank: "Himatnagar Nagarik Sahakari Bank Ltd" },
  { bank: "Hindusthan Co-Op Bank Ltd" },
  { bank: "Hutatma Sahakari Bank Ltd" },
  { bank: "ICICI Bank" },
  { bank: "IDBI" },
  { bank: "IDFC FIRST Bank" },
  { bank: "IMC Bank Ltd" },
  { bank: "Imphal Urban Co-operative Bank Ltd" },
  { bank: "India Post Payments Bank" },
  { bank: "Indian Bank" },
  { bank: "Indian Overseas Bank" },
  { bank: "Indore Cloth Market Co-Op Bank Ltd" },
  { bank: "Indore Paraspar Sahakari Bank Ltd" },
  { bank: "Indore Premier Co-Op Bank Ltd" },
  { bank: "Indraprastha Sehkari Bank Ltd" },
  { bank: "Indrayani Co-Op Bank" },
  { bank: "IndusInd Bank" },
  { bank: "Integral Urban Co-Op Bank Ltd" },
  { bank: "Irinjalakuda Town Co-Op Bank Ltd" },
  { bank: "J&K Bank" },
  { bank: "Jai Bhavani Sahakari Bank" },
  { bank: "Jai Tuljabhavani Urban Co-Op Bank" },
  { bank: "Jaihind Urban Co-Op Bank Ltd" },
  { bank: "Jain Co-Op Bank Ltd" },
  { bank: "Jalgaon Janata Bank" },
  { bank: "Jalgaon Peoples Co-Op Bank Ltd" },
  { bank: "Jalna Merchants Co-Op Bank Ltd" },
  { bank: "Jalore Nagrik Sah Bank Ltd" },
  { bank: "Jamia Co-Op Bank Ltd" },
  { bank: "Jamnagar District Co-Op Bank Ltd" },
  { bank: "Jamshedpur Urban Co-Op Bank Ltd" },
  { bank: "Jana Small Finance Bank Ltd" },
  { bank: "Janalaxmi Co-Op Bank" },
  { bank: "Janaseva Sahakari Bank Ltd" },
  { bank: "Janata Sahakari Bank" },
  { bank: "Janatha Seva Co-Op. Bank Ltd" },
  { bank: "Jandhan Nagari Sahkari Patsanstha Ltd Kolhapur" },
  { bank: "Jankalyan Co-Op Bank Ltd" },
  { bank: "Jankalyan Sahakari Bank" },
  { bank: "Jankalyan Urban Co-Op Bank" },
  { bank: "Jansewa Urban Co-Op Bank Ltd" },
  { bank: "JayPrakash Narayan Nagari Sahakari Bank Ltd" },
  { bank: "Jaysingpur Udgaon Sahakari Bank Ltd" },
  { bank: "Jharkhand Rajya Gramin Bank" },
  { bank: "Jijamata Mahila Sahakari Bank" },
  { bank: "Jivaji Sahkari Bank Ltd" },
  { bank: "Jivan Commercial Co-Op Bank Ltd" },
  { bank: "Junagadh Commercial Co-Op Bank Ltd" },
  { bank: "Jyoti Kranti Co-Op Credit Society Ltd" },
  { bank: "KEB Hana Bank" },
  { bank: "Kakinada Co-Op Central Bank Ltd" },
  { bank: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank" },
  { bank: "Kalol Nagarik Sahakari Bank Ltd" },
  { bank: "Kalyan Janata Sahakari Bank Ltd" },
  { bank: "Kamala Co-Op Bank Ltd" },
  { bank: "Kangra Co-op Bank Ltd" },
  { bank: "Kankaria Maninagar Nagrik Sahakari Bank Ltd" },
  { bank: "Kannur District Co-Op Bank" },
  { bank: "Karimnagar District Co-Op Central Bank Ltd" },
  { bank: "Karnala Nagari Sahakari Bank Ltd" },
  { bank: "Karnataka Bank" },
  { bank: "Karnataka Central Co-Op Bank Ltd" },
  { bank: "Karnataka Gramin Bank" },
  { bank: "Karnataka Mahila Sahakara Bank" },
  { bank: "Karnataka Vikas Grameena Bank Ltd" },
  { bank: "Karur Vysya Bank" },
  { bank: "Kashi Gramin Bank" },
  { bank: "Kashipur Urban Co-Op Bank Ltd." },
  { bank: "Kaveri Grameena Bank" },
  { bank: "Kerala Gramin Bank Ltd" },
  { bank: "Kerala State Co-Op Bank" },
  { bank: "Keshav Sehkari Bank Ltd" },
  { bank: "Khamgaon Urbank Co-op Bank Ltd" },
  { bank: "Khammam District Co-Op Central Bank" },
  { bank: "Khardah Co-Op Bank Ltd" },
  { bank: "Kheralu Nagrik Sahakari Bank Ltd" },
  { bank: "Kholeshwar Multistate Co-Op Credit Society Ltd" },
  { bank: "Kohinoor Sahakari Bank Ltd" },
  { bank: "Koilkuntla Co-Op Bank Ltd" },
  { bank: "Kokan Mercantile Co-Op. Bank Ltd" },
  { bank: "Kolhapur District Central Co-Operative Bank Ltd" },
  { bank: "Kolhapur Mahila Sahakari Bank Ltd" },
  { bank: "Kolhapur Urban Co-Op Bank" },
  { bank: "Konark Urban Co-Op Bank Ltd" },
  { bank: "Kopargaon Peoples Co-Op Bank" },
  { bank: "Kotak Mahindra Bank" },
  { bank: "Kozhikode District Co-Op Bank" },
  { bank: "Krishna Bhima Samruddhi Bank" },
  { bank: "Krishna District Co-Op Central Bank Ltd" },
  { bank: "Krishna Grameena Bank" },
  { bank: "Krishna Mercantile Co-Op Bank Ltd" },
  { bank: "Krishna Sahakari Bank Ltd" },
  { bank: "Kuberamitra Nidhi Ltd" },
  { bank: "Kukarwada Nagrik Sahakari Bank" },
  { bank: "Kumbhi Kasari Sahakari Bank" },
  { bank: "Kurmanchal Bank" },
  { bank: "Kurnool District Central Co-Op Bank Ltd" },
  { bank: "Kutch Co-Op Bank Ltd" },
  { bank: "Kuttiady Co-Op Urban Bank" },
  { bank: "Lakshmi Vilas Bank" },
  { bank: "Lala Urban Co-Op Bank Ltd" },
  { bank: "Langpi Dehangi Rural Bank" },
  { bank: "Latur Multistate Co-Op Credit Society Ltd" },
  { bank: "Latur Urban Co-Op. Bank Ltd" },
  { bank: "Laxmi Urban Co-op Bank" },
  { bank: "Liluah Co-Op Bank" },
  { bank: "Lokmangal Co-Op Bank Ltd" },
  { bank: "Lokmangal Nagari Sahakari Patsanstha Maryadit" },
  { bank: "Lokvikas Nagari Sahakari Bank" },
  { bank: "Lonavala Sahakari Bank" },
  { bank: "M D Pawar Peoples Co-Op Bank Ltd" },
  { bank: "M S Co-Op. Bank Ltd" },
  { bank: "MBabaji Date Mahila Sahakari Bank Ltd" },
  { bank: "Madheshwari Urban Development Co-Op Bank" },
  { bank: "Madhya Pradesh Gramin Bank" },
  { bank: "Madhyanchal Gramin Bank" },
  { bank: "Madurai District Central Co-Op Bank Ltd" },
  { bank: "Mahabubnagar District Co-Op Central Bank" },
  { bank: "Mahanagar Nagrik Sahakari Bank" },
  { bank: "Maharashtra Nagari Sahakari Bank" },
  { bank: "Maharastra Gramin Bank" },
  { bank: "Mahatma Fule Urban Co Op Bank" },
  { bank: "Mahaveer Co-Op Urban Bank Ltd" },
  { bank: "Mahesh Bank" },
  { bank: "Mahesh Sahakari Bank Ltd" },
  { bank: "Mahesh Urban Co-Operative Bank Ltd" },
  { bank: "Maheshwar Multistate Co-Op Credit Soc Ltd" },
  { bank: "Mahila Sahakari Bank" },
  { bank: "Mahila Urban Co-Op Bank Ltd" },
  { bank: "Mahila Vikash Co-Op Bank Ltd" },
  { bank: "Makarpura Industrial Estate Co-Op Bank Ltd" },
  { bank: "Malviya Urban Co-Op Bank Ltd" },
  { bank: "Mandvi Mercantile Co-op Bank Ltd" },
  { bank: "Mandya District Co-Op Central Bank Ltd" },
  { bank: "Mangal Co-Op Bank Ltd" },
  { bank: "Mangala Credit Co-Op Society Ltd" },
  { bank: "Maninagar Co-Op. Bank Ltd" },
  { bank: "Manipal Co Op Bank" },
  { bank: "Manipur Womens Co-Op Bank Ltd" },
  { bank: "Manjeri Co-Op Urban Bank Ltd" },
  { bank: "Manorama Co-Op. Bank Ltd" },
  { bank: "Manvi Pattana Souharda Sahakari Bank Niyamita" },
  { bank: "Manwath Urban Co-Op Bank" },
  { bank: "Marathwada Urban Co-Op Credit Society Ltd" },
  { bank: "Markandey Nagari Sahakari Bank Ltd" },
  { bank: "Marketyard Commercial Co-Op Bank Ltd" },
  { bank: "Mauli Multistate Co-Op Credit Society Ltd" },
  { bank: "Medak District Co-Op Central Bank Ltd" },
  { bank: "Meghalaya Rural Bank" },
  { bank: "Mehsana District Central Co-Op Bank Ltd" },
  { bank: "Merchants Co-Op Bank Ltd" },
  { bank: "Merchants Liberal Co-Op Bank Ltd" },
  { bank: "Mizoram Rural Bank" },
  { bank: "Model Co-Op Bank Ltd" },
  { bank: "Model Co-Op Urban Bank Ltd" },
  { bank: "Motiram Agrawal Jalna Merchants Co-Op Bank Ltd" },
  { bank: "Mudgal Urban Co-Op Bank Limited" },
  { bank: "Mugberia Central Co-Op Bank Ltd" },
  { bank: "Mumbai District Central Co-Op Bank Ltd" },
  { bank: "Mysore & Chamarajanagar DCC Bank Ltd" },
  { bank: "Mysore Merchants Co-Op Bank Ltd" },
  { bank: "NKGSB Bank" },
  { bank: "NSDL Payments Bank" },
  { bank: "Nadia District Central Co-Op Bank" },
  { bank: "Nagar Sahakari Bank" },
  { bank: "Nagar Urban Co-Op. Bank Ltd" },
  { bank: "Nagarik Sahakari Bank Ltd" },
  { bank: "Nagarik Samabay Bank Ltd" },
  { bank: "Nagpur Mahanagar Palika Karmachari Sahakari Bank Ltd" },
  { bank: "Nagpur Nagarik Sahakari Bank" },
  { bank: "Nainital Bank" },
  { bank: "Nalgonda DCC Bank" },
  { bank: "Nandani Sahakari Bank Ltd" },
  { bank: "Nanded Merchants Co-Op Bank Ltd" },
  { bank: "Narayani Mahila Nagari Sahkari Patsanstha Ltd Kolhapur" },
  { bank: "Naroda Nagrik Co-Op. Bank Ltd" },
  { bank: "Nashik Jilha Mahila Sahakari Bank Ltd" },
  { bank: "National Bank" },
  { bank: "National Mercantile Co-Op Bank Ltd" },
  { bank: "National Urban Co-Op Bank Ltd" },
  { bank: "Navabharat Co-Op Urban Bank Ltd" },
  { bank: "Navapur Mercantile Co-Op Bank Ltd" },
  { bank: "Navi Mumbai Co-Op Bank Ltd" },
  { bank: "Navjeevan Co-Op Bank Ltd" },
  { bank: "Navnirman Co-op Bank Ltd" },
  { bank: "Navsarjan Industrial Co-Op. Bank Ltd" },
  { bank: "Nawanagar Co-op Bank Ltd" },
  { bank: "Neelkanth Co-Op. Bank Ltd" },
  { bank: "Nellore District Co-Op Central Bank Ltd" },
  { bank: "Ner Urban Co-Op Credit Society Ltd" },
  { bank: "New India Co-Op Bank Ltd." },
  { bank: "Nidhi Co-Op. Bank Ltd" },
  { bank: "Nilambur Co-Op Urban Bank" },
  { bank: "Nirmal Urban Co-Op Bank Ltd" },
  { bank: "Nishigandha Sahakari Bank Ltd" },
  { bank: "Noble Co-Op Bank Ltd" },
  { bank: "Noida Commercial Co-Op. Bank Ltd" },
  { bank: "North East Small Finance Bank Ltd" },
  { bank: "Nutan Nagarik Sahakari Bank Ltd." },
  { bank: "Odisha Gramya Bank" },
  { bank: "Omkar Nagreeya Sahakari Bank" },
  { bank: "Omprakash Deora Peoples Co-Op Bank Ltd" },
  { bank: "Oriental Bank of Commerce" },
  { bank: "Osmanabad Janata Sahakari Bank Ltd" },
  { bank: "Ottapalam Co-Op Urban Bank Ltd" },
  { bank: "Padmavathi Co-Op Urban Bank" },
  { bank: "Palakkad District Co-Op Bank Ltd" },
  { bank: "Pali Urban Co-Op Bank" },
  { bank: "Palus Sahakari Bank Ltd" },
  { bank: "Pandharpur Urban Co-op Bank Ltd" },
  { bank: "Panipat Urban Co-Op. Bank Ltd" },
  { bank: "Panjabrao Deshmuk Urban Co-Op Bank" },
  { bank: "Panvel Co-Op Urban Bank Ltd" },
  { bank: "Parner Taluka Sainik Sahakari Bank Ltd" },
  { bank: "Parshwanath Co-Op. Bank Ltd." },
  { bank: "Paschim Banga Gramin Bank" },
  { bank: "Patan Co-Op Bank Ltd" },
  { bank: "Patan Nagarik Sahakari Bank Ltd" },
  { bank: "Pavana Sahakari Bank Ltd" },
  { bank: "Paytm Payments Bank" },
  { bank: "Peoples Co-Op Bank Ltd" },
  { bank: "Pimpalgaon Merchants Co-Op Bank Ltd" },
  { bank: "Pimpri Chinchwad Sahakari Bank Maryadit Pimpri" },
  { bank: "Pochampally Bank" },
  { bank: "Poornawadi Nagrik Sahakari Bank Ltd" },
  { bank: "Post Office Saving Bank" },
  { bank: "Pragathi Co Operative Bank Ltd" },
  { bank: "Pragathi Krishna Gramin Bank" },
  { bank: "Pragati Co-Op Bank Ltd" },
  { bank: "Pragati Sahakari Bank" },
  { bank: "Prathama Bank" },
  { bank: "Prathama U.P. Gramin Bank" },
  { bank: "Prathamika Krushi Pattina Sahakara Sangha Niyamitha" },
  { bank: "Pravara Sahakari Bank Ltd" },
  { bank: "Prerana Co-Op. Bank Ltd" },
  { bank: "Prerna Nagri Sahkari Bank" },
  { bank: "Prime Co-Op. Bank Ltd" },
  { bank: "Priyadarshani Nagari Sahakari Bank" },
  { bank: "Priyadarshini Urban Co-Op Bank Ltd" },
  { bank: "Proddatur Co-Op Town Bank Ltd" },
  { bank: "Progressive Merc. Co-Op. Bank Ltd" },
  { bank: "Progressive Urban Co-Op Bank" },
  { bank: "Puduppadi Service Co-Operative Bank Ltd" },
  { bank: "Pune Cantonment Sahakari Bank Ltd" },
  { bank: "Pune District Central Co-Operative Bank Ltd" },
  { bank: "Pune Merchants Co-Op Bank" },
  { bank: "Pune Municipal Corporation Servants Co-Op Urban Bank Ltd" },
  { bank: "Pune Peoples Co-Op Bank" },
  { bank: "Pune Urban Co-Op Bank Ltd" },
  { bank: "Punjab & Maharashtra Co-Op Bank" },
  { bank: "Punjab Gramin Bank" },
  { bank: "Punjab National Bank" },
  { bank: "Punjab State Co-Op Bank Ltd" },
  { bank: "Punjab and Sind Bank" },
  { bank: "Purvanchal Bank Ltd" },
  { bank: "Pusad Urban Co-Op Bank Ltd" },
  { bank: "RBL (Ratnakar) Bank" },
  { bank: "RBS (ABN AMRO)" },
  { bank: "Rabo Bank" },
  { bank: "Raigad District Central Co-Op Bank Ltd" },
  { bank: "Raiganj Central Co-Op Bank Ltd" },
  { bank: "Rajadhani Co-Op Urban Bank Ltd" },
  { bank: "Rajajinagar Co-op Bank Ltd" },
  { bank: "Rajarambapu Sahakari Bank Ltd" },
  { bank: "Rajarshi Shahu Multistate Co-Op Credit Society Ltd" },
  { bank: "Rajarshi Shahu Sahakari Bank Ltd" },
  { bank: "Rajasthan Marudhara Gramin Bank" },
  { bank: "Rajgurunagar Sahakari Bank Ltd" },
  { bank: "Rajkot Commercial Co-Op. Bank Ltd" },
  { bank: "Rajkot Nagarik Sahakari Bank Ltd" },
  { bank: "Rajkot Peoples Co-Op Bank Ltd." },
  { bank: "Rajlaxmi Urban Co-Op Bank" },
  { bank: "Rajputana Mahila Urban Co-Op Bank" },
  { bank: "Ramanagaram Urban Co-Op Bank Ltd" },
  { bank: "Rameshwar Co-Op Bank Ltd" },
  { bank: "Ramgarhia Co-Op. Bank Ltd" },
  { bank: "Ramrajya Sahakari Bank Ltd" },
  { bank: "Ratanchand Shah Sahakari Bank" },
  { bank: "Ratnagiri District Central Co-Op Bank Ltd" },
  { bank: "Rupamata Multi State Co-Op Credit Society Ltd" },
  { bank: "Rythara Seva Sahakara Bank" },
  { bank: "SBM Bank" },
  { bank: "SBTA Bank Ltd" },
  { bank: "SUCO Bank" },
  { bank: "Sadguru Nagrik Sahakari Bank Maryadit" },
  { bank: "Sadhana Sahakari Bank Ltd" },
  { bank: "Sahebrao Deshmukh Co-Operative Bank Ltd" },
  { bank: "Sahyadri Sahakari Bank Ltd" },
  { bank: "Saibaba Nagari Sahakari Bank Ltd" },
  { bank: "Samarth Sahakari Bank Ltd" },
  { bank: "Samastipur District Central Co-operative Bank Ltd" },
  { bank: "Samata Sahkari Bank Ltd" },
  { bank: "Sampada Sahakari Bank Ltd" },
  { bank: "Samruddhi Co-Op Bank Ltd" },
  { bank: "Sangli District Central Co-Op Bank Ltd" },
  { bank: "Sangli Sahakari Bank Ltd" },
  { bank: "Sangli Urban Co-Op. Bank Ltd" },
  { bank: "Sangola Urban Co-Op. Bank Ltd" },
  { bank: "Sankheda Nagarik Sahakari Bank Ltd" },
  { bank: "Sanmati Sahakari Bank Ltd" },
  { bank: "Sanmitra Sahakari Bank Ltd" },
  { bank: "Sant Sopankaka Sahakari Bank Ltd" },
  { bank: "Saptagiri Grameena Bank" },
  { bank: "Sarangpur Co-Op. Bank Ltd" },
  { bank: "Saraspur Nagarik Co-Op Bank Ltd." },
  { bank: "Saraswat Bank" },
  { bank: "Sardar Bhiladwala Pardi People's Co-Operative Bank Ltd" },
  { bank: "Sardar Singh Nagrik Sahakari Bank" },
  { bank: "Sardar Vallabhbhai Sahakari Bank Ltd" },
  { bank: "Sardarganj Mercantile Co-Op Bank Ltd" },
  { bank: "Sarjeraodada Naik Shirala Sahakari Bank Ltd" },
  { bank: "Sarva Haryana Gramin Bank" },
  { bank: "Sarva UP Gramin Bank Ltd" },
  { bank: "Sarvodaya Co-Op Bank" },
  { bank: "Sarvodaya Commercial Co-Operative Bank Ltd" },
  { bank: "Sarvodaya Nagarik Sahakari Bank Ltd" },
  { bank: "Sarvodaya Sahakari Bank" },
  { bank: "Satara District Central Co-Op Bank" },
  { bank: "Saurashtra Gramin Bank" },
  { bank: "Sawantwadi Urban Co-Op Bank Ltd" },
  { bank: "Shahada Peoples Co-Op Bank" },
  { bank: "Shalini Sahkari Bank Ltd" },
  { bank: "Shamrao Vittal Co-Operative Bank Ltd." },
  { bank: "Shankar Nagari Sahakari Bank" },
  { bank: "Sharad Sahakari Bank Ltd" },
  { bank: "Sharada Sahkari Bank Ltd" },
  { bank: "Shihori Nagrik Sahkari Bank Ltd" },
  { bank: "Shikshak Sahakari Bank Ltd" },
  { bank: "Shikshan Maharshi Dnyandev Mohekar Multistate Credit Soc. Ltd" },
  { bank: "Shimoga Arecanut Mandy Merchants Co-Op Bank Ltd" },
  { bank: "Shinhan Bank" },
  { bank: "Shirpur People Co-Op Bank Ltd" },
  { bank: "Shiva Sahakari Bank" },
  { bank: "Shivaji Nagari Sahakari Bank Ltd" },
  { bank: "Shivajirao Bhosale Sahakari Bank Ltd" },
  { bank: "Shivalik Mercantile Co-Op. Bank Ltd" },
  { bank: "Shivkrupa Sahakari Patpedhi Ltd" },
  { bank: "Shivsahyadri Sahakari Patpedhi Ltd" },
  { bank: "Shivshakti Urban Co-Op Bank Ltd" },
  { bank: "Shramajivi Nagari Sahakari Pat Sanstha Maryadit" },
  { bank: "Shramik Nagrik Sahakari Bank" },
  { bank: "Shree Bharat Co-Op Bank Ltd" },
  { bank: "Shree Co-Op. Bank Ltd" },
  { bank: "Shree Dharati Co-Op Bank Ltd" },
  { bank: "Shree Ganesh Sahakari Bank Ltd" },
  { bank: "Shree Kadi Nagarik Sahakari Bank Ltd" },
  { bank: "Shree Kanyaka Souharda Sahakari Niyamita" },
  { bank: "Shree Laxmi Co-Op Bank" },
  { bank: "Shree Mahesh Co-Op. Bank Ltd" },
  { bank: "Shree Mungsaji Maharaj Nagari Sahakari Patsanstha" },
  { bank: "Shree Samarth Sahakari Bank" },
  { bank: "Shree Savarkundla Nagrik Sahakari Bank Ltd" },
  { bank: "Shree Sharada Sahakari Bank Ltd" },
  { bank: "Shree Talaja Nagrik Sahakari Bank Ltd" },
  { bank: "Shree Vardhaman Sahakari Bank Ltd" },
  { bank: "Shree Warana Sahakari Bank Ltd" },
  { bank: "Shri Adinath Co-Op Bank Ltd" },
  { bank: "Shri Anand Co-Op. Bank Ltd" },
  { bank: "Shri Anand Nagari Sahakari Bank Ltd" },
  { bank: "Shri Beereshwar Co-Op Credit Society Ltd" },
  { bank: "Shri Bhailalbhai Contractor Smarak Co-Op Bank Ltd" },
  { bank: "Shri Bharat Urban Co-Op Bank" },
  { bank: "Shri Chatrapati Shivaji Maharaj Sahakari Bank Niyamith" },
  { bank: "Shri Chhatrapati Rajarshi Shahu Urban Co-Op Bank Ltd" },
  { bank: "Shri Gajanan Lokseva Sahakari Bank Ltd" },
  { bank: "Shri Gajanan Maharaj Urban Co-Op Bank Ltd" },
  { bank: "Shri Gajanan Urban Co-Op Bank Ltd" },
  { bank: "Shri Kanyaka Nagari Sahakari Bank Ltd" },
  { bank: "Shri Laxmikrupa Urban Co-Op Bank Ltd" },
  { bank: "Shri Mahalaxmi Co-Op Bank Ltd Kolhapur" },
  { bank: "Shri Mahavir Nagari Sahakari Pethpedhi" },
  { bank: "Shri Mangalnath Multistate Co-Op Credit Society Ltd" },
  { bank: "Shri Panchganga Nagari Sahakari Bank Ltd" },
  { bank: "Shri Rajkot District Co-Operative Bank" },
  { bank: "Shri Renukamata Multistate Co-Op Urban Credit Society" },
  { bank: "Shri Rukmini Sahakari Bank Ltd" },
  { bank: "Shri Sairam Urban Multi State Co-Op Credit Society Ltd" },
  { bank: "Shri Sant Nagebaba Multistate Co-Op Urban Credit Society Ltd" },
  { bank: "Shri Shivayogi Murughendra Swami Urban Co-Op Bank Ltd" },
  { bank: "Shri Shiveshwar Nagari Sahakari Bank Ltd" },
  { bank: "Shri Veershaiv Co-Op. Bank Ltd" },
  { bank: "Shri Venkatesh Multistate Co-Op Credit Society Ltd" },
  { bank: "Shri Vinayak Sahakari Bank Ltd" },
  { bank: "Shripatraodada Sahkari Bank Ltd" },
  { bank: "Shriram Urban Co-Op Bank Ltd" },
  { bank: "Shubhlakshmi Mahila Co-Op Bank Ltd" },
  { bank: "Shushruti Souharda Sahakara Bank Niyamita" },
  { bank: "Siddaganga Urban Co-op Bank Ltd" },
  { bank: "Siddheshwar Sahakari Bank Ltd" },
  { bank: "Siddheshwar Urban Co Op Bank Maryadit" },
  { bank: "Sihor Mercantile Co-Op Bank Ltd" },
  { bank: "Sindhudurg District Central Co-Op Bank Ltd" },
  { bank: "Sir M Visvesvaraya Co-Op Bank Ltd" },
  { bank: "Smriti Nagrik Sahakari Bank" },
  { bank: "Solapur District Central Co-operative Bank Ltd" },
  { bank: "Solapur Janata Sahakari Bank" },
  { bank: "Solapur Siddheshwar Sahakari Bank Ltd" },
  { bank: "Solapur Social Urban Co-Op Bank Ltd" },
  { bank: "Sonali Bank Ltd" },
  { bank: "Soundarya Souharda Credit Co-Op Ltd" },
  { bank: "South Canara District Central Co-Op Bank Ltd" },
  { bank: "South Indian Bank" },
  { bank: "Sree Charan Souharda Co-Op Bank Ltd" },
  { bank: "Sree Mahayogi Lakshmamma Co-Op. Bank Ltd" },
  { bank: "Sree Narayana Guru Co-Op Bank Ltd" },
  { bank: "Sree Subramanyeswara Co-Op Bank" },
  { bank: "Sree Thyagaraja Co-Op Bank Ltd" },
  { bank: "Sreenidhi Souharda Sahakari Bank Niyamitha" },
  { bank: "Sri Basaveshwar Sahakari Bank" },
  { bank: "Sri Gokarnanath Co-Op Bank Ltd" },
  { bank: "Sri Kannikaparameshwari Co-Op Bank Ltd" },
  { bank: "Sri Satya Sai Nagrik Sahakari Bank" },
  { bank: "Sri Sudha Co-Op Bank Ltd" },
  { bank: "Sri Vasavamba Co-Op Bank" },
  { bank: "St Milagres Credit Souhardha Co-Op Ltd" },
  { bank: "Standard Chartered Bank" },
  { bank: "Standard Urban Co-Op Bank Ltd" },
  { bank: "State Bank of India" },
  { bank: "Sterling Urban Co-Op Bank" },
  { bank: "Sulaimani Co-Op Bank Ltd" },
  { bank: "Sumerpur Mercantile Urban Co-Op Bank Ltd" },
  { bank: "Sundarlal Sawji Urban Co-Op. Bank Ltd" },
  { bank: "Surat District Co-Op. Bank Ltd" },
  { bank: "Surat National Co-Op. Bank Ltd" },
  { bank: "Surat Peoples Co-op Bank Ltd" },
  { bank: "Suryoday Small Finance Bank Ltd" },
  { bank: "Sutex Co-op Bank Ltd." },
  { bank: "Suvarnayug Sahakari Bank" },
  { bank: "Swadhaar Urban Multipurpose Nidhi Ltd" },
  { bank: "Swatantra Senani Late Shripal Alase Kurundwad Co-Op Bank Ltd" },
  { bank: "Syndicate Bank" },
  { bank: "TJSB Sahakari Bank" },
  { bank: "Tamil Nadu Grama Bank" },
  { bank: "Tamilnad Mercantile Bank Ltd." },
  { bank: "Tamlukghatal Central Co-Op Bank Ltd" },
  { bank: "Telangana Grameena Bank" },
  { bank: "Telangana State Co-Op Apex Bank Ltd" },
  { bank: "Texco Co-Op. Bank Ltd" },
  { bank: "Textile Co-Op Bank Ltd" },
  { bank: "Textile Traders Co-Op. Bank Ltd" },
  { bank: "Thane Bharat Sahakari Bank Ltd" },
  { bank: "The A.P. Raja Rajeswari Mahila Co-Op Urban Bank Ltd" },
  { bank: "The Abhinav Sahakari Bank Ltd" },
  { bank: "The Ace Co-operative Bank Ltd" },
  { bank: "The Adinath Co-Operative Bank Ltd." },
  { bank: "The Ahmednagar District Central Co-Op Bank Ltd" },
  { bank: "The Ajara Urban Co-Op Bank Ltd" },
  { bank: "The Akola Urban Co-Op Bank Ltd." },
  { bank: "The Amravati Merchant's Co-Op Bank Ltd" },
  { bank: "The Amravati Zilla Parishad Shikshak Sahakari Bank Ltd" },
  { bank: "The Andhra Pradesh State Co-Op Bank" },
  { bank: "The Annasaheb Savant Co-Op Urban Bank Mahad Ltd" },
  { bank: "The Assam Co-Op Apex Bank Ltd" },
  { bank: "The Babasaheb Deshmukh Sahakari Bank Ltd" },
  { bank: "The Bagat Urban Co-Operative Bank Ltd" },
  { bank: "The Bailhongal Urban Co-Op Bank Ltd" },
  { bank: "The Banaskantha District Central Co-Op Bank" },
  { bank: "The Banaskantha Mercantile Co. Operative Bank Ltd" },
  { bank: "The Bangalore City Co-Op Bank Ltd" },
  { bank: "The Bantra Co-Op Bank Ltd" },
  { bank: "The Bapunagar Mahila Co-Op Bank" },
  { bank: "The Bardoli Nagarik Sahakari Bank Ltd" },
  { bank: "The Baroda Central Co-Op Bank Ltd" },
  { bank: "The Baroda City Co-Op Bank Ltd" },
  { bank: "The Bavla Nagrik Sahkari Bank Ltd" },
  { bank: "The Bengaluru DCC Bank Ltd" },
  { bank: "The Bhabhar Vibhag Nagrik Sahakari Bank" },
  { bank: "The Bhagyalashmi Mahila Sahakari Bank Ltd" },
  { bank: "The Bhandara Urban Co-Op Bank Ltd" },
  { bank: "The Bharat Co-Operative Bank" },
  { bank: "The Bhavana Rishi Co-Op Urban Bank Ltd" },
  { bank: "The Bhavnagar District Co-Op Bank Ltd" },
  { bank: "The Bhiwani Central Co-Op Bank Ltd" },
  { bank: "The Bhuj Mercantile Coop.Bank Ltd." },
  { bank: "The Buldana District Central Co-Op Bank Ltd" },
  { bank: "The Burdwan Central Co-Op Bank Ltd" },
  { bank: "The Catholic Co-Op Urban Bank Ltd" },
  { bank: "The Chanasma Nagrik Sahakari Bank Ltd" },
  { bank: "The Chandigarh State Co-Op Bank Ltd" },
  { bank: "The Chandwad Merchant's Co-Op Bank Ltd" },
  { bank: "The Chembur Nagrik Sahkari Bank" },
  { bank: "The Chitnavispura Sahakari Bank Ltd" },
  { bank: "The Chittoor District Co-Op Central Bank Ltd" },
  { bank: "The Co-Op Bank of Mehsana Ltd" },
  { bank: "The Co-Operative Bank of Rajkot" },
  { bank: "The Coimbatore District Central Co-Op Bank Ltd" },
  { bank: "The Commercial Co-Op Bank Ltd" },
  { bank: "The Dahod Mercantile Co-Op Bank Ltd" },
  { bank: "The Dahod Urban-Co-Op.Bank Ltd" },
  { bank: "The Deccan Co-Op Urban Bank Ltd" },
  { bank: "The Delhi State Co-Op Bank Ltd" },
  { bank: "The Dharmavaram Co-Op Town Bank Ltd" },
  { bank: "The Dhrangadhra Peoples' Co-Op Bank Ltd" },
  { bank: "The Eenadu Co-Op Urban Bank Ltd" },
  { bank: "The Faridabad Central Cooperative Bank Ltd" },
  { bank: "The Financial Co-Op Bank Ltd." },
  { bank: "The Gadchiroli District Central Co-Op Bank Ltd" },
  { bank: "The Gandevi People's Co-Op Bank Ltd" },
  { bank: "The Gandhi Gunj Co-Op Bank Ltd" },
  { bank: "The Gandhidham Co-Op Bank" },
  { bank: "The Gandhidham Mercantile Co-operative Bank Ltd." },
  { bank: "The Gandhinagar Urban Co-Op Bank" },
  { bank: "The Ganga Mercantile Urban Co-Op Bank Ltd" },
  { bank: "The Gauhati Co-Op Urban Bank Ltd" },
  { bank: "The Gayatri Co-Operative Urban Bank Ltd." },
  { bank: "The Goa Urban Co-Op Bank Ltd" },
  { bank: "The Gokak Urban Co-op Credit Bank Ltd" },
  { bank: "The Grain Merchants Co-Op Bank Ltd" },
  { bank: "The Guntur Co-Op Urban Bank Ltd" },
  { bank: "The Harij Nagrik Sahakari Bank Ltd" },
  { bank: "The Hotel Industrialists Co-Op Bank Ltd" },
  { bank: "The Hubli Urban Co-Op Bank Ltd" },
  { bank: "The Hyderabad District Co-Op Central Bank Ltd" },
  { bank: "The Ichalkaranji Merchants Co-Op Bank Ltd" },
  { bank: "The Income-Tax Department Co-Op Bank Ltd" },
  { bank: "The Jain Sahakari Bank Ltd" },
  { bank: "The Jaipur Central Co-Op Bank Ltd" },
  { bank: "The Jalna Peoples Co-Op Bank Ltd" },
  { bank: "The Jamnagar People's Co-Op Bank" },
  { bank: "The Jampeta Co-Op Urban Bank Ltd" },
  { bank: "The Janata Co-Op Bank Ltd" },
  { bank: "The Janatha Co-Op Bank" },
  { bank: "The Jhalawar Nagrik Sahakari Bank Ltd" },
  { bank: "The Jharkhand State Co-Op Bank Ltd" },
  { bank: "The Jijau Commercial Co-Op Bank Ltd" },
  { bank: "The Junagadh Jilla Sahakari Bank Ltd" },
  { bank: "The Kaira District Central Co-Op Bank Ltd" },
  { bank: "The Kakatiya Co-Op Urban Bank Ltd" },
  { bank: "The Kalupur Commercial Co.op. Bank Ltd." },
  { bank: "The Kalwan Merchant Co-Op Bank" },
  { bank: "The Kanakamahalakshmi Co-Operative Bank Ltd" },
  { bank: "The Karad Urban Co-op Bank" },
  { bank: "The Karan Urban Co-Op Bank Ltd" },
  { bank: "The Karnataka State Co-Operative Apex Bank Ltd" },
  { bank: "The Karnavati Co-Op Bank Ltd" },
  { bank: "The Kerala State Financial Enterprises Ltd" },
  { bank: "The Khattri Co-Op Urban Bank Ltd" },
  { bank: "The Kodinar Nagarik Sahakari Bank Ltd" },
  { bank: "The Kodinar Taluka Co-Op Banking Union Ltd" },
  { bank: "The Kranthi Co-Op Urban Bank" },
  { bank: "The Kunbi Sahakari Bank Ltd" },
  { bank: "The Kurla Nagrik Sahakari Bank Ltd" },
  { bank: "The Lunawada Peoples Co-Op Bank Ltd" },
  { bank: "The Maharaja Co-Op Urban Bank Ltd" },
  { bank: "The Maharashtra State Co-Op Bank Ltd" },
  { bank: "The Malad Sahakari Bank Ltd" },
  { bank: "The Malkapur Urban Co-Op Bank Ltd" },
  { bank: "The Mansa Nagrik Sahakari Bank Ltd" },
  { bank: "The Mehkar Urban Co-Op Bank Ltd" },
  { bank: "The Mehsana Nagrik Sahakari Bank" },
  { bank: "The Mehsana Urban Co-op Bank Ltd." },
  { bank: "The Merchants Souharda Sahakara Bank" },
  { bank: "The Modasa Nagarik Sahakari Bank Ltd" },
  { bank: "The Moga Central Co-Op Bank Ltd" },
  { bank: "The Mogaveera Co-Op Bank" },
  { bank: "The Moti Urban Co-Op Bank Ltd" },
  { bank: "The Municipal Co-Op Bank Ltd" },
  { bank: "The Muslim Co-Op Bank Ltd" },
  { bank: "The Nabadwip Co-Operative Credit Bank Ltd" },
  { bank: "The Nandura Urban Co Op Bank Ltd" },
  { bank: "The Nandurbar Merchant's Co-Op Bank Ltd" },
  { bank: "The Nashik Road Deolali Vyapari Sahakari Bank Ltd" },
  { bank: "The Nasik Merchants Co-Operative Bank Ltd" },
  { bank: "The National Co-Op Bank Ltd" },
  { bank: "The Naval Dockyard Co-Op Bank Ltd" },
  { bank: "The New Urban Co-Op-Bank Ltd" },
  { bank: "The Nizamabad District Co-Op Central Bank" },
  { bank: "The Ojhar Merchant Co-Op Bank" },
  { bank: "The Panchmahal District Co-Op Bank Ltd" },
  { bank: "The Panchsheel Mercantile Co-Op Bank Ltd" },
  { bank: "The Panipat Central Co-Op Bank Ltd" },
  { bank: "The Parbhani District Central Co-Op Bank Ltd" },
  { bank: "The Patiala Central Co-Op Bank Ltd" },
  { bank: "The Pratap Co-Op Bank Ltd" },
  { bank: "The Quepem Urban Multipurpose Co-Op Society Ltd" },
  { bank: "The Raddi Sahakara Bank Niyamitha" },
  { bank: "The Railway Employees Co-Op Bank Ltd" },
  { bank: "The Rajasthan Urban Co-Op Bank Ltd" },
  { bank: "The Ranuj Nagrik Sahkari Bank Ltd" },
  { bank: "The SSK Co-Op Bank Ltd" },
  { bank: "The Sabarkantha District Central Co-Op Bank Ltd." },
  { bank: "The Salem District Central Co-Op Bank" },
  { bank: "The Sangamner Merchants Co-operative Bank Ltd" },
  { bank: "The Santrampur Urban Co-Op Bank Ltd" },
  { bank: "The Satana Merchants Co-Op Bank Ltd" },
  { bank: "The Satara Sahakari Bank Ltd." },
  { bank: "The Saurashtra Co-Op Bank Ltd" },
  { bank: "The Secunderabad Mercantile Co-Op Urban Bank" },
  { bank: "The Seva Vikas Co-Operative Bank Ltd." },
  { bank: "The Sevalia Urban Co-Op Bank Ltd" },
  { bank: "The Sirsi Urban Sahakari Bank Ltd" },
  { bank: "The Social Co-Op Bank Ltd" },
  { bank: "The Sonepat Urban Co-Op Bank Ltd" },
  { bank: "The Srivilliputhur Co-Op Urban Bank Ltd" },
  { bank: "The Surat Mercantile Co-Op. Bank Ltd" },
  { bank: "The Swarnabharathi Sahakara Bank Niyamitha" },
  { bank: "The Tamilnadu State Apex Co-Operative Bank" },
  { bank: "The Tasgaon Urban Co-Op Bank" },
  { bank: "The Thane District Central Co-Op Bank" },
  { bank: "The Tiruchirapalli District Central Co-Op Bank Ltd" },
  { bank: "The Town Co-Op Bank Ltd" },
  { bank: "The Tumkur District Co-Op Central Bank Ltd" },
  { bank: "The Udaipur Mahila Samridhi Urban Co-Operative Bank Ltd" },
  { bank: "The Udaipur Mahila Urban Co-Op Bank Ltd" },
  { bank: "The Udaipur Urban Co-Op Bank Ltd" },
  { bank: "The Umreth Urban Co-Op Bank Ltd" },
  { bank: "The Union Co-Op Bank Ltd" },
  { bank: "The Urban Co-Operative Bank Ltd" },
  { bank: "The Vaish Co-Op New Bank Ltd" },
  { bank: "The Vallabh Vidyanagar Commercial Co-Op Bank Ltd" },
  { bank: "The Vani Merchants Co-Op Bank Ltd" },
  { bank: "The Veraval Peoples Co-Op Bank" },
  { bank: "The Visakhapatnam Co-Op Bank Ltd" },
  { bank: "The Vyankateshwara Sahakari Bank Ltd" },
  { bank: "The Washim Urban Co-Op Bank Ltd" },
  { bank: "The West Bengal State Co-Op Bank Ltd" },
  { bank: "The Yadagiri Lakshmi Narasimha Swamy Co-Op Urban Bank Ltd" },
  { bank: "The Yashwant Co-Op Ltd" },
  { bank: "The Yavatmal District Central Co-Op Bank" },
  { bank: "The Yavatmal Urban Co-Op Bank Ltd" },
  { bank: "Thrissur District Citizens Co-Op Society Ltd" },
  { bank: "Tirumalla Tirupati Multistate Co-Op Credit Society Ltd" },
  { bank: "Tirupati Urban Co-Op Bank" },
  { bank: "Tripura Gramin Bank" },
  { bank: "Tripura State Co-Op Bank" },
  { bank: "Trivandrum District Co-Op Bank" },
  { bank: "Tuljabhavani Urban Multi-state Co-Op Credit Society Ltd" },
  { bank: "Tumkur Grain Merchants Credit Co-Op. Bank Ltd" },
  { bank: "Tumkur Merchants Credit Co-Op. Bank Ltd" },
  { bank: "Tumkur Veerashaiva Co-Op Bank Ltd" },
  { bank: "UCO Bank" },
  { bank: "Udaygiri Multi State Co-Op Credit Society Ltd" },
  { bank: "Udham Singh Nagar District Co-Op Bank Ltd" },
  { bank: "Udyam Vikas Sahakari Bank" },
  { bank: "Ujjivan Bank" },
  { bank: "Uma Co-Op. Bank Ltd" },
  { bank: "Umiya Urban Co-Op Bank" },
  { bank: "Union Bank of India" },
  { bank: "United Bank of India" },
  { bank: "United Co-Op. Bank Ltd" },
  { bank: "United Mercantile Co-Op Bank" },
  { bank: "Unjha Nagarik Sahakari Bank Ltd" },
  { bank: "Utkal Grameen Bank" },
  { bank: "Utkarsh Small Finance Bank" },
  { bank: "Uttar Bihar Gramin Bank" },
  { bank: "Uttarakhand Gramin Bank" },
  { bank: "Uttarakhand State Co-Op Bank" },
  { bank: "Uttarbanga Kshetriya Gramin Bank" },
  { bank: "Uttarkashi Zila Sahkari Bank Ltd" },
  { bank: "VSV Co-Op Bank Ltd" },
  { bank: "Vadnagar Nagrik Sahkari Bank Ltd" },
  { bank: "Vaidyanath Urban Co-op Bank Ltd" },
  { bank: "Vaijapur Merchants Co-Op. Bank Ltd" },
  { bank: "Vaishya Nagari Sahakari Bank" },
  { bank: "Vaishya Sahakari Bank Ltd" },
  { bank: "Valmiki Urban Co-Op Bank Ltd" },
  { bank: "Valsad District Central Co-Op Bank Ltd" },
  { bank: "Varachha Co-op Bank Ltd." },
  { bank: "Vardhaman Mahila Co-Op Urban Bank Ltd" },
  { bank: "Vardhaman Nagari Sahakari Patsanstha Maryadit" },
  { bank: "Vasai Janata Sahakari Bank Ltd" },
  { bank: "Vasai Vikas Sahakari Bank Ltd" },
  { bank: "Vasundhara Mahila Nagari Sahakari Bank" },
  { bank: "Veerashaiva Sahakari Bank Ltd" },
  { bank: "Veraval Mercantile Co-Op Bank Ltd" },
  { bank: "Vidarbha Merchants Urban Co-Op. Bank Ltd" },
  { bank: "Vidharbha Konkan Gramin Bank" },
  { bank: "Vidya Sahkari Bank Ltd" },
  { bank: "Vidyanand Co-Op Bank" },
  { bank: "Vijay Co-Op. Bank Ltd" },
  { bank: "Vijay Commercial Co-Op Bank Ltd" },
  { bank: "Vijaya Bank" },
  { bank: "Vijaypur Sahakari Bank" },
  { bank: "Vikas Sahakari Bank Ltd" },
  { bank: "Vikas Souharda Co-Op Bank Ltd" },
  { bank: "Vikramaditya Nagrik Sahakari Bank" },
  { bank: "Vilas Bank" },
  { bank: "Vipra Vividhoddesha Souhardha Sahakari Niyamitha" },
  { bank: "Viramgam Mercantile Co-Op Bank Ltd" },
  { bank: "Vishweshwar Sahakari Bank Ltd" },
  { bank: "Vita Merchants Co-Op Bank Ltd" },
  { bank: "Vyapari Sahakari Bank Maryadit" },
  { bank: "Vyaparik Audyogik Sahkari Bank" },
  { bank: "Vyavsayik Sahakari Bank Ltd" },
  { bank: "Vysya Co-op Bank Ltd" },
  { bank: "Wai Urban Co-Operative Bank" },
  { bank: "Wana Nagrik Sahkari Bank Ltd" },
  { bank: "Warangal Urban Co-Op Bank Ltd" },
  { bank: "Wardha Nagari Sahakari Adhikosh Bank Ltd" },
  { bank: "Wardhaman Urban Co-Op bank Ltd" },
  { bank: "Yash Urban Co-Op Credit Society Ltd" },
  { bank: "Yes Bank" },
  { bank: "Yeshwant Urban Co-Op Bank Ltd." },
  { bank: "Zila Sahkari Bank Ltd" },
  { bank: "Zoroastrian Bank" },
];


export const IncredoBankList = [
  {
    code: "ABBL",
    name: "AB Bank Ltd.",
    ifsc: "ABBL",
  },
  {
    code: "ABHY",
    name: "Abhyudaya Co-operative Bank Ltd",
    ifsc: "ABHY",
  },
  {
    code: "ADCB",
    name: "Abu Dhabi Commercial Bank Ltd.",
    ifsc: "ADCB",
  },
  {
    code: "ACC",
    name: "Access Bank",
    ifsc: "",
  },
  {
    code: "ACBL",
    name: "Adarsh Co-operative Bank Ltd.",
    ifsc: "ACBX",
  },
  {
    code: "APB",
    name: "Airtel Payments Bank",
    ifsc: "AIRP",
  },
  {
    code: "ALLA",
    name: "Allahabad Bank",
    ifsc: "ALLA",
  },
  {
    code: "AUGB",
    name: "Allahabad UP Gramin Bank",
    ifsc: "AUGX",
  },
  {
    code: "AJHC",
    name: "Ambarnath Jai Hind Co Op Bank Ltd.",
    ifsc: "AJHC",
  },
  {
    code: "AEBG",
    name: "American Express Banking Corp.",
    ifsc: "",
  },
  {
    code: "ANDH",
    name: "Andhra Bank",
    ifsc: "ABEX",
  },
  {
    code: "APGVB",
    name: "Andhra Pradesh Grameena Vikas Bank",
    ifsc: "APGV",
  },
  {
    code: "APGB",
    name: "Andhra Pragathi Grameena Bank",
    ifsc: "APGB",
  },
  {
    code: "ASBL",
    name: "Apna Sahakari Bank Ltd.",
    ifsc: "ASBL",
  },
  {
    code: "APRB",
    name: "Arunachal Pradesh Rural Bank",
    ifsc: "APRX",
  },
  {
    code: "ASGVB",
    name: "Assam Gramin Vikash Bank",
    ifsc: "AGVX",
  },
  {
    code: "ASFB",
    name: "Au Small Finance Bank Ltd.",
    ifsc: "AUBL",
  },
  {
    code: "ANBG",
    name: "Australia and New Zealand Banking Group Ltd.",
    ifsc: "ANZB",
  },
  {
    code: "AXIS",
    name: "Axis Bank Ltd.",
    ifsc: "UTIB",
  },
  {
    code: "BAAC",
    name: "Bank for Agriculture and Agricultural Cooperatives",
    ifsc: "",
  },
  {
    code: "BCP",
    name: "Banco Comercial Português",
    ifsc: "",
  },
  {
    code: "BBL",
    name: "Bandhan Bank Ltd.",
    ifsc: "BDBL",
  },
  {
    code: "BGVB",
    name: "Bangiya Gramin Vikas Bank",
    ifsc: "",
  },
  {
    code: "BANB",
    name: "Bangkok Bank",
    ifsc: "",
  },
  {
    code: "BOA",
    name: "Bank of America",
    ifsc: "BOFA",
  },
  {
    code: "BAY",
    name: "Bank of Ayudhya",
    ifsc: "",
  },
  {
    code: "BBK",
    name: "Bank of Bahrain & Kuwait BSC",
    ifsc: "",
  },
  {
    code: "BOB",
    name: "Bank of Baroda",
    ifsc: "BARB",
  },
  {
    code: "BOC",
    name: "Bank of Ceylon",
    ifsc: "BCEY",
  },
  {
    code: "BOI",
    name: "Bank of India",
    ifsc: "BKID",
  },
  {
    code: "BOM",
    name: "Bank of Maharashtra",
    ifsc: "MAHB",
  },
  {
    code: "BONS",
    name: "Bank of Nova Scotia",
    ifsc: "NOSC",
  },
  {
    code: "BARA",
    name: "The Baramati Sahakari Bank Ltd.",
    ifsc: "BARA",
  },
  {
    code: "BBP",
    name: "Barclays Bank Plc.",
    ifsc: "BARC",
  },
  {
    code: "BGGB",
    name: "Baroda Gujarat Gramin Bank",
    ifsc: "BGGX",
  },
  {
    code: "BRKGB",
    name: "Baroda Rajasthan Kshetriya Gramin Bank",
    ifsc: "BRGX",
  },
  {
    code: "BUGB",
    name: "Baroda UP Gramin Bank",
    ifsc: "",
  },
  {
    code: "BCCB",
    name: "Bassein Catholic Co-operative Bank Ltd.",
    ifsc: "BACB",
  },
  {
    code: "BNSB",
    name: "Bhagini Nivedita Sahakari Bank Ltd.",
    ifsc: "BNSX",
  },
  {
    code: "BLMS",
    name: "The Bhagyalakshmi Mahila Sahakari Bank Ltd.",
    ifsc: "BMSX",
  },
  {
    code: "BCBM",
    name: "Bharat Co-operative Bank (Mumbai) Ltd.",
    ifsc: "BCBM",
  },
  {
    code: "BSBL",
    name: "Bhausaheb Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "BDCB",
    name: "The Bhavnagar District Co-operative Bank Ltd",
    ifsc: "BVNX",
  },
  {
    code: "BGB",
    name: "Bihar Gramin Bank",
    ifsc: "BGBX",
  },
  {
    code: "BNP",
    name: "BNP Paribas",
    ifsc: "BNPA",
  },
  {
    code: "CAN",
    name: "Canara Bank",
    ifsc: "CNRB",
  },
  {
    code: "CSFB",
    name: "Capital Small Finance Bank Ltd",
    ifsc: "CLBL",
  },
  {
    code: "CSB",
    name: "Catholic Syrian Bank Ltd.",
    ifsc: "CSBK",
  },
  {
    code: "CBI",
    name: "Central Bank of India",
    ifsc: "CBIN",
  },
  {
    code: "CMPGB",
    name: "Central Madhya Pradesh Gramin Bank",
    ifsc: "CMPX",
  },
  {
    code: "CGGB",
    name: "Chaitanya Godavari Grameena Bank",
    ifsc: "CGGX",
  },
  {
    code: "CRGB",
    name: "Chattisgarh Rajya Gramin Bank",
    ifsc: "",
  },
  {
    code: "CITI",
    name: "Citibank N.A.",
    ifsc: "",
  },
  {
    code: "CUBL",
    name: "City Union Bank Ltd.",
    ifsc: "CIUB",
  },
  {
    code: "CLAB",
    name: "Coastal Local Area Bank Ltd",
    ifsc: "COAS",
  },
  {
    code: "CRUA",
    name: "Cooperatieve Rabobank U.A.",
    ifsc: "",
  },
  {
    code: "CORP",
    name: "Corporation Bank",
    ifsc: "CORP",
  },
  {
    code: "COSB",
    name: "Cosmos Co-operative Bank Ltd",
    ifsc: "COSB",
  },
  {
    code: "CACIB",
    name: "Credit Agricole Corporate & Investment Bank",
    ifsc: "",
  },
  {
    code: "CSAG",
    name: "Credit Suisse A.G",
    ifsc: "",
  },
  {
    code: "CTBC",
    name: "CTBC Bank Co.",
    ifsc: "CTCB",
  },
  {
    code: "DBS",
    name: "DBS Bank Ltd.",
    ifsc: "",
  },
  {
    code: "DCB",
    name: "DCB Bank Ltd.",
    ifsc: "DCBL",
  },
  {
    code: "DNSB",
    name: "Deendayal Nagari Sahakari Bank Ltd.",
    ifsc: "DNSX",
  },
  {
    code: "DENA",
    name: "Dena Bank",
    ifsc: "BKDN",
  },
  {
    code: "DGGB",
    name: "Dena Gujarat Gramin Bank",
    ifsc: "DEGX",
  },
  {
    code: "DEOB",
    name: "Deogiri Nagari Sahakari Bank",
    ifsc: "DEOB",
  },
  {
    code: "DEUT",
    name: "Deutsche Bank",
    ifsc: "DEUT",
  },
  {
    code: "DHAN",
    name: "Dhanlaxmi Bank Ltd.",
    ifsc: "DLXB",
  },
  {
    code: "DMKJ",
    name: "DMK Jaoli Bank Ltd.",
    ifsc: "DMKJ",
  },
  {
    code: "DOHA",
    name: "Doha Bank",
    ifsc: "DOHB",
  },
  {
    code: "DNS",
    name: "Dombivli Nagari Sahakari Bank Ltd.",
    ifsc: "DNSB",
  },
  {
    code: "EDB",
    name: "Ellaquai Dehati Bank",
    ifsc: "EDBX",
  },
  {
    code: "ENBD",
    name: "Emirates NBD Bank PJSC",
    ifsc: "EBIL",
  },
  {
    code: "ESFB",
    name: "Equitas Small Finance Bank Ltd",
    ifsc: "ESFB",
  },
  {
    code: "ESAF",
    name: "ESAF Small Finance Bank Ltd.",
    ifsc: "ESAF",
  },
  {
    code: "FBL",
    name: "Federal Bank Ltd.",
    ifsc: "FDRL",
  },
  {
    code: "FSFB",
    name: "Fincare Small Finance Bank Ltd.",
    ifsc: "FINF",
  },
  {
    code: "FPB",
    name: "Fino Payments Bank",
    ifsc: "FINO",
  },
  {
    code: "FADB",
    name: "First Abu Dhabi Bank PJSC",
    ifsc: "",
  },
  {
    code: "FRBL",
    name: "FirstRand Bank Ltd.",
    ifsc: "FIRN",
  },
  {
    code: "GCUB",
    name: "Gayatri Co-operative Urban Bank Ltd.",
    ifsc: "GCUX",
  },
  {
    code: "GSB",
    name: "Government Savings Bank",
    ifsc: "",
  },
  {
    code: "GPPB",
    name: "GP Parsik Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "GBA",
    name: "Gramin Bank of Aryavart",
    ifsc: "ARYX",
  },
  {
    code: "GBCB",
    name: "Greater Bombay Co-operative Bank Ltd",
    ifsc: "GBCB",
  },
  {
    code: "GMUC",
    name: "Gulshan Mercantile Urban Coop. Bank Ltd",
    ifsc: "",
  },
  {
    code: "HCBL",
    name: "The Hasti Co - Op Bank Ltd.",
    ifsc: "",
  },
  {
    code: "HDFC",
    name: "HDFC Bank Ltd",
    ifsc: "HDFC",
  },
  {
    code: "HPGB",
    name: "Himachal Pradesh Gramin Bank",
    ifsc: "HMBX",
  },
  {
    code: "HSBC",
    name: "HSBC Ltd",
    ifsc: "",
  },
  {
    code: "ICICI",
    name: "ICICI Bank Ltd.",
    ifsc: "ICIC",
  },
  {
    code: "IDFC",
    name: "IDFC Bank Ltd.",
    ifsc: "",
  },
  {
    code: "INKR",
    name: "Inkredo",
    ifsc: "",
  },
  {
    code: "IPPB",
    name: "India Post Payments Bank",
    ifsc: "IPOS",
  },
  {
    code: "INDB",
    name: "Indian Bank",
    ifsc: "IDIB",
  },
  {
    code: "IOB",
    name: "Indian Overseas Bank",
    ifsc: "IOBA",
  },
  {
    code: "IIBL",
    name: "IndusInd Bank Ltd",
    ifsc: "INDB",
  },
  {
    code: "ICBC",
    name: "Industrial & Commercial Bank of China Ltd.",
    ifsc: "",
  },
  {
    code: "IBK",
    name: "Industrial Bank of Korea",
    ifsc: "IBKO",
  },
  {
    code: "JPMC",
    name: "J.P. Morgan Chase Bank N.A.",
    ifsc: "",
  },
  {
    code: "JKBL",
    name: "Jammu & Kashmir Bank Ltd.",
    ifsc: "JAKA",
  },
  {
    code: "JKGB",
    name: "Jammu & Kashmir Grameen Bank",
    ifsc: "",
  },
  {
    code: "JSFB",
    name: "Jana Small Finance Bank Ltd",
    ifsc: "JSFB",
  },
  {
    code: "JSBL",
    name: "Janaseva Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "JSBP",
    name: "Janata Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "JGB",
    name: "Jharkhand Gramin Bank",
    ifsc: "",
  },
  {
    code: "JRGB",
    name: "Jharkhand Rajya Gramin Bank",
    ifsc: "VGBX",
  },
  {
    code: "JSC",
    name: "JSC VTB Bank",
    ifsc: "",
  },
  {
    code: "KAIJ",
    name: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank",
    ifsc: "KAIJ",
  },
  {
    code: "KJSB",
    name: "Kalyan Janata Sahakari Bank",
    ifsc: "KJSB",
  },
  {
    code: "KBL",
    name: "Karnataka Bank Ltd.",
    ifsc: "KARB",
  },
  {
    code: "KSCB",
    name: "The Karnataka State Co-Operative Apex Bank Ltd.",
    ifsc: "KSCB",
  },
  {
    code: "KVGB",
    name: "Karnataka Vikas Grameena Bank",
    ifsc: "KVGB",
  },
  {
    code: "KVBL",
    name: "Karur Vysya Bank Ltd.",
    ifsc: "KVBL",
  },
  {
    code: "KGSG",
    name: "Kashi Gomti Samyut Gramin Bank",
    ifsc: "KGSX",
  },
  {
    code: "KKB",
    name: "Kasikornbank",
    ifsc: "",
  },
  {
    code: "KAGB",
    name: "Kaveri Grameena Bank",
    ifsc: "KGBX",
  },
  {
    code: "KEBH",
    name: "KEB Hana Bank",
    ifsc: "KBHB",
  },
  {
    code: "KEGB",
    name: "Kerala Gramin Bank",
    ifsc: "KLGB",
  },
  {
    code: "KBSL",
    name: "Krishna Bhima Samruddhi LAB Ltd",
    ifsc: "KBSX",
  },
  {
    code: "KTBP",
    name: "Krung Thai Bank Public Co. Ltd.",
    ifsc: "",
  },
  {
    code: "LVBL",
    name: "Lakshmi Vilas Bank Ltd.",
    ifsc: "LAVB",
  },
  {
    code: "LDRB",
    name: "Langpi Dehangi Rural Bank",
    ifsc: "LDRX",
  },
  {
    code: "MTB",
    name: "M&T Bank",
    ifsc: "",
  },
  {
    code: "MBGB",
    name: "Madhya Bihar Gramin Bank",
    ifsc: "",
  },
  {
    code: "MGB",
    name: "Madhyanchal Gramin Bank",
    ifsc: "MADX",
  },
  {
    code: "MAGB",
    name: "Maharashtra Gramin Bank",
    ifsc: "MAHG",
  },
  {
    code: "MIEB",
    name: "Makarpura Ind Estate Co Op Bank Ltd",
    ifsc: "",
  },
  {
    code: "MLGB",
    name: "Malwa Gramin Bank",
    ifsc: "MGRB",
  },
  {
    code: "MARB",
    name: "Manipur Rural Bank",
    ifsc: "MRBX",
  },
  {
    code: "MBPSC",
    name: "Mashreq Bank PSC",
    ifsc: "MSHQ",
  },
  {
    code: "MERB",
    name: "Meghalaya Rural Bank",
    ifsc: "MERX",
  },
  {
    code: "MIRB",
    name: "Mizoram Rural Bank",
    ifsc: "MZRX",
  },
  {
    code: "MBL",
    name: "Mizuho Bank Ltd.",
    ifsc: "MHCB",
  },
  {
    code: "NRB",
    name: "Nagaland Rural Bank",
    ifsc: "NAGX",
  },
  {
    code: "NBL",
    name: "Nainital Bank Ltd.",
    ifsc: "NTBL",
  },
  {
    code: "NUCB",
    name: "The Nandura Urban Co-operative Bank Ltd.",
    ifsc: "TNUX",
  },
  {
    code: "NJGB",
    name: "Narmada Jhabua Gramin Bank",
    ifsc: "NJGX",
  },
  {
    code: "NAB",
    name: "National Australia Bank",
    ifsc: "NATA",
  },
  {
    code: "NICB",
    name: "New India Co-Operative Bank Ltd",
    ifsc: "NICB",
  },
  {
    code: "NIYO",
    name: "Niyo Solutions Inc.",
    ifsc: "",
  },
  {
    code: "NKGS",
    name: "NKGSB Co-operative Bank Ltd.",
    ifsc: "NKGS",
  },
  {
    code: "NESF",
    name: "North East Small Finance Bank Ltd",
    ifsc: "NESF",
  },
  {
    code: "NSDL",
    name: "NSDL Payments Bank Limited",
    ifsc: "NSPB",
  },
  {
    code: "OGB",
    name: "Odisha Gramya Bank",
    ifsc: "ODGB",
  },
  {
    code: "OBC",
    name: "Oriental Bank of Commerce",
    ifsc: "ORBC",
  },
  {
    code: "OCBC",
    name: "Oversea-Chinese Banking Corporation",
    ifsc: "Limited",
  },
  {
    code: "PUCB",
    name: "The Pandharpur Urban Co-operative Bank Ltd",
    ifsc: "PDUX",
  },
  {
    code: "PAYTM",
    name: "PayTM Payments Bank",
    ifsc: "PYTM",
  },
  {
    code: "PLGB",
    name: "Pallavan Grama Bank",
    ifsc: "PABX",
  },
  {
    code: "PNGB",
    name: "Pandyan Grama Bank",
    ifsc: "PDNX",
  },
  {
    code: "PBGB",
    name: "Paschim Banga Gramin Bank",
    ifsc: "PASX",
  },
  {
    code: "PKGB",
    name: "Pragathi Krishna Gramin Bank",
    ifsc: "PGBX",
  },
  {
    code: "PRAB",
    name: "Prathama Bank",
    ifsc: "PRTH",
  },
  {
    code: "PNSB",
    name: "Priyadarshani Nagari Sahakari Bank Ltd",
    ifsc: "PDSX",
  },
  {
    code: "PTBMI",
    name: "PT Bank Maybank Indonesia TBK",
    ifsc: "IBBK",
  },
  {
    code: "PUBG",
    name: "Puduvai Bharathiar Grama Bank",
    ifsc: "PBGX",
  },
  {
    code: "PCSB",
    name: "Pune Cantonment Sahakari Bank Ltd",
    ifsc: "PCTX",
  },
  {
    code: "PPCB",
    name: "Pune People's Co-operative Bank",
    ifsc: "PPBX",
  },
  {
    code: "PSB",
    name: "Punjab & Sind Bank",
    ifsc: "PSIB",
  },
  {
    code: "PGB",
    name: "Punjab Gramin Bank",
    ifsc: "PUGX",
  },
  {
    code: "PNB",
    name: "Punjab National Bank",
    ifsc: "PUNB",
  },
  {
    code: "PURB",
    name: "Purvanchal Bank",
    ifsc: "",
  },
  {
    code: "QNB",
    name: "Qatar National Bank SAQ",
    ifsc: "QNBA",
  },
  {
    code: "RMGB",
    name: "Rajasthan Marudhara Gramin Bank",
    ifsc: "MDGX",
  },
  {
    code: "RBL",
    name: "RBL Bank Ltd.",
    ifsc: "RATN",
  },
  {
    code: "SGB",
    name: "Saptagiri Grameena Bank",
    ifsc: "SPBX",
  },
  {
    code: "SCOB",
    name: "Saraswat Co-operative Bank",
    ifsc: "SRCB",
  },
  {
    code: "SHGB",
    name: "Sarva Haryana Gramin Bank",
    ifsc: "HGBX",
  },
  {
    code: "SUGB",
    name: "Sarva UP Gramin Bank",
    ifsc: "",
  },
  {
    code: "SAGB",
    name: "Saurashtra Gramin Bank",
    ifsc: "SAGX",
  },
  {
    code: "SBER",
    name: "Sberbank",
    ifsc: "",
  },
  {
    code: "SBM",
    name: "SBM Bank (Mauritius) Ltd",
    ifsc: "STCB",
  },
  {
    code: "SCAN",
    name: "Scanned Statement",
    ifsc: "",
  },
  {
    code: "SVBL",
    name: "The Seva Vikas Co-operative Bank Limited",
    ifsc: "SVBL",
  },
  {
    code: "SVCB",
    name: "Shamrao Vithal Co-operative Bank",
    ifsc: "",
  },
  {
    code: "SHIN",
    name: "Shinhan Bank",
    ifsc: "SHBK",
  },
  {
    code: "KNSB",
    name: "Shree Kadi Nagarik Sahakari Bank Ltd",
    ifsc: "KDIX",
  },
  {
    code: "SMCB",
    name: "Shree Mahesh Co- Op Bank Limited",
    ifsc: "SHRX",
  },
  {
    code: "CRUB",
    name: "Shri Chhatrapati Rajarshi Shahu Urban Co-Operative Bank",
    ifsc: "",
  },
  {
    code: "SIAM",
    name: "Siam Commercial Bank",
    ifsc: "",
  },
  {
    code: "SOGE",
    name: "Societe Generale",
    ifsc: "SOGE",
  },
  {
    code: "SONA",
    name: "Sonali Bank Ltd.",
    ifsc: "SBLD",
  },
  {
    code: "SIB",
    name: "South Indian Bank Ltd.",
    ifsc: "SIBL",
  },
  {
    code: "SCB",
    name: "Standard Chartered Bank",
    ifsc: "SCBL",
  },
  {
    code: "SBI",
    name: "State Bank of India",
    ifsc: "SBIN",
  },
  {
    code: "SLBL",
    name: "Subhadra Local Bank Ltd",
    ifsc: "",
  },
  {
    code: "SMBC",
    name: "Sumitomo Mitsui Banking Corporation",
    ifsc: "SMBC",
  },
  {
    code: "SPCB",
    name: "The Surat People's Co-Op. Bank Ltd.",
    ifsc: "",
  },
  {
    code: "SSFB",
    name: "Suryoday Small Finance Bank Ltd.",
    ifsc: "SURY",
  },
  {
    code: "SUTL",
    name: "Sutlej Gramin Bank",
    ifsc: "SJGX",
  },
  {
    code: "SYNB",
    name: "Syndicate Bank",
    ifsc: "SYNB",
  },
  {
    code: "TMBL",
    name: "Tamilnad Mercantile Bank Ltd.",
    ifsc: "TMBL",
  },
  {
    code: "TGCB",
    name: "Tamluk Ghatal Central Co-operative Bank Ltd.",
    ifsc: "",
  },
  {
    code: "TEGB",
    name: "Telengana Grameena Bank",
    ifsc: "",
  },
  {
    code: "ACCUK",
    name: "The Access Bank UK",
    ifsc: "",
  },
  {
    code: "BTM",
    name: "The Bank of Tokyo- Mitsubishi UFJ",
    ifsc: "BOTM",
  },
  {
    code: "RBS",
    name: "The Royal Bank of Scotland plc",
    ifsc: "",
  },
  {
    code: "MCBL",
    name: "The Municipal Co-Op. Bank Ltd.",
    ifsc: "",
  },
  {
    code: "TJSB",
    name: "TJSB Sahakari Bank Ltd",
    ifsc: "TJSB",
  },
  {
    code: "TTB",
    name: "TMBThanachart Bank Public Company Limited",
    ifsc: "",
  },
  {
    code: "TRGB",
    name: "Tripura Gramin Bank",
    ifsc: "TGBX",
  },
  {
    code: "UCOB",
    name: "UCO Bank",
    ifsc: "UCBA",
  },
  {
    code: "USFB",
    name: "Ujjivan Small Finance Bank Ltd.",
    ifsc: "UJVN",
  },
  {
    code: "UBI",
    name: "Union Bank of India",
    ifsc: "UBIN",
  },
  {
    code: "UNBI",
    name: "United Bank of India",
    ifsc: "UTBI",
  },
  {
    code: "UOBL",
    name: "United Overseas Bank Ltd.",
    ifsc: "UOVB",
  },
  {
    code: "UGB",
    name: "Utkal Grameen bank",
    ifsc: "UGBX",
  },
  {
    code: "UTSF",
    name: "Utkarsh Small Finance Bank Ltd.",
    ifsc: "UTKS",
  },
  {
    code: "UBGB",
    name: "Uttar Bihar Gramin Bank",
    ifsc: "UBGX",
  },
  {
    code: "UTGB",
    name: "Uttarakhand Gramin Bank",
    ifsc: "UTGX",
  },
  {
    code: "UKGB",
    name: "Uttarbanga Kshetriya Gramin Bank",
    ifsc: "UKGX",
  },
  {
    code: "VGB",
    name: "Vananchal Gramin Bank",
    ifsc: "",
  },
  {
    code: "VVSB",
    name: "Vasai Vikas Sahakari Bank Ltd.",
    ifsc: "VVSB",
  },
  {
    code: "VKGB",
    name: "Vidharbha Konkan Gramin Bank",
    ifsc: "",
  },
  {
    code: "VIJ",
    name: "Vijaya Bank",
    ifsc: "VIJB",
  },
  {
    code: "VSBL",
    name: "The Vishweshwar Sahakari Bank Ltd.",
    ifsc: "VSBL",
  },
  {
    code: "WBC",
    name: "Westpac Banking Corporation",
    ifsc: "WPAC",
  },
  {
    code: "WOOB",
    name: "Woori Bank",
    ifsc: "HVBK",
  },
  {
    code: "YESB",
    name: "YES Bank Ltd.",
    ifsc: "YESB",
  },
  {
    code: "IDBI",
    name: "IDBI Bank Limited",
    ifsc: "IBKL",
  },
  {
    code: "KOTAK",
    name: "Kotak Mahindra Bank Ltd",
    ifsc: "KKBK",
  },
];