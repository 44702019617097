import React, { useEffect, useState } from "react";
import { useContext } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PermissionModal from "../../components/PermissionModal";
import CustomerContextProvider, {
  CustomerContext,
} from "../../Contexts/CustomerContext";
import { GlobalContext } from "../../Contexts/GlobalContext";
import LoanContextProvider, {
  LoanContext,
} from "../../Contexts/LoanJourneyContext";
import useApplication from "../../hooks/useApplication";
import usePermission from "../../hooks/usePermission";
import ApplicationProgress from "../../pages/LoanJournery/v1/ApplicationStages/ApplicationProgress";
// import DocumentUpload from "../../pages/LoanJournery/v1/ApplicationStages/DocumentUpload";
import FieldInvestigation from "../../pages/LoanJournery/v1/ApplicationStages/FieldInvestigation";
import PartOne from "../../pages/LoanJournery/v1/ApplicationStages/PartOne";
import PartTwo from "../../pages/LoanJournery/v1/ApplicationStages/PartTwo";
import PostDisbursement from "../../pages/LoanJournery/v1/ApplicationStages/PostDisbursement";
import PreDisbursement from "../../pages/LoanJournery/v1/ApplicationStages/PreDisbursement";
import PreDisbursementCoApp from "../../pages/LoanJournery/v1/ApplicationStages/PreDisbursementCoApp";
import PreDisbursementCustomer from "../../pages/LoanJournery/v1/ApplicationStages/PreDisbursementCustomer";
import CoGuarantor from "../../pages/LoanJournery/v1/CoCustomer";
import CoCustomerDetails from "../../pages/LoanJournery/v1/CoCustomerDetails";
import LoanCreation from "../../pages/LoanJournery/v1/LoanCreation";
import LoanSummary from "../../pages/LoanJournery/v1/LoanSummary";
import PanDetails from "../../pages/LoanJournery/v1/PanDetails";
import PanUpload from "../../pages/LoanJournery/v1/PanUpload";
import PsychometricTest from "../../pages/LoanJournery/v1/PsychometricTest";
import CoCustomerStatus from "../../pages/LoanJournery/v1/StatusScreens/CoCustomerStatus";
import Congrats from "../../pages/LoanJournery/v1/StatusScreens/Congrats";
import CustomerStatus from "../../pages/LoanJournery/v1/StatusScreens/CustomerStatus";
import UnderReview from "../../pages/LoanJournery/v1/StatusScreens/UnderReview";
import LoanStatus from "../../pages/LoanJournery/v1/StatusScreens/LoanStatus";
import LoanCreationVani from "../../pages/LoanJournery/v1/LoanCreation/LoanCreationVani";
import PaymentMethod from "../../pages/LoanJournery/v1/PaymentMethod/PaymentMethod";
import PreDisbursementBank from "../../pages/LoanJournery/v1/ApplicationStages/PreDisbursementBank";
import ENach from "../../pages/LoanJournery/v1/PaymentMethod/Enach";
import FieldInvestigationV2 from "../../pages/LoanJournery/v2/FieldInvestigation";
import OtpScreen from "../../pages/OtpScreen";
import FieldInvestigationDone from "../../pages/LoanJournery/v2/FieldInvestigationDone";
import UpiAutoCollect from "../../pages/LoanJournery/v1/PaymentMethod/UpiAutoCollect";
import RewardWallet from "../../components/V2/Reward/RewardWallet";
import { incentiveData } from "../../api/agent";
import RewardAnimation from "../../components/V2/Reward/RewardAnimation";
import { UserContext } from "../../Contexts/UserContext";
import RewardSummary from "../../pages/Rewards/RewardSummary";
import DocumentUpload from "../../pages/LoanJournery/v3/DocumentUploads";
import BankDetails from "../../pages/LoanJournery/v3/BankDetails";

const LoanJourney = () => {
  const {} = useContext(CustomerContext);
  const {
    showPermissionState,
    applicationState,
    showRewardState,
    coinsState,
    getCoinData,
  } = useContext(GlobalContext);

  const { journeyState, clearJourneyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [isReward, showReward] = showRewardState;

  const [nbfc, setNbfc] = useState("");
  const [coins, setCoins] = coinsState;
  const { user } = useContext(UserContext);

  const [showPermission] = showPermissionState;
  const { permissions, getUserLocation } = usePermission();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setNbfc(localStorage.getItem("nbfc") || null);
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
      clearJourneyState();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // const { getStage } = useApplication();
  // useEffect(() => {
  //   getStage(journeyData?.stage)
  // }, [journeyData?.stage]);

  // useEffect(() => {
  //   getCoinData();
  // }, [navigate]);

  return (
    <>
      <div className="content-center">
        <Routes>
          <Route path="pan-upload" element={<PanUpload />} />
          {/* <Route path="pan-upload" element={<PsychometricTest />} /> */}
          <Route path="pan-details/:application_id" element={<PanDetails />} />
          <Route path="status/:application_id" element={<CustomerStatus />} />
          <Route path="co-customer/:application_id" element={<CoGuarantor />} />
          <Route
            path="co-customer-details/:application_id"
            element={<CoCustomerDetails />}
          />
          <Route
            path="status-cocustomer/:application_id"
            element={<CoCustomerStatus />}
          />
          <Route
            path="loan-creation/:application_id"
            element={<LoanCreationVani />}
          />
          <Route
            path="loan-summary/:application_id"
            element={<LoanSummary />}
          />

          <Route
            path="bank-details/:application_id"
            element={<BankDetails />}
          />

          <Route
            path="documents-upload/:application_id"
            element={<DocumentUpload />}
          />

          <Route
            path="documents-upload/:application_id/:tab"
            element={<DocumentUpload />}
          />

          {/* <Route path="progress/*">
            <Route path=":application_id" element={<ApplicationProgress />} />
            <Route
              path="field-investigation/:application_id"
              element={<FieldInvestigation />}
            />
            <Route
              path="field-investigation/v2/:application_id"
              element={<FieldInvestigationV2 />}
            />
            <Route
              path="field-investigation/v2/overview/:application_id"
              element={<FieldInvestigationDone />}
            />
            <Route
              path="pre-disbursement/:application_id"
              element={<PreDisbursement />}
            />
            <Route
              path="pre-disbursement/customer/:application_id"
              element={<PreDisbursementCustomer />}
            />
            <Route
              path="pre-disbursement/co-customer/:application_id"
              element={<PreDisbursementCoApp />}
            />
            <Route
              path="pre-disbursement/bank/:application_id"
              element={<PreDisbursementBank />}
            />

  
            <Route
              path="documents/part-1/:application_id"
              element={<PartOne />}
            />
            <Route
              path="documents/part-2/:application_id"
              element={<PartTwo />}
            />

            <Route
              path="post-disbursement/:application_id"
              element={<PostDisbursement />}
            />
          </Route> */}

          <Route path="loan-status/:application_id" element={<LoanStatus />} />
          <Route
            path="payment-method/:application_id"
            element={<PaymentMethod />}
          />
          <Route
            path="payment-method/enach/:application_id"
            element={<ENach />}
          />
          <Route
            path="payment-method/upi/:application_id"
            element={<UpiAutoCollect />}
          />

          <Route path="rewards/:application_id" element={<RewardSummary />} />
          <Route path="rewards/" element={<RewardSummary />} />

          <Route path="otp/:vId" element={<OtpScreen />} />
        </Routes>

        {/* {permissions.camera === "PROMPT" && showPermission.state && (
          <PermissionModal initial={true} />
        )} */}
      </div>

      {/* {user?.incentives_enabled &&
        !location?.pathname?.includes("/create-loan/rewards") && (
          <RewardWallet coin={coins?.overall} journey={true} />
        )}
      {isReward?.show && <RewardAnimation coin={isReward?.coin} />} */}
    </>
  );
};

export default LoanJourney;
