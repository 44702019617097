import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchDashboardData } from "../../api/agent";
import CountUp from "react-countup";
// import "./Home.css";
import homeIco from "../../assets/homeIco1.svg";
import MixPanelAudit from "../../util/MixPanelAudit";
import { UserContext } from "../../Contexts/UserContext";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { l } from "../../util/languageConvertor";

const Home = () => {
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const getDashboardData = async () => {
    try {
      setLoading(true);
      let { data } = await fetchDashboardData();
      setDashboardData(data?.data);
    } catch (error) {
      enqueueSnackbar(error?.message || "Error loading dashboard data", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <div className="content-center">
        <Grid container spacing={2} className="p-3 response-wrapper">
          <Grid item xs={12} sm={12} className="mt-5 mb-2">
            <div className="card-home create-card" style={{ height: "160px" }}>
              <div className="d-flex justify-content-between">
                <p className="heading mb-0">
                  {l("Create E-Rick Loan Application", lang)}
                  <div>
                    <Button
                      className="btn mt-4 create-button"
                      onClick={() => {
                        MixPanelAudit.intercept("new_application_click", {
                          distinct_id: user?.mobile_number[0],
                          agent_name: user?.name,
                        });
                        navigate("/create-loan/pan-upload");
                      }}
                    >
                      {l("Create", lang)}
                    </Button>
                  </div>
                </p>
                <p className="desc-text" style={{ color: "#6A6A6A" }}>
                  <img src={homeIco} alt="homeIco" />
                </p>
              </div>
              <div className="button-holder"></div>
              <i></i>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} className="pb-0">
            <div
              style={{
                fontSize: 25,
                fontWeight: 500,
              }}
            >
              {l("Overview", lang)}
            </div>
          </Grid>

          {/* <Grid item xs={6} sm={6}>
            <div
              className="card-home pointer"
              style={{ height: "130px" }}
              onClick={() => {
                MixPanelAudit.intercept("draft_click", {
                  distinct_id: user?.mobile_number[0],
                  agent_name: user?.name,
                });
                navigate("/all-applications/draft");
              }}
            >
              <div className="d-flex justify-content-between">
                <p className="heading mb-0">
                  {l("Draft Applications", lang)}
                  <div style={{ fontSize: 48 }}>
                    {!loading ? (
                      <CountUp end={dashboardData?.draftApplications} />
                    ) : (
                      <CircularProgress style={{ color: "white" }} />
                    )}
                  </div>
                </p>
              </div>
              <div className="button-holder"></div>
            </div>
          </Grid> */}

          <Grid item xs={6} sm={6}>
            <div
              className="card-home pointer"
              style={{ height: "130px" }}
              onClick={() => {
                navigate("/all-applications/draft");
              }}
            >
              <div className="d-flex justify-content-between">
                <p className="heading mb-0">
                  {l("All Applications", lang)}
                  {/* <div style={{ fontSize: 48 }}>{dashboardData?.totalApplications}</div> */}
                  <div style={{ fontSize: 48 }}>
                    {!loading ? (
                      <CountUp end={dashboardData?.totalApplications} />
                    ) : (
                      <CircularProgress style={{ color: "white" }} />
                    )}
                  </div>
                </p>
              </div>
              <div className="button-holder"></div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;
