import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";

export default function CustomInputAutocomplete({
  text,
  options,
  onChange: setVal,
  value,
}) {
  return (
    <Autocomplete
      sx={{
        "& input": {
          width: "100% !important",
        },
      }}
      value={value}
      id="custom-input-demo"
      options={options}
      getOptionLabel={(option) => option?.name}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input
            placeholder={text ? text : "Select an option"}
            type="text"
            {...params.inputProps}
            className="form-input px-3 input-blue"
          />
        </div>
      )}
      onChange={(e, value) => {
        setVal(value);
      }}
    />
  );
}
