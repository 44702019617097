import axios from 'axios';

const API = axios.create({ baseURL: 'https://api.eriksha.embifi.in/v1', withCredentials: true })

export const sendOTP = (data, method) => API.post(`/user/send-otp?method=${method}`, data);
export const verifyOTP = (data) => API.post(`/user/verify-otp`, data);
export const signIn = (data) => API.post(`/user/login`, data);
export const verifyAuth = () => API.get(`/user/verify-user`);
export const addName = (data) => API.put(`user/update-basic-details`, data);
export const resetPassword = (data) => API.put(`/user/update-password`, data);
export const uploadDocuments = (data) => API.put(`/user/kyc-details`, data);
export const updateGstData = (data) => API.put(`/user/gst-step`, data);
export const updateBankDetails = (data) => API.put(`/user/bank-details`, data);

// Loan Routes

export const fetchAllApplications = (filter, filterType, duration, status) => API.get(`/user/all-applications?filter=${filter}&loanStatus=${status}&filterType=${filterType}&filterValue=${duration}`);
export const fetchDraftApplications = (filterType, duration) => API.get(`/user/draft-applications?filterType=${filterType}&filterValue=${duration}`);
export const fetchCompletedApplications = () => API.get(`/user/completed-applications`);
export const fetchRecentApplications = (status) => API.get(`/user/applications?filter=LOAN_COMPLETED&days=7&status=${status}`);
export const searchApplication = (query, status, filter, durationType, duration, subStatus) => API.get(`user/search?query=${query}&status=${status}&filter=${filter}&filterType=${durationType}&filterValue=${duration}&filterValue=${subStatus}`);



export const logout = () => API.post(`/user/logout`);

// AADHAR ENDPOINTS

export const requestOTPAadhar = (data) => API.post(`/user/aadhar-verification`, data)
export const submitOTPAadhar = (data) => API.post(`/user/aadhar-submitOTP`, data)

// CUSTOMER LOAN JOURNEY

export const panUpload = (data) => API.post(`/common/extract/id/PAN`, data)
export const createCustomer = (data) => API.post(`/customer/create`, data)
export const updateContactDetails = (data) => API.put(`/customer/contact-details`, data)
export const updateAddress = (data) => API.put(`/customer/personal-details`, data)
export const uploadCustomerDocument = (data) => API.put(`/customer/upload-personal-docs`, data)
export const updateCustomerBankDetails = (data) => API.post(`/customer/upload-bank-details`, data)
export const processLoan = (data) => API.put(`/customer/update-loan-details`, data)
export const addVehicle = (data) => API.post(`/customer/add-vehicle`, data)
export const getLoanOffers = () => API.get(`/customer/get-loan-offers`)
export const updateVehicleDetails = (data) => API.put(`/customer/vehicle-details`, data)
export const getCustomerData = (id) => API.get(`/customer/fetch-data/${id}`)
export const getLoanData = (id) => API.get(`/customer/fetch-loan/${id}`)
export const completeLoan = (data) => API.post(`/customer/agreement-complete`, data)

// Loan Profile

export const getLoanProfile = (id) => API.get(`/customer/fetch-loan-profile/${id}`)
export const getPerviousLoan = (id) => API.get(`/customer/previous-loans/${id}`)
export const getSingleTransactions = (id) => API.get(`/user/transactions/${id}`)

// CO-APPLICANT AND GUARANTOR LOAN JOURNEY

export const getCoDetails = (id) => API.get(`/customer/co/${id}`)
export const createCoCustomer = (data) => API.post(`/customer/create-cocustomer`, data)
export const updateCoContactDetails = (data) => API.put(`/customer/co/contact-details`, data)
export const updateCoPersonalDetails = (data) => API.put(`/customer/co/personal-details`, data)
export const uploadPersonalDoc = (data) => API.post(`/customer/co/upload-personal-docs`, data)
export const getCoCustomerDetails = (id) => API.get(`/customer/co/fetch/${id}`)


// Common

export const verifyPincode = (pin) => API.get(`common/pincode/${pin}`)
export const getChequeData = (data) => API.post(`/common/cheque-ocr`, data)

// Collections

export const getCollections = (query) => API.get(`/user/loan-collections?query=${query}`)
export const collectLoanAmount = (data) => API.put(`/user/collect-loan-amount`, data)
export const getTransactions = (mode, filterType, duration, query) => API.get(`/user/transaction-history?filterType=${filterType}&filterValue=${duration}&transactionType=${mode}&query=${query}`)
