import { createContext, useEffect, useState } from "react";

export const CustomerContext = createContext(null);

const CustomerContextProvider = ({ children }) => {
  const [customerDetails, setCustomerDetails] = useState({
    customerId: "",
    mobile_number: "",
    email: "",
    res_address: "",
    pincode: "",
    isPropertyOwned: "",
    gender: "",
    income: "",
    specifiedIncome: "",
    education: "",
    specifiedEducation: "",
    selfie: "",
    electricity_bill: "",
    license: "",
    aadhaar_front: "",
    aadhaar_back: "",
  });

  const [panDetails, setPanDetails] = useState({
    image: "",
    pan_number: "",
    full_name: "",
    dob: "",
  });

  const [bankDetails, setBankDetails] = useState({
    cheques: [],
    account_num: "",
    ifsc: "",
    bank_statement: "",
    bank: "",
    parsed_bank: "",
    result:{}
  });

  const [vehicleDetails, setVehicleDetails] = useState({
    id: "",
    model: "",
    total_cost: "",
    down_pay: "",
    loan_amount: "",
    tenure: "12",
    process_charge: "",
    discount: "",
    interest: "",
    process_charge: "",
    total_payable: "",
    emi: "",
    chasis_no: "",
    reg_no: "",
    manufacture_year: "",
    invoice_no: "",
    invoice_date: "",
    reg_cost: "",
    insurance_cost: "",
    insurance_company: "",
    policy_no: "",
    issued_on: "",
    policy_period: "",
  });

  const [coAppDetails, setCoAppDetails] = useState({
    id: "",
    image: "",
    pan_number: "",
    full_name: "",
    dob: "",
    selfie: "",
    mobile_number: "",
    email: "",
    res_address: "",
    pincode: "",
    gender: "",
    income: "",
    specifiedIncome: "",
    education: "",
    specifiedEducation: "",
    aadhaar_front: "",
    aadhaar_back: "",
    relationship: "",
  });

  const [availableLoans, setAvailableLoans] = useState([]);
  const [loanProfile, setLoanProfile] = useState({});
  const [prevLoans, setPrevLoans] = useState([]);
  const [showCollect, setShowCollect] = useState(false);
  

  return (
    <CustomerContext.Provider
      value={{
        panDetailState: [panDetails, setPanDetails],
        detailState: [customerDetails, setCustomerDetails],
        bankDetailState: [bankDetails, setBankDetails],
        vehicleState: [vehicleDetails, setVehicleDetails],
        coAppState: [coAppDetails, setCoAppDetails],
        loanOffers: [availableLoans, setAvailableLoans],
        loanProfileState: [loanProfile, setLoanProfile],
        prevLoanState:[prevLoans, setPrevLoans],
        StateShowCollect:[showCollect, setShowCollect]
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContextProvider;
