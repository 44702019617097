import Compressor from "compressorjs";

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
    //   console.log(fileInfo);
  });
};

export const compressImage = (file, beta = false) => {
  if (beta) {
    return new Promise((resolve, reject) => {
      const image = file;
      new Compressor(image, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // setCompressedFile(res)
          resolve(compressedResult);
        },
      });
    });
  } else {
    // alert("No Compressing image")
    return file;
  }
};
