import {
  Autocomplete,
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slide,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeDefaultBank,
  fetchAllBanks,
  proceedApplication,
  proceedPayment,
  updateLanguage,
} from "../api/agent";
import MenuAppBar from "../components/Appbar";
// import CustomInputAutocomplete from "../components/Autocomplete/AutoCompleteBank";
// import CircularProgress from "../../../../components/CircularProgress";
import { GlobalContext } from "../Contexts/GlobalContext";
import { LoanContext } from "../Contexts/LoanJourneyContext";
import useApplication from "../hooks/useApplication";
import erickIco from "../assets/ericksha.gif";
import bgCircle from "../assets/bgCircle.svg";
import OtpLogin from "./OtpLogin";
// import LoginSplashScreen from "./LoginSplashScreen";
import loginManIco from "../assets/loginImage.svg";
import langIco from "../assets/langIco.svg";
import { isMobValid } from "../validations/fieldValidations";
import useAuth from "../hooks/AuthScreens/Agent/useAuth";
import MixPanelAudit from "../util/MixPanelAudit";
import { l } from "../util/languageConvertor";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { async } from "q";
import { UserContext } from "../Contexts/UserContext";

const statusColor = {
  pending: "#274384",
  unsuccessfull: "#FF0000",
  successfull: "#2AB514",
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const LanguageSelection = forwardRef((props, ref) => {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { bottomNav, showRewardState, langState } = useContext(GlobalContext);

  const [lang, setLang] = langState;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSplash, setOpenSplash] = useState(true);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");
  const { user } = useContext(UserContext);

  const handleChange = (lang) => async (e) => {
    setLang(lang);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      let { data } = await updateLanguage({ language: lang });
      if (data?.status) {
        enqueueSnackbar(l("Language Updated Successfully", lang), {
          variant: "success",
        });
        MixPanelAudit.intercept("Language Proceeded", {
          language: lang,
          distinct_id: user?.mobile_number[0],
          agent_name: user?.name,
        });
        navigate("/home");
      }
    } catch (error) {
      navigate(
        error?.response?.data?.message ||
          "Something went wrong. You can change language Later"
      );
      navigate("/home");
    }
    setLoading(false);
  };

  useEffect(() => {
    setLang("hindi");
  }, []);

  return (
    <>
      <style>
        {`body{
            background-color: #573A7A;
        }`}
      </style>

      <div className="login-wrapper">
        <Box
          className="kyc-wrapper w-100 bottom-content"
          // sx={{ pt: 2 }}
          ref={ref}
        >
          <CssBaseline />
          <div
            className="main-wrapper"
            style={{ position: "relative", paddingBottom: 100 }}
          >
            <div className="lang-ico">
              <img src={langIco} alt="" />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <p className="lang-page-heading mb-0 mt-3">Welcome</p>
                <p className="mb-0" style={{ color: "white" }}>
                  Let’s get started {lang}
                </p>
              </Grid>

              <Grid item xs={12} lg={12}>
                <p className="lang-page-heading-2 mt-5">Select language</p>
              </Grid>

              <Grid item xs={12} lg={4}>
                <div className={`lang-card`} onClick={handleChange("english")}>
                  <p className="mb-0 lang-title">Welcome to Embifi</p>
                  <p className="mb-0 lang-desc">
                    English{" "}
                    <span
                      style={{
                        color: lang === "english" ? "#573A7A" : "#AEAEAE",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <div className={`lang-card`} onClick={handleChange("hindi")}>
                  <p className="mb-0 lang-title">एम्बिफी में आपका स्वागत है</p>
                  <p className="mb-0 lang-desc">
                    Hindi{" "}
                    <span
                      style={{
                        color: lang === "hindi" ? "#573A7A" : "#AEAEAE",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <div className={`lang-card`} onClick={handleChange("hinglish")}>
                  <p className="mb-0 lang-title">
                    Embifi mein aapaka svaagat hai
                  </p>
                  <p className="mb-0 lang-desc">
                    Hinglish{" "}
                    <span
                      style={{
                        color: lang === "hinglish" ? "#573A7A" : "#AEAEAE",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>

          <Paper className="paper-bottom" elevation={3}>
            <BottomNavigation
              style={{
                height: "auto",
                paddingTop: "3px",
                background: "transparent",
              }}
            >
              <div className="button-wrapper w-100">
                <Button
                  className={false ? "btn-submit-disabled" : "lang-btn-submit"}
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress
                        className="me-2 mb-1"
                        size={20}
                        style={{ color: "white" }}
                      />
                      {l("Loading", lang)}
                    </>
                  ) : (
                    l("Continue", lang)
                  )}
                </Button>
              </div>
            </BottomNavigation>
          </Paper>
        </Box>
      </div>
      <div
        className="circle-img"
        style={{ position: "fixed", bottom: 0, right: 0 }}
      >
        <img src={bgCircle} className="bg-circle" alt="" />
      </div>
    </>
  );
});

export default LanguageSelection;
