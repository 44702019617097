export const documentsList = {
    docs: {
        customer_photo: "customer_photo",
        driving_license:"driving_license",
        other_ownership_proof:"other_ownership_proof",
        electricity_bill:"electricity_bill",

        // CO CUSTOMER

        co_customer_photo:"co_customer_photo",
        co_customer_dl:"co_customer_dl",
        co_customer_electricity_bill:"co_customer_electricity_bill",
        co_customer_ownership_proof:"co_customer_ownership_proof",

        // Vehicle Documents

        form_26_35:"form_26_35",
        sales_invoice:"sales_invoice",
        form_22:"form_22",
        erick_delivery_photo:"erick_delivery_photo",
        chassis_number:"chassis_number",
        battery_warranty:"battery_warranty",
        insurance:"insurance",
        form_20:"form_20",
        form_21:"form_21",
        trc:"trc",

        // Loan Documents

        loan_agreement:"loan_agreement",
        stamp_paper:"stamp_paper",
        dual_form:"dual_form",

        // Bank documents

        cheque_1:"cheque_1",
        cheque_2:"cheque_2",
        cheque_3:"cheque_3",
        bsv:"bsv",
        bank_passbook:"bank_passbook",
        other_documents:"other_documents",
    },

    categories: {
        customer_photo: {
            name: "Customer photo",
            tab: 3
        },
        driving_license:{
            name: "Driving License",
            tab: 3
        },
        other_ownership_proof:{
            name: "House ownership proof(Electricity bill)",
            tab: 3
        },
        // electricity_bill:"electricity_bill",



        co_customer_photo:{
            name: "Co-Borrower Photo",
            tab: 4
        },
        co_customer_dl:{
            name: "Driving License",
            tab: 4
        },
        co_customer_ownership_proof:{
            name: "House ownership proof(Electricity bill)",
            tab: 4
        },

        // Loan Documents

        loan_agreement:{
            name: "Loan Agreement",
            tab: 5
        },
        stamp_paper:{
            name: "Stamp Paper",
            tab: 5
        },
        dual_form:{
            name: "Dual Form",
            tab: 5
        },



        // Vehicle Documents

        form_26_35:{
            name: "Form 26-35",
            tab: 6
        },
        sales_invoice:{
            name: "Sales Invoice",
            tab: 6
        },
        form_22:{
            name: "Form 22",
            tab: 6
        },
        erick_delivery_photo:{
            name: "Vehicle Delivery photo",
            tab: 6
        },
        chassis_number:{
            name: "Chassis Number Photo",
            tab: 6
        },
        battery_warranty:{
            name: "Battery Warranty Card",
            tab: 6
        },
        insurance:{
            name: "Insurance",
            tab: 6
        },
        form_20:{
            name: "Form 20",
            tab: 6
        },
        form_21:{
            name: "Form 21",
            tab: 6
        },
        trc:{
            name: "TRC",
            tab: 6
        },

        

        // Bank documents

        cheque_1:{
            name: "Cheque 1",
            tab: 7
        },
        cheque_2:{
            name: "Cheque 2",
            tab: 7
        },
        cheque_3:{
            name: "Cheque 3",
            tab: 7
        },
        bsv:{
            name: "BSV",
            tab: 7
        },
        bank_passbook:{
            name: "Bank Passbook",
            tab: 7
        },
        other_documents:{
            name: "Other Documents",
            tab: 7
        },
    }
}