import React, { useState } from "react";
import { useEffect } from "react";
import "./select.css";

function Select(params) {
  let options = params.hasOwnProperty("options") ? params.options : [];
  const [selected, setSelected] = useState(options[0]);
  const [selectStyle, setStyle] = useState({
    fontWeight: 300,
    color: "#B8BCCA",
    fontSize: "11px",
  });
  const [attributes, setAttributes] = useState({});

  useEffect(() => {
    if (params.state && params.state !== "") {
      setAttributes({ value: params?.state });
      if (params?.styleText) {
        setStyle({
          color: "#2f60d1",
          fontSize:"13px",
          paddingBottom:"8px"
        });
      } else {
        setStyle({
          color: "black",
        });
      }
    }
  }, [params.state]);

  return (
    <div
      class={`select-style ${
        // params.isBlue ? "select-bg-white" : "select-bg-gray"
       "select-bg-gray"
      }`}
    >
      <select
        className={params.isBlue && "select-blue"}
        ref={params?.reference}
        style={selectStyle}
        {...attributes}
        onChange={(e) => {
          setStyle({
            color: "black",
          });
          params.getData && params.getData(e.target.value);
        }}
      >
        <option default disabled selected>
          {params?.text ? params.text : "Select an option"}
        </option>
        {options.map((val, i) => {
          return (
            <>
              <option value={val}>{val}</option>
            </>
          );
        })}
      </select>
    </div>
  );
}

export default Select;
