import { Button, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import noConnection from "../assets/no-wifi-icon.svg"

const NoConnection = ({text}) => {

  return (
    <div className="kyc-dialog">
      <div className="dialog py-5" style={{width:"250px"}}>
        <p className="welcome text-center">
          <img src={noConnection} width="100px" alt="no-connection"/>
        </p>
        <p className="welcome mt-2 text-center">No Connection!</p>
      </div>
    </div>
  );
};

export default NoConnection;
