import { forwardRef, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import CustomerCheckBox from "../../../../components/V3/CustomerCheckBox";
import { documentStatus } from "../../../../api/agent";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { documentsList } from "../../../../constants/DocumentsList";
import { l } from "../../../../util/languageConvertor";
import { CircularProgress } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StatusDialog({
  open,
  handleClose,
  application_id,
  coExist,
  defaultTab,
}) {
  const [docStatus, setDocStatus] = useState({});
  const [active, setActive] = useState("pending");
  const [loading, setLoading] = useState(false);

  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const handleGetList = async () => {
    try {
      setLoading(true);
      let { data } = await documentStatus(application_id);
      if (data?.status) {
        setDocStatus({
          pending: data?.Pending,
          rejected: data?.Rejected,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setActive(defaultTab || "pending");
      handleGetList();
    }
  }, [open]);

  const navigate = useNavigate();
  useEffect(() => {
    window.onpopstate = () => {
      handleClose();
    };
  });

  const handleNavigate = (tab) => {
    handleClose();
    navigate(
      `/create-loan/documents-upload/${application_id}/${
        tab > 3 && !coExist ? tab - 1 : tab
      }`
    );
  };

  useEffect(() => {
    setActive(defaultTab);
  }, [defaultTab]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div
          style={{
            background: "white",
            height: "80vh",
            width: "100%",
            position: "fixed",
            bottom: 0,
          }}
        >
          <div
            style={{
              marginTop: "-50px",
              overflow: "hidden",
            }}
            className="content-center"
          >
            <CloseIcon
              sx={{ fontSize: "40px", color: "white", cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>

          <div className="content-center pt-3">
            <CustomerCheckBox
              active={active}
              onChange={(value) => {
                setActive(!value ? "pending" : "rejected");
              }}
            />
          </div>
          <List>
            <div style={{ overflowY: "scroll", height: "75vh" }}>
              {loading ? (
                <div className="content-center w-100 pt-3">
                  <CircularProgress color="secondary" size={50} />
                </div>
              ) : docStatus?.[active]?.length === 0 ? (
                <div className="content-center w-100 pt-3">
                  {active === "pending"
                    ? l("No Pending Documents", lang)
                    : l("No Rejected Documents", lang)}
                </div>
              ) : (
                <div style={{ paddingBottom: "100px" }}>
                  {docStatus?.[active]?.map((item, index) => {
                    return (
                      <>
                        <ListItem
                          button
                          onClick={() => {
                            handleNavigate(
                              documentsList?.categories?.[item]?.tab
                            );
                          }}
                        >
                          <div className="content-center w-100 pt-3">
                            {documentsList?.categories?.[item] &&
                              l(documentsList?.categories?.[item]?.name, lang)}
                          </div>
                        </ListItem>
                        <Divider />
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </List>
        </div>
      </Dialog>
    </div>
  );
}
