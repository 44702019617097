import { Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { kycContext } from "../Contexts/KycContext";
import { UserContext } from "../Contexts/UserContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PennyDrop = ({ setStatus }) => {
  const { pennyState } = useContext(kycContext);
  const [pennyDrop, setPennyDrop] = pennyState;

  const { user } = useContext(UserContext);
  const navigate = useNavigate();


  return (
    <div className="kyc-dialog">
      <div className="dialog">
        <p className="welcome text-center">Penny Drop</p>
        {pennyDrop.status ? (
          <>
            <p className="text-center mt-4 mb-0">
              <CheckCircleIcon style={{ color: "green", fontSize: "70px" }} />
            </p>
            <p className="desc w-100 text-center">Success</p>
          </>
        ) : (
          <>
            <p className="text-center w-100 mt-4">
              <CircularProgress />
            </p>
            <p className="desc w-100 text-center">
              We are verifying your bank account
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PennyDrop;
