import { Button, CssBaseline, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import UploadCard from "../../../../components/V3/UploadCard";
import lenderIco from "../../../../assets/nyleasing.png";
import useApplication from "../../../../hooks/useApplication";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import MenuAppBar from "../../../../components/Appbar";
import { useNavigate } from "react-router-dom";
import { l } from "../../../../util/languageConvertor";
import { GlobalContext } from "../../../../Contexts/GlobalContext";

const LenderDetails = () => {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const navigate = useNavigate();

  useEffect(() => {
    console.log(journeyData);
  }, [journeyData]);

  const styles = {
    header: {
      fontWeight: "500",
      fontSize: "12px",
      marginBottom: 0,
    },
    data: {
      color: "#412860",
      fontSize: "14px",
      marginBottom: 0,
    },
    bottomLink: {
      fontSize: "10px",
      marginBottom: 0,
      textAlign: "center",
      cursor: "pointer",
      textDecoration: "underline",
      color: "#667080",
    },

    bottomText: {
      fontSize: "10px",
      marginBottom: 0,
      textAlign: "center",
      color: "#667080",
    },
  };
  return (
    <div style={{ position: "relative", height: "100%" }}>
      <MenuAppBar
        home={false}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
      />
      <CssBaseline />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          {journeyData?.nbfcData?.logo && (
            <div className="content-center">
              <img src={journeyData?.nbfcData?.logo} alt="lender" width={100} />
            </div>
          )}
        </Grid>

        <Grid item xs={4} sm={3}>
          <p style={styles?.header}>{l("Lender", lang)}:</p>
        </Grid>
        <Grid item xs={8} sm={9}>
          <p style={styles?.data}>{journeyData?.nbfcData?.name}</p>
        </Grid>

        <Grid item xs={4} sm={3}>
          <p style={styles?.header}>{l("Address", lang)}:</p>
        </Grid>
        <Grid item xs={8} sm={9}>
          <p style={styles?.data}>{journeyData?.nbfcData?.address}</p>
        </Grid>

        <Grid item xs={4} sm={3}>
          <p style={styles?.header}>{l("Website Link", lang)}:</p>
        </Grid>
        <Grid item xs={8} sm={9}>
          <p style={styles?.data}>{journeyData?.nbfcData?.website}</p>
        </Grid>
        <Grid item xs={4} sm={3}>
          <p style={styles?.header}>{l("Product offered", lang)}:</p>
        </Grid>
        <Grid item xs={8} sm={9}>
          <p style={styles?.data}>Erickshaw Loan</p>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ position: "absolute", bottom: "0" }}>
        <Grid item xs={12} sm={12}>
          <div className="d-flex content-center mb-0">
            <p
              style={styles?.bottomLink}
              className="me-2 pointer"
              onClick={() => {
                window.open(journeyData?.nbfcData?.terms, "_blank");
              }}
            >
              Terms and conditions{" "}
            </p>
            <p
              style={styles?.bottomLink}
              className="pointer"
              onClick={() => {
                window.open(journeyData?.nbfcData?.privacy_policy, "_blank");
              }}
            >
              Privacy Policy
            </p>
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <p style={styles?.bottomText}>
            For any queries or grievances, please drop a mail {journeyData?.nbfcData?.email} or
            contact {journeyData?.nbfcData?.contact}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default LenderDetails;
