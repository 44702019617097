import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "FROM FIREBASE CONSOLE",
//   authDomain: "FROM FIREBASE CONSOLE",
//   databaseURL: "FROM FIREBASE CONSOLE",
//   projectId: "FROM FIREBASE CONSOLE",
//   storageBucket: "FROM FIREBASE CONSOLE",
//   messagingSenderId: "FROM FIREBASE CONSOLE",
//   appId: "FROM FIREBASE CONSOLE",
//   measurementId: "FROM FIREBASE CONSOLE",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDDg2VK1FLZRmxv5k-Bbn1VsowL1SK2rbs",
  authDomain: "notification-project-c7872.firebaseapp.com",
  projectId: "notification-project-c7872",
  storageBucket: "notification-project-c7872.appspot.com",
  messagingSenderId: "983626491691",
  appId: "1:983626491691:web:eea8bace67ceda373f876e",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// const { REACT_APP_VAPID_KEY } = process.env;
const publicKey =
  "BKlgyuOIwjg9RQhE-ikzTxggl5IqRudGZ1ZLsymiB__n4dG2VxrjRnAil3PqCZd77g4qDm8MnRR6rh0IiSPoWVM";

export const getTokenFn = (setTokenData) => {
  return getToken(messaging, { vapidKey: publicKey })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenData({
          found: true,
          token: currentToken,
        });
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenData({
          found: false,
          token: null,
        });
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
