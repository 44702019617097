import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import MenuAppBar from "../../../components/Appbar";
import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, FormControl, Row } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import SearchIcon from "@mui/icons-material/Search";
import emptyIco from "../../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getAllApplicationsV2 } from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import MixPanelAudit from "../../../util/MixPanelAudit";
import { UserContext } from "../../../Contexts/UserContext";
import { l } from "../../../util/languageConvertor";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDebounce } from "use-debounce";
import ScrollButton from "../../../components/V3/ScrollToTop";

export default function AllApplications() {
  const ref = useRef(null);
  const selectRef = useRef(null);

  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [time, setTime] = useState("Any time");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [filter, setFilter] = useState("draft");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [value] = useDebounce(searchTxt, 800);

  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const { filterType } = useParams();

  const { showPermissionState, applicationState, langState } =
    useContext(GlobalContext);
  const [lang, setLang] = langState;

  const fetchDraftApplications = async (params) => {
    setLoading(true);
    try {
      let { data } = await getAllApplicationsV2(params);
      setApplications([...applications, ...(data?.data?.applications || [])]);
      setTotalCount(data?.data?.totalCount);
    } catch (error) {}
    setLoading(false);
    setSearching(false);
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home/loan");
    };
  });

  const mapStage = (stage) => {
    switch (stage) {
      case "CUSTOMER_BASIC_DETAILS":
        return "Customer Details";
        break;
      case "CUSTOMER_EMBIFI_REJECTED":
        return "Rejected";
        break;
      case "CUSTOMER_CREDIT_ENGINE_FAILED":
        return "Under Review";
        break;
      case "CUSTOMER_UNDER_REVIEW":
        return "Under Review";
        break;
      case "CO_CUSTOMER_CREATION":
        return "Co-Applicant Onboarding";
        break;
      case "CO_CUSTOMER_RECREATION":
        return "Co-Applicant Re-creation";
        break;
      case "CO_CUSTOMER_BASIC_DETAILS":
        return "Co-Applicant Basic Details";
        break;
      case "CO_CUSTOMER_EMBIFI_REJECTED":
        return "Co-Applicant Rejected";
        break;
      case "CO_CUSTOMER_CREDIT_ENGINE_FAILED":
        return "Co-Applicant Under Review";
        break;
      case "CO_CUSTOMER_UNDER_REVIEW":
        return "Co-Applicant Under Review";
        break;
      case "APPLICATION_STAGE_PROGRESSION":
        return "Document Uploads";
        break;
      case "LOAN_UPDATE":
        return "Loan Creation";
        break;
      case "BANK_DETAILS":
        return "Bank Details";
        break;
      case "NBFC_ASSIGN_PENDING":
        return "NBFC Assignment Pending";
        break;
      case "LOAN_OFFER":
        return "Loan Summary";
        break;
      case "PRE_DISBURSEMENT":
        return "Pre Disbursment";
        break;
      case "PAYMENT_METHOD_SELECTION":
        return "Payment Selection";
        break;
      case "PENNY_DROP_FAILED":
        return "Bank Details Under Review";
        break;
      case "WAITING_FOR_NBFC_APPROVAL":
        return "NBFC Approval Pending";
        break;
      case "WAITING_FOR_DISBURSAL":
        return "Waiting Disbursal";
        break;
      case "DISBURSED":
        return "Disbursed";
        break;
      case "COMPLETED":
        return "Completed";
        break;
    }
  };

  const { getStage } = useApplication();

  const handleChangeFilter = (value) => {
    setLoading(true);
    setTotalCount(0);
    setPage(1);
    setLimit(20);
    setApplications([]);
    setFilter(value);
  };

  useEffect(() => {
    fetchDraftApplications(
      `filter=${filter}&page=${page}&limit=${limit}&search=${value}`
    );
  }, [filter, page, limit, value]);

  useEffect(() => {
    if (value !== "") {
      setSearching(true);
    }
  }, [value]);

  useEffect(() => {
    console.log(applications.length + "  -  " + totalCount);
  }, [applications]);

  useEffect(() => {
    if (
      ["all", "pending", "rejected", "closed", "disbursed"].includes(filterType)
    ) {
      setFilter(filterType);
    }
  }, [filterType]);

  const LoadingComp = () => {
    return ["", "", ""].map((val) => {
      return (
        <>
          <Container className="load-collapse-row collapse-blue pointer">
            <Row className="mt-2">
              <Col className="font-blue">
                <Skeleton width={"100%"} />
              </Col>
              <Col className="font-blue">
                <Skeleton width={"100%"} />
              </Col>
              <Col className="font-blue">
                <Skeleton width={"100%"} />
              </Col>
            </Row>
          </Container>
        </>
      );
    });
  };

  return (
    <>
      <Box sx={{ pb: 7 }} ref={ref} className="content-center">
        <Grid container spacing={2} className="p-3 response-wrapper">
          <Grid item xs={12} sm={12}>
            <div className="page-head">
              <ArrowBackIcon
                sx={{ color: "#573A7A" }}
                className="pointer"
                onClick={() => {
                  navigate("/home");
                }}
              />{" "}
              {l("All Applications", lang)}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} className="pt-0">
            <Container
              className="load-collapse-row mb-0 mt-0"
              style={{
                boxShadow: "none",
                border: "none",
                position: "sticky",
                top: 0,
                background: "white",
                zIndex:1500
              }}
            >
              <Row style={{ background: "white" }}>
                <div
                  style={{
                    position: "relative",
                    padding: 0,
                    marginTop: 15,
                    background: "white",
                  }}
                >
                  <input
                    type={"text"}
                    placeholder={l("Search Name/Application Id", lang)}
                    class="form-input input-blue input-blue-search w-100"
                    value={searchTxt}
                    onChange={(e) => {
                      setLoading(true);
                      setPage(1);
                      setApplications([]);
                      setSearchTxt(e.target.value);
                    }}
                  />

                  {!isSearching ? (
                    <SearchIcon
                      className="search-ico"
                      style={{ color: "gray" }}
                      onClick={() => {}}
                    />
                  ) : (
                    <div style={{ position: "absolute", right: 5, top: 5 }}>
                      <CircularProgress size={20} />
                    </div>
                  )}
                </div>
              </Row>
              <Row
                style={{
                  color: "#573A7A",
                  backgroundColor: "white",
                  paddingTop: 10,
                }}
                className="chip-holder"
              >
                <Col className="p-0 pe-2" style={{ background: "white" }}>
                  <Chip
                    label="All"
                    variant="outlined"
                    className={
                      filter === "all" ? "chip-blue-fill" : "chip-blue"
                    }
                    onClick={() => {
                      setApplications([]);
                      setFilter("all");
                    }}
                  />
                </Col>
                <Col className="p-0 pe-2">
                  <Chip
                    label="Drafts"
                    variant="outlined"
                    // className="chip-blue"
                    className={
                      filter === "draft" ? "chip-blue-fill" : "chip-blue"
                    }
                    onClick={() => handleChangeFilter("draft")}
                  />
                </Col>
                <Col className="p-0 pe-2">
                  <Chip
                    label="Disbursed"
                    variant="outlined"
                    // className="chip-blue"
                    className={
                      filter === "disbursed" ? "chip-blue-fill" : "chip-blue"
                    }
                    onClick={() => handleChangeFilter("disbursed")}
                  />
                </Col>
                <Col className="p-0 pe-2">
                  <Chip
                    label="Rejected"
                    variant="outlined"
                    // className="chip-blue"
                    className={
                      filter === "rejected" ? "chip-blue-fill" : "chip-blue"
                    }
                    onClick={() => handleChangeFilter("rejected")}
                  />
                </Col>
                <Col className="p-0">
                  <Chip
                    label="Closed"
                    variant="outlined"
                    // className="chip-blue"
                    className={
                      filter === "closed" ? "chip-blue-fill" : "chip-blue"
                    }
                    onClick={() => handleChangeFilter("closed")}
                  />
                </Col>
              </Row>

              <Row
                className="mt-1"
                style={{
                  color: "#573A7A",
                  backgroundColor: "white",
                  paddingTop: 10,
                  paddingBottom: 1,
                }}
              >
                <Col className="font-blue d-flex justify-content-start">
                  {l("Application ID", lang)}
                </Col>
                <Col className="font-blue d-flex justify-content-start">
                  {l("Name", lang)}
                </Col>
                <Col className="font-blue d-flex justify-content-end">
                  {l("Stage", lang)}
                </Col>
              </Row>
            </Container>

            {/* {isSearching && (
            <p className="w-100 text-center mt-2 mb-2">
              <CircularProgress size={25} />
            </p>
          )} */}

            <InfiniteScroll
              dataLength={applications.length} //This is important field to render the next data
              next={() => {
                setPage(page + 1);
              }}
              hasMore={applications?.length < totalCount}
              loader={<LoadingComp />}
              endMessage={
                isLoading ? (
                  <LoadingComp />
                ) : (
                  applications?.length !== 0 && (
                    <p style={{ textAlign: "center", color: "gray" }}>
                      Loaded all the data 👍
                    </p>
                  )
                )
              }
              // below props only if you need pull down functionality
              // refreshFunction={this.refresh}
              // pullDownToRefresh
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: "center" }}>
              //     &#8595; Pull down to refresh
              //   </h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: "center" }}>
              //     &#8593; Release to refresh
              //   </h3>
              // }
            >
              <>
                {applications?.length !== 0 &&
                  applications?.map((val) => {
                    return (
                      <>
                        <Container
                          className="load-collapse-row collapse-blue pointer"
                          onClick={() => {
                            // setSelectedApplication(val?.id);
                            // fetchStage(
                            //   val?.id,
                            //   val?.customerId,
                            //   val?.current_stage
                            // );
                            MixPanelAudit.intercept("draft_application_click", {
                              distinct_id: user?.mobile_number[0],
                              agent_name: user?.name,
                              application_id: val?.application_id,
                            });
                            getStage(
                              val?.stage,
                              val?.application_id,
                              val?.coCustomerData ? true : false
                            );
                          }}
                        >
                          <Row className="mt-2">
                            <Col className="font-blue">
                              <p className="app-p">
                                <div
                                  style={{
                                    fontSize: 10,
                                  }}
                                >
                                  {val?.application_id}
                                </div>
                                <div
                                  style={{
                                    color: "rgb(156 174 192)",
                                    fontSize: 10,
                                  }}
                                >
                                  {val?.createdAt
                                    ? moment(val?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "---"}
                                </div>
                              </p>
                            </Col>
                            <Col className="font-blue">
                              <p className="app-p text-left">
                                {val?.customerData?.pan_details?.name}
                              </p>
                            </Col>
                            <Col className="font-blue">
                              <p
                                className="app-p text-end"
                                style={{
                                  fontSize: 10,
                                }}
                              >
                                {l(mapStage(val?.stage), lang)}
                              </p>
                            </Col>
                          </Row>
                        </Container>
                      </>
                    );
                  })}
                {!isLoading && applications.length === 0 && (
                  <p className="text-center mt-5">
                    <img src={emptyIco} />
                    <p style={{ color: "gray" }}>No Applications here</p>
                  </p>
                )}{" "}
              </>
            </InfiniteScroll>
          </Grid>
        </Grid>
      </Box>
      <ScrollButton />
    </>
  );
}
