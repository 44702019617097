import { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import usePermission from "../hooks/usePermission";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { incentiveData } from "../api/agent";
export const GlobalContext = createContext(null);

const GlobalContextProvider = ({ children }) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const bottomNav = useRef();
  const [splashLoaded, setSplashLoad] = useState(false);
  const [isDenied, setDenied] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [otpData, setOtpData] = useState({
    mobile: "",
    path: "",
    method: "",
    vId: "",
  });
  const [isResetVerified, setResetVerified] = useState(false);
  const [permissions, setPermissions] = useState({
    camera: "PROMPT",
    location: "PROMPT",
  });

  const [showPermission, setShowPermission] = useState({
    state: false,
    action: null,
  });

  const [lang, setLang] = useState("english");
  const [applicationPath, setAppPath] = useState("");
  const [isReward, showReward] = useState({ coin: 0, show: false });
  const [globalLoader, setGlobalLoader] = useState(false);
  const [isBetaEnabled, setBetaEnabled] = useState(true);
  const [docPopup, setDocPopup] = useState({
    open: false,
    type: "",
  });

  const [imageRenderData, setImageRenderData] = useState({
    isLoading: false,
    image: null,
    location: null,
    address: null,
  });

  const [tokenData, setTokenData] = useState({
    found: false,
    token: null,
  });

  const [notificationData, setNotification] = useState({
    open: false,
    title: "",
    body: "",
    image: "",
  });

  const [notiCount, setNotiCount] = useState(0)

  useEffect(() => {
    if (
      ["DENIED", "NOTAVAIL"].includes(permissions.camera) ||
      ["DENIED", "NOTAVAIL"].includes(permissions.location)
    ) {
      setDenied(true);
    } else {
      setDenied(false);
    }
  }, [permissions]);

  useEffect(() => {
    if (bottomNav.current) {
      if (isKeyboardOpen) {
        bottomNav.current.style.display = "none";
      } else {
        bottomNav.current.style.display = "block";
      }
    }
  }, [isKeyboardOpen]);

  const [coins, setCoins] = useState({});
  const getCoinData = async () => {
    try {
      let { data } = await incentiveData(false, true, "");
      if (data?.status) {
        setCoins({
          application: data?.applicationIncentiveData?.total_coins,
          overall: data?.totalAgentIncentiveData?.total_coins,
        });
      }
    } catch (err) {}
  };

  return (
    <GlobalContext.Provider
      value={{
        otpState: [otpData, setOtpData],
        resetState: [isResetVerified, setResetVerified],
        splashState: [splashLoaded, setSplashLoad],
        permissionState: [permissions, setPermissions],
        showPermissionState: [showPermission, setShowPermission],
        applicationState: [selectedApplication, setSelectedApplication],
        pathState: [applicationPath, setAppPath],
        showRewardState: [isReward, showReward],
        coinsState: [coins, setCoins],
        getCoinData,
        isDenied,
        bottomNav,
        globalLoaderState: [globalLoader, setGlobalLoader],
        langState: [lang, setLang],
        isBetaEnabled,
        imageRenderDataState: [imageRenderData, setImageRenderData],
        docPopupState: [docPopup, setDocPopup],
        tokenState: [tokenData, setTokenData],
        notificationState : [notificationData, setNotification],
        notiCountState : [notiCount, setNotiCount]
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
