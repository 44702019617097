import {
  BottomNavigation,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import MenuAppBar from "../../../../components/Appbar";
import {
  isBlank,
  isEmailValid,
  isMobValid,
} from "../../../../validations/fieldValidations";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../../../components/Select";
import { Col, Container, Row } from "react-bootstrap";
import {
  isVehicleCost,
  maxLoanAmountVani,
} from "../../../../validations/docValidations";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import { updateLoanData } from "../../../../api/agent";
import moment from "moment";
import useApplication from "../../../../hooks/useApplication";
import { l } from "../../../../util/languageConvertor";

// const marks = [
//   {
//     value: 12,
//     label: "12",
//   },
//   {
//     value: 15,
//     label: "15",
//   },
//   {
//     value: 18,
//     label: "18",
//   },
//   {
//     value: 21,
//     label: "21",
//   },
// ];
const marks = [
  {
    value: 12,
    label: "12",
  },
  {
    value: 15,
    label: "15",
  },
];

const LoanCreationVani = forwardRef((props, ref) => {
  const { application_id } = useParams();
  const { bottomNav, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const navigate = useNavigate();
  const selectRef = useRef();
  const refs = {
    ex_price: useRef(),
    battery_ah: useRef(),
  };

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const { enqueueSnackbar } = useSnackbar();
  const [isAccepted, setAccepted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showFinalCalculation, setFinalCalculation] = useState(false);
  const [maxLoan, setMaxLoan] = useState(0);
  const [proceed, setProceed] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({
    model: "",
    total_cost: "",
    down_pay: "",
    ex_price: "",
  });

  let isValid = async () => {
    let valid = true;
    let errors = {
      ex_price:
        Number(journeyData?.vehicle_price_ex) >=
        Number(journeyData?.vehicle_price_on_road)
          ? "Ex-showroom price cannot be higher than onroad price"
          : "",
      battery_ah:
        Number(journeyData?.battery_ah) < 100 ||
        Number(journeyData?.battery_ah) > 200
          ? "Battery Ah should be between 100-200"
          : "",
    };

    setFieldErrors(errors);

    let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        if (!first) {
          first = true;
          refs[i]?.current?.focus();
          window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        }
        valid = false;
      }
    }

    return valid;
  };

  useEffect(() => {
    setMaxLoan(
      maxLoanAmountVani(
        journeyData?.vehicle_price_ex,
        journeyData?.vehicle_price_on_road
      )
    );

    setJourneyData({
      ...journeyData,
      down_payment: "",
      interest_rate: "18",
      tenure: "12",
      interest_amount: "",
      processing_charge_rate: "",
      processing_charge_amount: "",
      emi_amount: "",
      edi_amount: "",
    });
  }, [journeyData?.vehicle_price_ex, journeyData?.vehicle_price_on_road]);

  useEffect(() => {
    let val =
      (Number(journeyData?.loan_amount) *
        (Number(journeyData.interest_rate) / 100) *
        Number(journeyData?.tenure)) /
      12;

    // ////////////////////////

    let startDate = moment(new Date());
    let endDate = moment(new Date()).add(Number(journeyData?.tenure), "months");

    let ediDays = moment(endDate).diff(moment(startDate), "days");

    let numerator =
      Number(journeyData?.loan_amount) +
      Number(journeyData?.loan_amount) *
        (Number(journeyData?.interest_rate) / 100) *
        (Number(journeyData?.tenure) / 12);

    // console.log(numerator);

    let emi = (numerator / Number(journeyData?.tenure)).toFixed(2);
    let edi = (numerator / ediDays).toFixed(2);
    let down_payment =
      Number(journeyData.vehicle_price_on_road) -
      Number(journeyData?.loan_amount);

    // ////////////////////////

    let tenure =
      Number(journeyData?.loan_amount) < 90000 ? 12 : journeyData?.tenure;

    setJourneyData((pre) => ({
      ...pre,
      down_payment,
      interest_amount: Math.ceil(val),
      emi_amount: Math.ceil(emi),
      edi_amount: Math.ceil(edi),
      tenure,
    }));
  }, [
    journeyData?.interest_rate,
    journeyData?.tenure,
    journeyData?.loan_amount,
  ]);

  // useEffect(() => {
  //   if (Number(journeyData?.loan_amount) < 90000) {
  //     setJourneyData({
  //       ...journeyData,
  //       tenure: "12",
  //     });
  //   }
  // }, [journeyData?.loan_amount]);

  // const handleSubmit = async () => {
  //   if (isValid()) {
  //     navigate(`/create-loan/loan-summary/${application_id}`);
  //   }
  // };

  const handleSubmit = async () => {
    if (!(await isValid())) {
      return;
    }
    setLoading(true);
    let payload = {
      vehicle_model: journeyData?.vehicle_model,
      battery_brand: journeyData?.battery_brand,
      battery_capacity: journeyData?.battery_ah,
      vehicle_price_on_road: journeyData?.vehicle_price_on_road,
      vehicle_price_ex_showroom: journeyData?.vehicle_price_ex,
      loan_amount: journeyData?.loan_amount,
      down_payment: journeyData?.down_payment,
      interest_rate: journeyData?.interest_rate,
      tenure_type: "months",
      tenure_value: journeyData?.tenure,
      processing_charge_rate: journeyData?.processing_charge_rate,
    };

    console.log(payload);

    try {
      let { data } = await updateLoanData(payload, application_id);
      if (data?.status) {
        navigate(`/create-loan/bank-details/${application_id}`);
      }
    } catch (err) {
      enqueueSnackbar(l(err?.response?.data?.message,lang), { variant: "error" });
    }
    setLoading(false);
  };

  const { getApplicationDetails } = useApplication();

  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    const {
      vehicle_model,
      vehicle_price_on_road,
      vehicle_price_ex,
      battery_brand,
      battery_ah,
      loan_amount,
      down_payment,
      interest_rate,
      tenure,
      interest_amount,
      processing_charge_rate,
      processing_charge_amount,
      emi_amount,
      edi_amount,
    } = journeyData;

    if (
      vehicle_model !== "" &&
      vehicle_model !== null &&
      vehicle_price_on_road !== "" &&
      vehicle_price_ex !== "" &&
      battery_brand !== "" &&
      battery_brand !== null &&
      battery_ah !== "" &&
      battery_ah !== null &&
      loan_amount !== "" &&
      down_payment !== "" &&
      interest_rate !== "" &&
      tenure !== "" &&
      interest_amount !== "" &&
      processing_charge_rate !== "" &&
      processing_charge_amount !== "" &&
      emi_amount !== "" &&
      edi_amount !== ""
    ) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [journeyData]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  useEffect(() => {
    setJourneyData({
      ...journeyData,
      loan_amount: maxLoan,
    });
  }, [maxLoan]);

  // useEffect(() => {
  //   setJourneyData({
  //     ...journeyData,
  //     loan_amount: maxLoan,
  //   });
  // }, [maxLoan]);

  return (
    <>
      <Box className="kyc-wrapper" sx={{ pb: 7, pt: 6 }} ref={ref}>
        <MenuAppBar home={false} noBack={true} draft={true} />
        <CssBaseline />
        <div className="main-wrapper">
          <>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 13 }}>
                  {l("Vehicle Model", lang)}{" "}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  required
                  placeholder={l("Vehicle Model", lang)}
                  autoComplete="family-name"
                  value={journeyData.vehicle_model}
                  onChange={(e) => {
                    setJourneyData({
                      ...journeyData,
                      vehicle_model: e.target.value,
                    });
                  }}
                  // onKeyUp={(e) =>
                  //   setFieldErrors((st) => ({
                  //     ...st,
                  //     model: isBlank(e.target.value),
                  //   }))
                  // }
                />
                {/* <span className="error">{fieldErrors.model}</span> */}
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 13 }}>
                  {l("Battery Brand", lang)}{" "}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  required
                  placeholder={l("Battery Brand", lang)}
                  autoComplete="family-name"
                  value={journeyData.battery_brand}
                  onChange={(e) => {
                    setJourneyData({
                      ...journeyData,
                      battery_brand: e.target.value,
                    });
                  }}
                  // onKeyUp={(e) =>
                  //   setFieldErrors((st) => ({
                  //     ...st,
                  //     model: isBlank(e.target.value),
                  //   }))
                  // }
                />
                {/* <span className="error">{fieldErrors.model}</span> */}
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 13 }}>
                  {l("Battery AH", lang)} <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  ref={refs?.battery_ah}
                  type="text"
                  className="form-input w-100 px-3 input-blue"
                  required
                  placeholder={l("Battery AH", lang)}
                  autoComplete="family-name"
                  value={journeyData.battery_ah}
                  onChange={(e) => {
                    if (
                      e.target.value !== "" &&
                      !/^\d+$/.test(e.target.value)
                    ) {
                      return false;
                    }
                    if (e.target.value.length > 3) return false;
                    setFieldErrors({ ...fieldErrors, battery_ah: "" });
                    setJourneyData({
                      ...journeyData,
                      battery_ah: e.target.value,
                    });
                  }}
                  // onKeyUp={(e) =>
                  //   setFieldErrors((st) => ({
                  //     ...st,
                  //     model: isBlank(e.target.value),
                  //   }))
                  // }
                />
                <span className="error">{fieldErrors?.battery_ah}</span>
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 13 }}>
                  {l("Vehicle Price On Road", lang)}{" "}
                  <span className="mand-star">*</span>
                </label>
              </Grid>
              <Grid item xs={6} sm={6}>
                <input
                  type="text"
                  className="form-input w-100 input-blue"
                  required
                  placeholder={l("Vehicle Price On Road", lang)}
                  inputMode="numeric"
                  autoComplete="family-name"
                  value={journeyData.vehicle_price_on_road}
                  maxLength="7"
                  onChange={(e) => {
                    if (
                      e.target.value !== "" &&
                      !/^\d+$/.test(e.target.value)
                    ) {
                      return false;
                    }
                    setJourneyData({
                      ...journeyData,
                      vehicle_price_on_road: e.target.value,
                    });
                  }}
                  // onBlur={(e) =>
                  //   setFieldErrors((st) => ({
                  //     ...st,
                  //     total_cost: isVehicleCost(e.target.value),
                  //   }))
                  // }
                />
                <span className="error">{fieldErrors.total_cost}</span>
              </Grid>

              <Grid item xs={6} sm={6}>
                <label className="form-label mt-2" style={{ fontSize: 13 }}>
                  {l("Vehicle Price Ex(showroom)", lang)}
                  <span className="mand-star">*</span>
                </label>
              </Grid>

              <Grid item xs={6} sm={6}>
                <input
                  ref={refs?.ex_price}
                  type="text"
                  className="form-input w-100 input-blue"
                  required
                  placeholder={l("Vehicle Price Ex(showroom)", lang)}
                  inputMode="numeric"
                  autoComplete="family-name"
                  value={journeyData.vehicle_price_ex}
                  maxLength="7"
                  onChange={(e) => {
                    if (
                      e.target.value !== "" &&
                      !/^\d+$/.test(e.target.value)
                    ) {
                      return false;
                    }
                    setFieldErrors({ ...fieldErrors, ex_price: "" });
                    setJourneyData({
                      ...journeyData,
                      vehicle_price_ex: e.target.value,
                    });
                  }}
                  // onBlur={(e) =>
                  //   setFieldErrors((st) => ({
                  //     ...st,
                  //     total_cost: isVehicleCost(e.target.value),
                  //   }))
                  // }
                />
                <span className="error">{fieldErrors?.ex_price}</span>
              </Grid>

              {!(
                isVehicleCost(journeyData?.vehicle_price_on_road || 0) !== "" ||
                isVehicleCost(journeyData?.vehicle_price_ex || 0) !== "" ||
                maxLoan < 50000
              ) && (
                <>
                  <Grid item xs={12} sm={12} className="content-center">
                    <Box sx={{ width: "95%" }}>
                      <Slider
                        aria-label="Restricted values"
                        defaultValue={maxLoan}
                        color={"secondary"}
                        value={Number(journeyData?.loan_amount)}
                        disabled={
                          isVehicleCost(journeyData.vehicle_price_on_road) !==
                            "" || maxLoan < 50000
                        }
                        onChange={(e) => {
                          setJourneyData((st) => ({
                            ...st,
                            loan_amount: e.target.value,
                          }));
                        }}
                        min={
                          isVehicleCost(journeyData.vehicle_price_on_road) ===
                            "" && 50000
                        }
                        max={maxLoan > 50000 && maxLoan}
                        step={maxLoan > 75000 ? 1000 : maxLoan - 50000}
                        marks={[
                          {
                            value: 50000,
                            label: "50000",
                          },
                          {
                            value: maxLoan,
                            label: maxLoan + "",
                          },
                        ]}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <label className="form-label mt-2" style={{ fontSize: 15 }}>
                      {l("Loan Amount", lang)}{" "}
                      <span className="mand-star">*</span>
                    </label>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <input
                      type="number"
                      className="form-input w-100 input-blue"
                      disabled
                      placeholder={l("Loan Amount", lang)}
                      name="number"
                      autoComplete="family-name"
                      value={journeyData.loan_amount}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <label className="form-label mt-2" style={{ fontSize: 15 }}>
                      {l("Down Payment", lang)}{" "}
                      <span className="mand-star">*</span>
                    </label>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <input
                      type="number"
                      className="form-input w-100 input-blue"
                      disabled
                      placeholder={l("Down Payment", lang)}
                      name="number"
                      autoComplete="family-name"
                      value={journeyData.down_payment}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <label className="form-label mt-2" style={{ fontSize: 15 }}>
                      {l("Interest Rate", lang)}{" "}
                      <span className="mand-star">*</span>
                    </label>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <input
                      type="text"
                      className="form-input w-100 input-blue"
                      disabled
                      placeholder={l("Interest Rate", lang)}
                      autoComplete="family-name"
                      value={journeyData.interest_rate + " %"}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={12} className="content-center">
                    <Box sx={{ width: "95%" }}>
                      <Slider
                        aria-label="Restricted values"
                        defaultValue={journeyData.interest_rate}
                        disabled={
                          isVehicleCost(journeyData.vehicle_price_on_road) !==
                            "" || maxLoan < 50000
                        }
                        min={15}
                        max={18}
                        step={0.5}
                        onChange={(e) => {  
                          setJourneyData((st) => ({
                            ...st,
                            interest_rate: e.target.value,
                          }));
                        }}
                        marks={marks}
                      />
                    </Box>
                  </Grid> */}

                  {journeyData?.loan_amount > 90000 && (
                    <Grid item xs={12} sm={12} className="content-center">
                      <Box sx={{ width: "95%" }}>
                        <Slider
                          aria-label="Restricted values"
                          color={"secondary"}
                          defaultValue={journeyData.tenure}
                          disabled={
                            isVehicleCost(journeyData.vehicle_price_on_road) !==
                              "" || maxLoan < 50000
                          }
                          min={12}
                          max={15}
                          step={3}
                          onChange={(e) => {
                            setJourneyData((st) => ({
                              ...st,
                              tenure: e.target.value,
                            }));
                          }}
                          marks={marks}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={6} sm={6}>
                    <label className="form-label mt-2" style={{ fontSize: 15 }}>
                      {l("Loan Tenure (In Months)", lang)}{" "}
                      <span className="mand-star">*</span>
                    </label>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <input
                      type="text"
                      className="form-input w-100 input-blue"
                      disabled
                      placeholder={l("Loan Tenure (In Months)", lang)}
                      autoComplete="family-name"
                      value={journeyData.tenure + " Months"}
                    />
                  </Grid>

                  {/* {journeyData?.edi_amount > 0 &&
                    journeyData?.emi_amount > 0 && (
                      <Grid item xs={12} sm={12} className="loan-collapse">
                        <Container className="load-collapse-head">
                          <Row>
                            <Col>
                              <span>{l("EMI Amount", lang)}</span>
                            </Col>
                            <Col>
                              <span>{l("EDI Amount", lang)}</span>
                            </Col>
                          </Row>
                        </Container>

                        <Container className="load-collapse-row pt-3">
                          <Row>
                            <Col className="font-blue">
                              ₹{journeyData.emi_amount}
                            </Col>
                            <Col className="font-blue">
                              ₹{journeyData.edi_amount}
                            </Col>
                          </Row>
                        </Container>
                      </Grid>
                    )} */}

                  {journeyData?.edi_amount > 0 &&
                    journeyData?.emi_amount > 0 && (
                      <Grid item xs={12} sm={12} className="loan-collapse">
                        <Container
                          className="installment-row pt-3"
                          style={{ borderRadius: "20px 0px 0 0" }}
                        >
                          <Row>
                            <Col className="font-blue d-flex justify-content-start">
                              {l("EMI Amount", lang)}
                            </Col>
                            <Col className="font-blue">
                              ₹{journeyData.emi_amount}
                            </Col>
                          </Row>
                        </Container>

                        <Container
                          className="installment-row pt-3"
                          style={{ borderRadius: "0 0 20px 0px" }}
                        >
                          <Row>
                            <Col className="font-blue d-flex justify-content-start">
                              {l("EDI Amount", lang)}
                            </Col>
                            <Col className="font-blue">
                              ₹{journeyData.edi_amount}
                            </Col>
                          </Row>
                        </Container>
                      </Grid>
                    )}

                  <Grid item xs={6} sm={6}>
                    <label
                      className="form-label mt-2"
                      style={{ fontSize: 14, color: "blue !important" }}
                    >
                      {l("Interest Amount", lang)}{" "}
                      <span className="mand-star">*</span>
                    </label>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <input
                      type="text"
                      className="form-input w-100 px-3 input-blue"
                      disabled
                      autoComplete="family-name"
                      value={journeyData?.interest_amount}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <label
                      className="form-label mt-2"
                      style={{ fontSize: 14, color: "blue !important" }}
                    >
                      {l("File Charge Rate", lang)} (%)
                      <span className="mand-star">*</span>
                    </label>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <input
                      type="number"
                      className="form-input w-100 px-3 input-blue"
                      autoComplete="family-name"
                      value={journeyData?.processing_charge_rate}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        if (e.target.value > 10) {
                          return false;
                        }
                        setJourneyData((st) => ({
                          ...st,
                          processing_charge_rate: e.target.value,
                          processing_charge_amount:
                            (Number(e.target.value) / 100) *
                            journeyData?.loan_amount,
                        }));
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <label
                      className="form-label mt-2"
                      style={{ fontSize: 14, color: "blue !important" }}
                    >
                      {l("File Charge Amount", lang)}
                      <span className="mand-star">*</span>
                    </label>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <input
                      type="text"
                      className="form-input w-100 px-3 input-blue"
                      disabled
                      autoComplete="family-name"
                      value={Number(
                        journeyData?.processing_charge_amount
                      )?.toFixed(2)}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        </div>

        <Paper className="paper-bottom" elevation={3} ref={bottomNav}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className={`btn-submit ${!proceed && "btn-submit-disabled"}`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={!proceed || isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Proceed", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default LoanCreationVani;
