import moment from "moment";

export const validateDob = (value) => {
  let date = value.split("/");
  let day = date[0];
  let month = date[1];
  let year = date[2];

  if (/^[0-9]{2}$/.test(month)) {
    if (month !== "02") {
     
      if(day === "31"){
        if(["01", "03", "05", "07", "08", "10", "12"].includes(month)){
          day='31'
        }else if(["04", "06", "09", "11"].includes(month)){
          day='30'
        }
      }
      
      // if (
      //   day === "30" &&
      //   ["01", "03", "05", "07", "08", "10", "12"].includes(month)
      // ) {
      //   day = "31";
      // } else if (day === "31" && ["04", "06", "09", "11"].includes(month)) {
      //   day = "30";
      // }
    } else {
      if (day > "29") {
        day = "29";
      }
    }
  }

  if (/^[0-9]{4}$/.test(year) && day === "29") {
    if (!leapyear(year) && month === "02") {
      day = "28";
    }
  }

  return `${day}/${month}/${year}`;
};

function leapyear(year) {
  return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
}

export const ageValidate = (date) => {
  let age = moment().diff(moment(date, "DD-MM-YYYY"), "years");
  if (age > 59 || age < 21) {
    return "Age must be between 21-59";
  }
  return "";
};

export const validateInvoiceDate = (date) => {
  if (date.length > 0 && !date?.includes("_")) {
    date =
      date.split("/")[2] + "/" + date.split("/")[1] + "/" + date.split("/")[0];
    let today = moment().format("YYYY/MM/DD");
    let min = moment(moment().subtract(1, "months")).format("YYYY/MM/DD");

    if (moment(date).isBetween(min, today, null, "[]")) return "";
    return "Should not be more than one month old";
  } else {
    return "Provide Valid date";
  }
};

export const validateMonthYear = (date) => {
  if (date.length > 0 && !date?.includes("_")) {
    return "";
  } else {
    return "Provide Valid date";
  }
};
