import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCustomerData, getLoanData } from "../api";
import { useContext } from "react";
import { CustomerContext } from "../Contexts/CustomerContext";
import { LoanContext } from "../Contexts/LoanJourneyContext";
import { fetchApplicationData } from "../api/agent";
import { useEffect } from "react";
import { GlobalContext } from "../Contexts/GlobalContext";

export default function useApplication() {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { globalLoaderState } = useContext(GlobalContext);
  const [globalLoader, setGlobalLoader] = globalLoaderState;

  const navigate = useNavigate();

  // const authloan = () => {
  //   if(vehicleDetails?.id !== "") return true;
  //   else return false;
  // }

  const getValue = (value, defaultValue) => {
    if (!value || value === null || value === undefined || value === "") {
      return defaultValue;
    } else {
      return value;
    }
  };

  const getApplicationDetails = async (
    application_id,
    load = false,
    setLoader
  ) => {
    if (journeyData?.loaded && !load) {
      console.log("Already loaded");
      return;
    }
    if (setLoader) {
      setLoader(true);
    }
    setGlobalLoader(true);
    console.log("loading");
    try {
      let { data: response } = await fetchApplicationData(application_id);

      console.log(response);
      const { data, current_stage, application_stage_progress } = response;
      const { customerData, coCustomerData, anchorData, nbfcData } = data;

      setJourneyData((st) => ({
        ...st,
        application_id,
        loaded: true,
        isPanOcr: true,
        pan_number: customerData?.pan_details?.number,
        pan_image: "",
        aadhaar_front: "",
        aadhaar_back: "",

        customer_id: customerData?.customer_id || "",
        full_name: customerData?.pan_details?.name,
        date_of_birth: customerData?.dob ? customerData?.dob : "",
        aadhaar_address: customerData?.aadhaar_details?.address,
        aadhaar_pincode: customerData?.aadhaar_details?.address_information
          ?.pincode
          ? customerData?.aadhaar_details?.address_information?.pincode
          : "",
        is_address_same:
          customerData?.residential_address?.is_aadhaar_address_same,
        current_address: customerData?.residential_address?.address
          ? customerData?.residential_address?.address
          : "",
        current_pincode: customerData?.residential_address?.pincode
          ? customerData?.residential_address?.pincode
          : "",
        is_property_owned: customerData?.residential_address?.property_owned,

        is_aadhaar_property_owned:
          customerData?.aadhaar_details?.is_aadhaar_address_owned,

        mobile_number: customerData?.mobile_number
          ? customerData?.mobile_number
          : "",
        email_id: customerData?.email_id ? customerData?.email_id : "",
        credit_pull_allow: data?.credit_pull?.consent,
        creditPullData: data?.credit_pull,
        coCustomerCreditPullData: data?.coCustomerData?.credit_pull,

        customer_pan_uploaded: customerData?.is_pan_uploaded,

        gender: customerData?.gender ? customerData?.gender : "",

        is_psychometric_done: data?.other_details?.psychometric_data
          ? true
          : false,

        co_customer_exist: coCustomerData ? true : false,
        co_customer_type: coCustomerData?.customer_type
          ? coCustomerData?.customer_type
          : "CO-APPLICANT",
        co_customer_id: coCustomerData?.id ? coCustomerData?.id : "",
        co_customer_full_name: coCustomerData?.pan_details?.name
          ? coCustomerData?.pan_details?.name
          : "",
        co_customer_pan_number: coCustomerData?.pan_details?.number
          ? coCustomerData?.pan_details?.number
          : "",
        co_customer_date_of_birth: coCustomerData?.dob
          ? coCustomerData?.dob
          : "",
        co_customer_aadhaar_address: coCustomerData?.aadhaar_details?.address
          ? coCustomerData?.aadhaar_details?.address
          : "",
        co_customer_aadhaar_pincode: coCustomerData?.aadhaar_details
          ?.address_information?.pincode
          ? coCustomerData?.aadhaar_details?.address_information?.pincode
          : "",
        co_customer_is_address_same:
          coCustomerData?.residential_address?.is_aadhaar_address_same,
        co_customer_current_address: coCustomerData?.residential_address
          ?.address
          ? coCustomerData?.residential_address?.address
          : "",
        co_customer_current_pincode: coCustomerData?.residential_address
          ?.pincode
          ? coCustomerData?.residential_address?.pincode
          : "",
        co_customer_is_property_owned: coCustomerData?.residential_address
          ?.property_owned
          ? coCustomerData?.residential_address?.property_owned
          : null,
        // co_customer_is_aadhaar_property_owned: coCustomerData?.aadhaar_details
        //   ?.is_aadhaar_address_owned
        //   ? coCustomerData?.aadhaar_details?.is_aadhaar_address_owned
        //   : null,
        co_customer_is_aadhaar_property_owned:
          coCustomerData?.aadhaar_details?.is_aadhaar_address_owned,
        co_customer_email_id: coCustomerData?.email
          ? coCustomerData?.email
          : "",

        co_customer_gender: coCustomerData?.gender
          ? coCustomerData?.gender
          : "",

        co_customer_mobile_number: coCustomerData?.mobile_number
          ? coCustomerData?.mobile_number
          : "",

        co_customer_email_id: coCustomerData?.email_id
          ? coCustomerData?.email_id
          : "",

        post_disbursement_docs: data?.documents?.post_disbursement
          ? data?.documents?.post_disbursement
          : [],
        field_investigation_docs: data?.documents?.field_investigation
          ? data?.documents?.field_investigation
          : [],

        documents: data?.documents ? data?.documents : [],
        customer_documents: data?.customerData?.documents
          ? data?.customerData?.documents
          : [],
        co_customer_documents: coCustomerData?.documents
          ? coCustomerData?.documents
          : [],

        reference1_name: data?.other_details?.references?.[0]?.name
          ? data?.other_details?.references?.[0]?.name
          : "",
        reference1_mob: data?.other_details?.references?.[0]?.mobile_number
          ? data?.other_details?.references?.[0]?.mobile_number
          : "",
        reference1_relation: data?.other_details?.references?.[0]
          ?.relation_with_borrower
          ? data?.other_details?.references?.[0]?.relation_with_borrower
          : "",
        reference2_name: data?.other_details?.references?.[1]?.name
          ? data?.other_details?.references?.[1]?.name
          : "",
        reference2_mob: data?.other_details?.references?.[1]?.mobile_number
          ? data?.other_details?.references?.[1]?.mobile_number
          : "",
        reference2_relation: data?.other_details?.references?.[1]
          ?.relation_with_borrower
          ? data?.other_details?.references?.[1]?.relation_with_borrower
          : "",

        penny_drop_status: data?.bank_details?.penny_drop_status || null,

        // Vehicle Details

        vehicle_model: data?.other_details?.vehicle_model || "",
        vehicle_price_on_road: data?.other_details?.vehicle_price_on_road,
        vehicle_price_ex: data?.other_details?.vehicle_price_ex_showroom,
        battery_brand: data?.other_details?.battery_brand || "",
        battery_ah: data?.other_details?.battery_capacity || "",
        loan_amount: data?.loan_amount,
        down_payment: data?.down_payment,
        interest_rate: data?.annual_interest_rate,
        tenure: data?.tenure_value,
        interest_amount: data?.interest_amount,
        processing_charge_rate: data?.processing_charge_rate,
        processing_charge_amount: data?.processing_charge,

        // Enach

        enach_status: data?.enach_status,
        upi_status: data?.upi_autopay_status,
        upi_link_sent: data?.upi_autopay_data?.link_sent,

        is_disbursed: data?.is_disbursed,
        disbursed_amount: data?.disbursed_amount,
        disbursed_date: data?.disbursed_date,
        stage: current_stage,
        percentages: {
          ...application_stage_progress,
        },

        // Physical Nach

        physical_nach_status: data?.physical_nach_status?.created || false,
        physical_nach_uploaded: data?.documents?.nach_form?.length > 0 || false,
        mandate_id: data?.physical_nach_data?.mandate_id,

        // Field investigation

        is_field_investigation_done:
          Object.keys(data?.field_investigation_agent || {})?.length > 0,
        anchor_name: anchorData?.name,
        total_members: getValue(
          customerData?.other_details?.family?.family_members ||
            data?.other_details?.family?.family_members,
          ""
        ),
        earning_members: getValue(
          customerData?.other_details?.family?.earning_members ||
            data?.other_details?.family?.earning_members,
          ""
        ),
        family_income: getValue(
          customerData?.other_details?.family?.amount_earned ||
            data?.other_details?.family?.amount_earned,
          ""
        ),
        engine_number: getValue(data?.other_details?.vehicle_engine_no, ""),
        chasis_no: getValue(data?.other_details?.vehicle_chasis_no, ""),
        customer_photo_with_vehicle: "",
        customer_house_inside: "",
        customer_house_outside: "",
        customer_photo_with_electricity_meter: "",
        premise_accessibility: getValue(
          data?.other_details?.accessbility_till_premise,
          ""
        ),
        fi_remark: getValue(data?.other_details?.remarks, ""),
        investigator_name: getValue(data?.field_investigation_agent?.name, ""),
        investigator_mobile: getValue(
          data?.field_investigation_agent?.mobile_number,
          ""
        ),

        application_remark: data?.embifi_approval_details?.remark,
        showRejectedPopup: data?.other_details?.rejected_documents?.length > 0,
        utr: data?.utr,
        // nbfc Data

        nbfcData,
      }));

      // console.log(window.location.pathname);
      // console.log(current_stage);
      await getStage(
        current_stage,
        application_id,
        coCustomerData ? true : false
      );
      setGlobalLoader(false);
      if (setLoader) setLoader(false);
    } catch (err) {
      console.log(err);
      setJourneyData({ ...journeyData, stage: "FAILED" });
      setGlobalLoader(false);
      if (setLoader) setLoader(false);
      return false;
    }
  };

  const getStage = async (stage, application_id, isCocustomer = false) => {
    switch (stage) {
      case "CUSTOMER_BASIC_DETAILS":
        navigate(`/create-loan/pan-details/${application_id}`);
        break;
      case "CUSTOMER_EMBIFI_REJECTED":
        navigate(`/create-loan/status/${application_id}`);
        break;
      case "CUSTOMER_CREDIT_ENGINE_FAILED":
        navigate(`/create-loan/status/${application_id}`);
        break;
      case "CUSTOMER_UNDER_REVIEW":
        navigate(`/create-loan/status/${application_id}`);
        break;
      case "CO_CUSTOMER_CREATION":
        navigate(`/create-loan/status/${application_id}`);
        break;
      case "CO_CUSTOMER_RECREATION":
        navigate(`/create-loan/co-customer/${application_id}`);
        break;
      case "CO_CUSTOMER_BASIC_DETAILS":
        navigate(`/create-loan/co-customer-details/${application_id}`);
        break;
      case "CO_CUSTOMER_EMBIFI_REJECTED":
        navigate(`/create-loan/status-cocustomer/${application_id}`);
        break;
      case "CO_CUSTOMER_CREDIT_ENGINE_FAILED":
        navigate(`/create-loan/status-cocustomer/${application_id}`);
        break;
      case "CO_CUSTOMER_UNDER_REVIEW":
        navigate(`/create-loan/status-cocustomer/${application_id}`);
        break;
      // case "LOAN_UPDATE":
      //   navigate(`/create-loan/loan-creation/${application_id}`);
      //   break;
      case "NBFC_ASSIGN_PENDING":
        navigate(`/create-loan/status/${application_id}`);
        break;

      case "LOAN_UPDATE":
        // if (
        //   window.location.pathname.includes("/create-loan/documents-upload/")
        // ) {
        //   return;
        // }

        if (isCocustomer) {
          navigate(`/create-loan/status-cocustomer/${application_id}`);
        } else {
          navigate(`/create-loan/status/${application_id}`);
        }
        break;
      case "BANK_DETAILS":
        navigate(`/create-loan/bank-details/${application_id}`);
        break;

      case "LOAN_OFFER":
        navigate(`/create-loan/loan-summary/${application_id}`);
        break;

      case "PRE_DISBURSEMENT":
        navigate(`/create-loan/documents-upload/${application_id}`);
        break;

      case "APPLICATION_STAGE_PROGRESSION": {
        if (
          window.location.pathname.includes("/create-loan/documents-upload/")
        ) {
          return;
        }
        navigate(`/create-loan/documents-upload/${application_id}`);
        break;
      }
      case "PENNY_DROP_FAILED": {
        navigate(`/create-loan/loan-status/${application_id}`);
        break;
      }
      case "PAYMENT_METHOD_SELECTION": {
        if (window.location.pathname.includes("/create-loan/payment-method/")) {
          return;
        }
        navigate(`/create-loan/payment-method/${application_id}`);
        break;
      }
      case "WAITING_FOR_NBFC_APPROVAL":
        navigate(`/create-loan/loan-status/${application_id}`);
        break;
      case "WAITING_FOR_DISBURSAL":
        navigate(`/create-loan/loan-status/${application_id}`);
        break;
      case "DISBURSED":
        navigate(`/create-loan/loan-status/${application_id}`);
        break;
    }
  };

  // const getRealStatus = (enach_status) => {
  //   if (enach_status === null || enach_status === undefined) {
  //     return "pending";
  //   } else {
  //     if (enach_status) {
  //       return "successfull";
  //     } else {
  //       return "unsuccessfull";
  //     }
  //   }
  // };

  const getRealStatus = (enach_status, key) => {
    if (enach_status === null || enach_status === undefined) {
      if (key === "upi" && journeyData?.upi_link_sent) {
        return "Link Sent";
      }
      return "pending";
    } else {
      if (enach_status) {
        return "successfull";
      } else {
        return "unsuccessfull";
      }
    }
  };

  const docCount = (docList, list) => {
    let count = 0;

    list.forEach((item) => {
      count += docList?.[item]?.length > 0 ? 1 : 0;
    });

    return count;
  };

  const calculatePending = (docs) => {
    // console.log(docs);
    let docStatus = {
      customer: docCount(docs, [
        "customer_photo",
        "other_ownership_proof",
        "driving_license",
      ]),
      co_customer: docCount(docs, [
        "co_customer_photo",
        "co_customer_ownership_proof",
        "co_customer_dl",
      ]),
      loan_document: docCount(docs, [
        "loan_agreement",
        "stamp_paper",
        "dual_form",
      ]),
      vehicle_document: docCount(docs, [
        "form_26_35",
        "sales_invoice",
        "form_22",
        "erick_delivery_photo",
        "chassis_number",
        "battery_warranty",
        "insurance",
        "form_20",
        "trc",
        "form_21",
      ]),
      bank_document: docCount(docs, ["cheques", "bsv", "bank_passbook"]),
    };
    return docStatus;
  };

  return {
    getApplicationDetails,
    getStage,
    getRealStatus,
    calculatePending,
  };
}
