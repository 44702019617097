import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../../components/Appbar";
import waitIcon from "../../../../assets/wait-glass.svg";
import waitIconGif from "../../../../assets/hourglass.gif";

import { GlobalContext } from "../../../../Contexts/GlobalContext";
import useApplication from "../../../../hooks/useApplication";
import { l } from "../../../../util/languageConvertor";
import { CircularProgressbar } from "react-circular-progressbar";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
// import TimeInput from "../../Components/DateInput";

const UnderReview = forwardRef(({ origin }, ref) => {
  const { bottomNav, langState } = useContext(GlobalContext);
  const [lang, setLang] = langState;

  const [isLoading, setLoading] = useState(false);
  const [timer, setTimer] = useState({
    minutes: 0,
    seconds: 0,
    started: false,
  });
  const [isExpired, setExpired] = useState(true);
  const [timeRemain, setTimeRemain] = useState(0);
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleBack = () => {
    navigate("/draft-applications");
  };

  const style = {
    reviewTxt: {
      fontSize: "30px",
      fontWeight: "900",
      color: "#151940",
    },
    tagTxt: {
      padding: "0 20px",
      color: "#151940",
    },
  };

  const { application_id } = useParams();
  const { getApplicationDetails } = useApplication();

  const startTimer = async (time) => {
    var countDownDate = new Date(time).getTime() + 1 * 60000;
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      // var hours = Math.floor(
      //   (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      // );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTimer({ minutes, seconds, started: true });
      setTimeRemain(`${minutes}.${seconds}`);
      if (distance < 0) {
        clearInterval(x);
        setExpired(true);
        console.log("Expired");
        handleRefresh();
      }
    }, 1000);
  };

  const handleRefresh = async () => {
    setLoading(true);
    await getApplicationDetails(application_id, true);
    setLoading(false);
  };

  const isTimeExpired = (time) => {
    return time < Date.now();
  };

  useEffect(() => {
    console.log(journeyData);
    if (origin === "co-customer") {
      if (
        journeyData?.coCustomerCreditPullData?.next_runtimeMS &&
        !isTimeExpired(
          journeyData?.coCustomerCreditPullData?.next_runtimeMS + 1 * 60000
        )
      ) {
        startTimer(journeyData?.coCustomerCreditPullData?.next_runtimeMS);
        setExpired(false);
      }
    } else {
      if (
        journeyData?.creditPullData?.next_runtimeMS &&
        !isTimeExpired(journeyData?.creditPullData?.next_runtimeMS + 1 * 60000)
      ) {
        startTimer(journeyData?.creditPullData?.next_runtimeMS);
        setExpired(false);
      }
    }
  }, [journeyData]);

  function padNumberWithZero(number) {
    if (number < 10 && number >= 0) {
      return "0" + number;
    } else {
      return number.toString();
    }
  }

  return (
    <>
      <Box className="" ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div
          className="main-wrapper"
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "90vh",
          }}
        >
          <div>
            <p className="text-center">
              <img width={"200px"} src={waitIconGif} alt="wait" />
            </p>
            <p className="text-center" style={style?.reviewTxt}>
              {l("Under review", lang)}
            </p>
            <p className="text-center" style={style?.tagTxt}>
              {isExpired
                ? l(
                    "Loan application is being reviewed by the team.We will get back shortly",
                    lang
                  )
                : l(
                    "Loan application is being reviewed by the team. Please refresh after ",
                    lang
                  )}
            </p>

            {!isExpired && (
              <>
                {timer?.started ? (
                  <div className="content-center mt-4">
                    <div className="timer-digit d-flex">
                      <div className="me-1 time-holder">
                        {padNumberWithZero(timer?.minutes)}
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        :
                      </div>
                      <div className="ms-1 time-holder">
                        {padNumberWithZero(timer?.seconds)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-center mt-3">
                    <CircularProgress />
                  </p>
                )}
              </>
            )}
          </div>
        </div>

        {isExpired && (
          <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
            <BottomNavigation style={{ height: "auto" }}>
              <div className="button-wrapper w-100">
                <Button
                  className="btn-submit"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    handleRefresh();
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress
                        className="me-2 mb-1"
                        size={20}
                        style={{ color: "white" }}
                      />
                      {l("Loading", lang)}
                    </>
                  ) : (
                    l("Refresh", lang)
                  )}
                </Button>
              </div>
            </BottomNavigation>
          </Paper>
        )}
      </Box>
    </>
  );
});

export default UnderReview;
