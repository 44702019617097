import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DocumentCard from "../../../../components/V3/DocumentCard";
import UploadCard from "../../../../components/V3/UploadCard";
import chequeIco from "../../../../assets/tabs/chequeIco.svg";
import bsvIco from "../../../../assets/tabs/bsvIco.svg";
import passbookIco from "../../../../assets/tabs/passbookIco.svg";
import addmoreIco from "../../../../assets/tabs/addmoreIco.svg";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import { uploadDocument } from "../../../../api/agent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuAppBar from "../../../../components/Appbar";
import { useNavigate } from "react-router-dom";
import MixPanelAudit from "../../../../util/MixPanelAudit";
import { UserContext } from "../../../../Contexts/UserContext";
import { l } from "../../../../util/languageConvertor";
import { GlobalContext } from "../../../../Contexts/GlobalContext";

const BankDocuments = ({ application_id, openStatus }) => {
  const { journeyState } = useContext(LoanContext);
  const navigate = useNavigate();
  const [journeyData, setJourneyData] = journeyState;
  const { getApplicationDetails } = useApplication();
  const [multiData, setMultiData] = useState({ show: false });
  const [otherDocs, setOtherDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);

  const { langState, docPopupState } = useContext(GlobalContext);
  const [lang, setLang] = langState;
  const [docPopup, setDocPopup] = docPopupState;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async (file, name, overide = true) => {
    setLoading(name);
    try {
      let { data } = await uploadDocument(
        {
          document_included_g: true,
          docs: [
            {
              name,
              file,
            },
          ],
        },
        application_id,
        overide ? `?override=true` : ""
      );
      if (data?.mandatory_completed) {
        setDocPopup({ open: true, type: "DONE" });
      }
      getApplicationDetails(application_id, true);
    } catch (error) {}
    setLoading("");
  };

  useEffect(() => {
    let doc = journeyData?.documents?.other_documents;
    if (doc?.length > 0) {
      setOtherDocs([...doc, { name: `other_documents_${doc?.length}` }]);
    } else {
      setOtherDocs([{ name: "other_documents_1" }]);
    }

    // console.log(journeyData?.documents?.cheques?.[5] ? true : false);
  }, [journeyData?.documents]);

  return (
    <div>
      <MenuAppBar
        home={false}
        action={() => {
          navigate(`/draft-applications`);
        }}
        shadow={true}
        leftContent={
          <Button
            color="secondary"
            onClick={() => {
              MixPanelAudit.intercept("check_status_click", {
                distinct_id: user?.mobile_number[0],
                agent_name: user?.name,
                application_id,
              });
              openStatus();
            }}
          >
            {l("Check Status", lang)}
          </Button>
        }
      />
      <CssBaseline />
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={6} sm={6}>
          {/* <DocumentCard tempState={""} text={"Cheques"} icon={chequeIco} /> */}
          <div
            className="documents-card-v3 pointer"
            onClick={() => {
              handleClickOpen();
            }}
          >
            <div>
              <p className="mb-2 text-center">
                <img
                  src={chequeIco}
                  alt=""
                  width={60}
                  height={60}
                  style={{ objectFit: "contain" }}
                />
              </p>
              <p className="heading mb-0">
                {l("Cheques", lang)} <span className="mand-star">*</span>
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <DocumentCard
            text={l("BSV", lang)}
            isRequired={true}
            icon={bsvIco}
            tempState={
              journeyData?.documents?.bsv?.slice(-1)[0]?.fileLink || ""
            }
            isUpload={journeyData?.documents?.bsv?.length > 0}
            status={journeyData?.documents?.bsv?.slice(-1)[0]?.isApproved}
            isLoading={loading === "bsv"}
            setFile={(file) => {
              handleUpload(file, "bsv");
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <DocumentCard
            text={l("Bank Passbook", lang)}
            isRequired={true}
            icon={passbookIco}
            tempState={
              journeyData?.documents?.bank_passbook?.slice(-1)[0]?.fileLink ||
              ""
            }
            isUpload={journeyData?.documents?.bank_passbook?.length > 0}
            status={
              journeyData?.documents?.bank_passbook?.slice(-1)[0]?.isApproved
            }
            isLoading={loading === "bank_passbook"}
            setFile={(file) => {
              handleUpload(file, "bank_passbook");
            }}
          />
        </Grid>
        {otherDocs?.map((doc, index) => {
          return (
            <Grid item xs={6} sm={6}>
              <DocumentCard
                text={
                  index === otherDocs?.length - 1
                    ? l("Add More", lang)
                    : doc?.name?.replaceAll("_", " ")
                }
                icon={addmoreIco}
                tempState={doc?.fileLink || ""}
                isUpload={doc?.length > 0}
                status={doc?.isApproved}
                isLoading={loading === "other_documents"}
                setFile={(file) => {
                  handleUpload(file, "other_documents", false);
                }}
              />
            </Grid>
          );
        })}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{l("Cheques", lang)}</DialogTitle>
        <DialogContent>
          <div className="doc-modal mt-5">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <DocumentCard
                  text={l("Cheque", lang) + " 1"}
                  isRequired={true}
                  icon={chequeIco}
                  tempState={
                    journeyData?.documents?.cheques?.[0]?.fileLink || ""
                  }
                  isUpload={journeyData?.documents?.cheques?.[0]}
                  status={journeyData?.documents?.cheques?.[0]?.isApproved}
                  isLoading={loading === "cheques"}
                  setFile={(file) => {
                    handleUpload(file, "cheques", false);
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <DocumentCard
                  text={l("Cheque", lang) + " 2"}
                  isRequired={true}
                  icon={chequeIco}
                  tempState={
                    journeyData?.documents?.cheques?.[1]?.fileLink || ""
                  }
                  isUpload={journeyData?.documents?.cheques?.[1]}
                  status={journeyData?.documents?.cheques?.[1]?.isApproved}
                  isLoading={loading === "cheques"}
                  setFile={(file) => {
                    handleUpload(file, "cheques", false);
                  }}
                  disabled={
                    !journeyData?.documents?.cheques?.length ||
                    journeyData?.documents?.cheques?.length < 1
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <DocumentCard
                  text={l("Cheque", lang) + " 3"}
                  isRequired={true}
                  icon={chequeIco}
                  tempState={
                    journeyData?.documents?.cheques?.[2]?.fileLink || ""
                  }
                  isUpload={journeyData?.documents?.cheques?.[2]}
                  status={journeyData?.documents?.cheques?.[2]?.isApproved}
                  isLoading={loading === "cheques"}
                  setFile={(file) => {
                    handleUpload(file, "cheques", false);
                  }}
                  disabled={
                    !journeyData?.documents?.cheques?.length ||
                    journeyData?.documents?.cheques?.length < 2
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <DocumentCard
                  text={l("Cheque", lang) + " 4"}
                  icon={chequeIco}
                  tempState={
                    journeyData?.documents?.cheques?.[3]?.fileLink || ""
                  }
                  isUpload={journeyData?.documents?.cheques?.[3]}
                  status={journeyData?.documents?.cheques?.[3]?.isApproved}
                  isLoading={loading === "cheques"}
                  setFile={(file) => {
                    handleUpload(file, "cheques", false);
                  }}
                  disabled={
                    !journeyData?.documents?.cheques?.length ||
                    journeyData?.documents?.cheques?.length < 3
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <DocumentCard
                  text={l("Cheque", lang) + " 5"}
                  icon={chequeIco}
                  tempState={
                    journeyData?.documents?.cheques?.[4]?.fileLink || ""
                  }
                  isUpload={journeyData?.documents?.cheques?.[4]}
                  status={journeyData?.documents?.cheques?.[4]?.isApproved}
                  isLoading={loading === "cheques"}
                  setFile={(file) => {
                    handleUpload(file, "cheques", false);
                  }}
                  disabled={
                    !journeyData?.documents?.cheques?.length ||
                    journeyData?.documents?.cheques?.length < 4
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <DocumentCard
                  text={l("Cheque", lang) + " 6"}
                  icon={chequeIco}
                  tempState={
                    journeyData?.documents?.cheques?.[5]?.fileLink || ""
                  }
                  isUpload={journeyData?.documents?.cheques?.[5]}
                  status={journeyData?.documents?.cheques?.[5]?.isApproved}
                  isLoading={loading === "cheques"}
                  setFile={(file) => {
                    handleUpload(file, "cheques", false);
                  }}
                  disabled={
                    !journeyData?.documents?.cheques?.length ||
                    journeyData?.documents?.cheques?.length < 5
                  }
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{l("Close", lang)}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BankDocuments;
