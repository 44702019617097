import { Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserAvatar from "../../components/Avatar";
import { UserContext } from "../../Contexts/UserContext";
import { FILE_URL } from "../../util/constants";

const ViewProfile = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  return (
    <>
      <div className="content-center">
        <Grid container spacing={2} className="p-3 response-wrapper">
          <Grid item xs={12} sm={12} className="mt-2">
            <p className="w-100 content-center">
              <img
                className="profile-pic"
                style={{ width: "80px", height: "80px" }}
                src={FILE_URL + `user=dealer&id=${user?.id}&type=selfie`}
                alt={user?.name}
              />
            </p>
            <p className="text-center mb-2 profile-text company-name">
              {user?.business_details?.name}
            </p>
            <p className="text-center mb-2 profile-text user-name">
              {user.name}
            </p>
          </Grid>
          <Grid item xs={12} sm={12} className="mt-4">
            <Button
              className="w-100 home-btn"
              variant="outlined"
              onClick={() => navigate("/under-construction")}
            >
              Business Details
            </Button>

            <Button
              className="w-100 home-btn mt-2"
              variant="outlined"
              onClick={() => navigate("/under-construction")}
            >
              KYC Documents
            </Button>

            <Button
              className="w-100 home-btn mt-2"
              variant="outlined"
              onClick={() => {
                if (!user?.gstStage?.gst || !user?.gstStage?.trade_license) {
                  navigate("/kyc/gst");
                } else {
                  navigate("/under-construction");
                }
              }}
            >
              {(() => {
                if (!user?.gstStage?.gst || !user?.gstStage?.trade_license) {
                  return "Business Documents (Pending)";
                } else {
                  return "Business Documents";
                }
              })()}
            </Button>

            <Button
              className="w-100 home-btn mt-2"
              variant="outlined"
              onClick={() => {
                if (!user?.bankStage) {
                  navigate("/kyc/bank-details");
                } else {
                  navigate("/under-construction");
                }
              }}
            >
              {(() => {
                if (!user?.bankStage) {
                  return "Banks Details (Pending)";
                } else {
                  return "Banks Details";
                }
              })()}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ViewProfile;
