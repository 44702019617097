import {
  BottomNavigation,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ageValidate, validateDob } from "../../../validations/dateValidations";
// import { createCoCustomer, createCustomer, verifyPincode } from "../../../api/";
import MenuAppBar from "../../../components/Appbar";
import TimeInput from "../../../components/DateInput";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import useApplication from "../../../hooks/useApplication";
import {
  deleteCoCustomer,
  updateCoCustomer,
  verifyPincode,
} from "../../../api/agent";
import {
  isBlank,
  isEmailValid,
  isMobValid,
  isStartSpace,
} from "../../../validations/fieldValidations";
import { l } from "../../../util/languageConvertor";
import WebView from "../../../components/V3/WebView";
// import TimeInput from "../../Components/DateInput";

const radioStyle = {
  color: "#573A7A",
  "&.Mui-checked": {
    color: "#573A7A",
  },
};

const labelStyle = {
  color: "gray",
};

const CoCustomerDetails = forwardRef((props, ref) => {
  const refs = {
    dob: useRef(null),
    aadhaar_address: useRef(null),
    aadhaar_pincode: useRef(null),
    current_address: useRef(null),
    current_pincode: useRef(null),
    is_property_owned: useRef(null),
    is_aadhaar_property_owned: useRef(null),
    mobile_number: useRef(null),
    gender: useRef(null),
    email: useRef(null),
  };

  const { bottomNav, otpState, langState } = useContext(GlobalContext);
  const [otpData, setOtpData] = otpState;
  const [lang, setLang] = langState;

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fieldErrors, setErrors] = useState({});

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [webviewData, setWebView] = useState({ open: false, url: "" });

  const navigate = useNavigate();
  const { application_id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const { getApplicationDetails } = useApplication();

  const handleInput = (e, setState) => {
    let result = validateDob(e.target.value);
    setState((st) => ({ ...st, co_customer_date_of_birth: result }));
  };

  const validDate = (date, key) => {
    if (date.length === 0 || date?.includes("_")) {
      return "Provide a valid date";
    } else {
      // if (key === "dob") {
      //   if (date.length > 0 && !date?.includes("_")) {
      //     return ageValidate(journeyData?.co_customer_date_of_birth);
      //   }
      // }

      return "";
    }
  };

  const isValid = async () => {
    console.log(journeyData?.co_customer_gender);
    let valid = true;
    let errors = {
      dob: validDate(journeyData.co_customer_date_of_birth, "dob"),
      aadhaar_address: isBlank(journeyData?.co_customer_aadhaar_address),
      aadhaar_pincode: await isPinValid(
        journeyData?.co_customer_aadhaar_pincode
      ),
      current_address:
        ![null, undefined, ""].includes(
          journeyData?.co_customer_is_address_same
        ) && journeyData?.co_customer_is_address_same
          ? ""
          : isBlank(journeyData?.co_customer_current_address),
      current_pincode:
        ![null, undefined, ""].includes(
          journeyData?.co_customer_is_address_same
        ) && journeyData?.co_customer_is_address_same
          ? ""
          : await isPinValid(journeyData?.co_customer_current_pincode),
      // is_property_owned: [null, undefined, ""].includes(
      //   journeyData?.co_customer_is_property_owned
      // )
      //   ? "Select any option"
      //   : "",

      is_property_owned:
        ![null, undefined, ""].includes(
          journeyData?.co_customer_is_address_same
        ) && journeyData?.co_customer_is_address_same
          ? ""
          : [null, undefined, ""].includes(
              journeyData?.co_customer_is_property_owned
            )
          ? "Select any option"
          : "",

      is_aadhaar_property_owned: [null, undefined, ""].includes(
        journeyData?.co_customer_is_aadhaar_property_owned
      )
        ? "Select any option"
        : "",
      mobile_number: isMobValid(journeyData?.co_customer_mobile_number),
      gender: [null, undefined, ""].includes(journeyData?.co_customer_gender)
        ? "Select gender"
        : "",
      email: isEmailValid(journeyData?.co_customer_email_id),
    };

    console.log(errors);

    setErrors((prev) => ({ ...prev, ...errors }));

    // if (state.full_name === "" || state.pan_number === "") valid = false;
    let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        if (!first) {
          first = true;
          refs[i]?.current?.focus();
          window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        }
        valid = false;
      }
    }

    return valid;
  };

  const isPinValid = async (pincode, key) => {
    try {
      let { data } = await verifyPincode(pincode);
      if (Object.keys(data).length !== 0) {
        return "";
      } else {
        return "Invalid pincode";
      }
    } catch (error) {
      return "Pincode validation failed";
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (await isValid()) {
      if (journeyData?.co_customer_credit_pull_allow) {
        let payload = {
          dob: journeyData?.co_customer_date_of_birth,
          aadhaar_pincode: journeyData?.co_customer_aadhaar_pincode + "",
          aadhaar_address: journeyData?.co_customer_aadhaar_address,
          same_address_as_aadhaar: journeyData?.co_customer_is_address_same,

          is_property_owned: journeyData?.co_customer_is_address_same
            ? journeyData?.co_customer_is_aadhaar_property_owned
            : journeyData?.co_customer_is_property_owned,

          is_aadhaar_address_owned:
            journeyData?.co_customer_is_aadhaar_property_owned,
          mobile_number: journeyData?.co_customer_mobile_number + "",
          email: journeyData?.co_customer_email_id,
          credit_consent: journeyData?.co_customer_credit_pull_allow,
          residential_address: journeyData?.co_customer_current_address + "",
          residential_pincode: journeyData?.co_customer_current_pincode + "",
          gender: journeyData?.co_customer_gender,
          application_id,
        };

        try {
          let { data } = await updateCoCustomer(
            payload,
            journeyData?.co_customer_id
          );
          if (data?.status) {
            setOtpData((st) => ({
              ...st,
              mobile: journeyData?.co_customer_mobile_number,
              path: "create-loan/status-cocustomer",
              method: "create-Cocustomer",
              vId: data?.verificationId,
              application_id,
            }));
            navigate(`/sent-otp/${data?.verificationId}`);
          }
        } catch (err) {
          let error = "";
          if (err?.response?.data?.message) {
            if (err?.response?.data?.message === "Bad request parameters") {
              error = "Please re-check all the inputs";
            } else {
              error = err?.response?.data?.message;
            }
          } else {
            error = "Something went wrong";
          }
          enqueueSnackbar(l(error,lang), { variant: "error" });
        }
      } else {
        enqueueSnackbar(l("Please accept the credit pull consent",lang));
      }
    }
    setLoading(false);
  };

  const handleCancelCo = async () => {
    setLoading(true);
    try {
      let { data } = await deleteCoCustomer(application_id);
      if (data?.status) {
        getApplicationDetails(application_id, true);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(l(error?.response?.data?.message,lang) || "Something wrong", {
        variant: "error",
      });
    }
    setOpen(false);
    setLoading(false);
  };

  const handleBack = async () => {
    navigate("/draft-applications");
  };

  useEffect(() => {
    getApplicationDetails(application_id, true);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/draft-applications");
    };
  });

  useEffect(() => {
    console.log(journeyData?.co_customer_id);
    console.log(journeyData?.co_customer_is_property_owned);
    console.log(journeyData?.co_customer_is_aadhaar_property_owned);
  }, [journeyData]);

  return (
    <>
      <Box className="kyc-wrapper" sx={{ pb: 7, pt: 7 }} ref={ref}>
        <MenuAppBar
          home={false}
          action={handleBack}
          leftContent={
            <Button
              style={{ color: "#502bac" }}
              onClick={() => {
                setOpen(true);
              }}
            >
              {l("Cancel Co-customer", lang)}
            </Button>
          }
        />
        <CssBaseline />
        <div className="main-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Name", lang)} ( As per PAN)
              </label>
              <input
                className="form-input w-100"
                autoComplete="given-name"
                name="firstName"
                fullWidth
                disabled
                placeholder="Enter your first name"
                autoFocus
                value={journeyData.co_customer_full_name}
              />
              <span className="error"></span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">{l("PAN Number", lang)}</label>
              <input
                className="form-input w-100"
                required
                fullWidth
                disabled
                placeholder="Enter your PAN number"
                autoComplete="family-name"
                value={journeyData.co_customer_pan_number}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">{l("Date of Birth", lang)} </label>

              <TimeInput
                ref={refs.dob}
                value={journeyData.co_customer_date_of_birth}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, dob: "" });
                  handleInput(e, setJourneyData);
                }}
                onBlur={() => {
                  if (journeyData.co_customer_date_of_birth === "__/__/____")
                    setJourneyData((st) => ({
                      ...st,
                      co_customer_date_of_birth: "",
                    }));
                  setErrors((prev) => ({
                    ...prev,
                    date_of_birth: validDate(
                      journeyData.co_customer_date_of_birth,
                      "dob"
                    ),
                  }));
                }}
              ></TimeInput>

              {/* <input
                  className="form-input w-100"
                  required
                  id="dob"
                  disabled
                  placeholder=""
                  name="dob"
                  autoComplete="family-name"
                  // value={pPanDetails.dob}
                /> */}

              <span className="error">{fieldErrors.dob}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Gender", lang)} <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.gender}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={journeyData.co_customer_gender}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, gender: "" });
                  setJourneyData({
                    ...journeyData,
                    co_customer_gender: e.target.value,
                  });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"Male"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Male", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"Female"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Female", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{fieldErrors.gender}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Aadhaar Address", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <textarea
                ref={refs.aadhaar_address}
                className="form-input-area w-100"
                required
                fullWidth
                placeholder={l("Aadhaar Address", lang)}
                autoComplete="family-name"
                value={journeyData.co_customer_aadhaar_address}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, aadhaar_address: "" });
                  setJourneyData({
                    ...journeyData,
                    co_customer_aadhaar_address: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.aadhaar_address}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Aadhaar Pincode", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.aadhaar_pincode}
                className="form-input w-100"
                required
                fullWidth
                placeholder={l("Aadhaar Pincode", lang)}
                autoComplete="family-name"
                value={journeyData.co_customer_aadhaar_pincode}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, aadhaar_pincode: "" });
                  setJourneyData({
                    ...journeyData,
                    co_customer_aadhaar_pincode: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.aadhaar_pincode}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Is this aadhaar property owned by customer?", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.is_aadhaar_property_owned}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={
                  journeyData?.co_customer_is_aadhaar_property_owned !== null
                    ? journeyData.co_customer_is_aadhaar_property_owned
                      ? "yes"
                      : "no"
                    : null
                }
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    co_customer_is_aadhaar_property_owned:
                      e.target?.value === "yes" ? true : false,
                  });
                  setErrors({ ...fieldErrors, is_aadhaar_property_owned: "" });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Yes", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"no"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("No", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">
                {fieldErrors.is_aadhaar_property_owned}
              </span>
              {/* <span className="error">{fieldErrors.owned}</span> */}
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={journeyData?.co_customer_is_address_same}
                  />
                }
                label={l("Same As Aadhaar Address", lang)}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    co_customer_is_address_same: e.target.checked,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Current Residential address", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <textarea
                ref={refs.current_address}
                className="form-input-area w-100"
                required
                fullWidth
                placeholder={l("Current Residential address", lang)}
                autoComplete="family-name"
                disabled={journeyData?.co_customer_is_address_same}
                value={
                  journeyData?.co_customer_is_address_same
                    ? journeyData?.co_customer_aadhaar_address
                    : journeyData?.co_customer_current_address
                }
                onChange={(e) => {
                  setErrors({ ...fieldErrors, current_address: "" });
                  setJourneyData({
                    ...journeyData,
                    co_customer_current_address: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.current_address}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                {l("Current Residential pincode", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.current_pincode}
                className="form-input w-100"
                required
                fullWidth
                disabled={journeyData?.co_customer_is_address_same}
                placeholder={l("Current Residential pincode", lang)}
                autoComplete="family-name"
                value={
                  journeyData?.co_customer_is_address_same
                    ? journeyData?.co_customer_aadhaar_pincode
                    : journeyData?.co_customer_current_pincode
                }
                onChange={(e) => {
                  setErrors({ ...fieldErrors, current_pincode: "" });
                  setJourneyData({
                    ...journeyData,
                    co_customer_current_pincode: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.current_pincode}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                {l("Is current address property owned by customer?", lang)}{" "}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.is_property_owned}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={
                  journeyData?.co_customer_is_address_same
                    ? journeyData.co_customer_is_aadhaar_property_owned !== null
                      ? journeyData.co_customer_is_aadhaar_property_owned
                        ? "yes"
                        : "no"
                      : null
                    : journeyData?.co_customer_is_property_owned !== null
                    ? journeyData.co_customer_is_property_owned
                      ? "yes"
                      : "no"
                    : null
                }
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    co_customer_is_property_owned:
                      e.target?.value === "yes" ? true : false,
                  });
                  setErrors({ ...fieldErrors, is_property_owned: "" });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("Yes", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"no"}
                      control={<Radio sx={radioStyle} />}
                      label={
                        <span style={{ fontSize: "10px" }}>
                          {l("No", lang)}
                        </span>
                      }
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{fieldErrors.is_property_owned}</span>
              {/* <span className="error">{fieldErrors.owned}</span> */}
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                {l("Mobile Number", lang)} <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.mobile_number}
                type="text"
                className="form-input w-100"
                required
                fullWidth
                placeholder={l("Mobile Number", lang)}
                name="number"
                autoComplete="family-name"
                maxLength={10}
                inputMode="numeric"
                value={journeyData.co_customer_mobile_number}
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 10) return false;
                  setErrors({ ...fieldErrors, mobile_number: "" });
                  setJourneyData((st) => ({
                    ...st,
                    co_customer_mobile_number: e.target.value,
                  }));
                }}
                onKeyUp={
                  (e) => {}
                  // setFieldErrors((st) => ({
                  //   ...st,
                  //   mobile: isMobValid(e.target.value),
                  // }))
                }
              />
              <span className="error">{fieldErrors.mobile_number}</span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">{l("Email Id", lang)} </label>
              <input
                ref={refs.email}
                type="text"
                className="form-input w-100 px-3"
                required
                placeholder={l("Email Id", lang)}
                style={{ fontSize: "11.5px" }}
                name="email"
                autoComplete="family-name"
                value={journeyData.co_customer_email_id}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, email: "" });
                  setJourneyData((st) => ({
                    ...st,
                    co_customer_email_id: e.target.value,
                  }));
                }}
                onKeyUp={
                  (e) => {}
                  // setFieldErrors((st) => ({
                  //   ...st,
                  //   email: isEmailValid(e.target.value),
                  // }))
                }
              />
              <span className="error">{fieldErrors.email}</span>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControlLabel
              className="mb-2"
              control={<Checkbox color="success" />}
              onChange={(e) =>
                setJourneyData({
                  ...journeyData,
                  co_customer_credit_pull_allow: e.target.checked,
                })
              }
              defaultChecked={journeyData?.co_customer_credit_pull_allow}
              label={
                <span className="checkbox-txt" style={{ fontSize: 12 }}>
                    I hereby authorize Embifi to pull my credit report in
                    accordance with the{" "}
                    <Link
                      onClick={(e) => {
                        setWebView({
                          open: true,
                          url: "https://www.embifi.in/terms",
                        });
                        e.preventDefault();
                      }}
                    >
                      Terms of Use
                    </Link>{" "}
                    and the{" "}
                    <Link
                      onClick={(e) => {
                        setWebView({
                          open: true,
                          url: "https://www.embifi.in/privacy-policy",
                        });
                        e.preventDefault();
                      }}
                    >
                      Privacy Policy
                    </Link>{" "}
                    of the Company
                  </span>
              }
            />
          </Grid>
        </div>

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    {l("Loading", lang)}
                  </>
                ) : (
                  l("Check Eligibility", lang)
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {l(
            "Are you sure to remove this co-customer? This operation cannot be undone?",
            lang
          )}
        </DialogContent>
        <DialogActions>
          {!isLoading ? (
            <>
              <Button
                style={{ color: "#502bac" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                {l("Cancel", lang)}
              </Button>
              <Button
                style={{ color: "#502bac" }}
                onClick={handleCancelCo}
                autoFocus
              >
                {l("Confrim", lang)}
              </Button>
            </>
          ) : (
            <Button disabled>{l("Loading", lang)}...</Button>
          )}
        </DialogActions>
      </Dialog>

      <WebView
        open={webviewData?.open}
        url={webviewData?.url}
        handleClose={() => {
          setWebView({ open: false, url: "" });
        }}
      />
    </>
  );
});

export default CoCustomerDetails;
