import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Buffer } from "buffer";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/Camera";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EXIF from "exif-js";

import { FiCamera, FiImage, FiX } from "react-icons/fi";
import { GrGallery } from "react-icons/gr";

import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Webcam from "react-webcam";
import { compressImage, getBase64 } from "../util/convertFileBase";
import { CircularProgress, Grid } from "@mui/material";
import ImageCropper from "./Crop";
import PermissionModal from "./PermissionModal";
import usePermission from "../hooks/usePermission";
import { useContext } from "react";
import { GlobalContext } from "../Contexts/GlobalContext";
import { useLocation } from "react-router-dom";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import LoadingOver from "./LoadingOver";
import CropScreen from "./CropScreen";
import useImageWithLocation from "./V3/useImageWithLocation";
import { useSnackbar } from "notistack";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CatureUpload({
  setFile,
  error,
  errorTxt,
  onSelect,
  text,
  tempState,
  disabled,
  ref,
  isLocation,
}) {
  const { showPermissionState } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isSelfie, setSelfie] = useState(false);
  const [captureImage, setImage] = useState("");
  const [confirmFilename, setConfirmFilename] = useState("");
  const [isInput, setInput] = useState(false);
  const [pdfFile, setPdf] = useState("");
  const [isLoading, setLoading] = React.useState(false);
  const { permissions, getUserLocation, getLocationV2 } = usePermission();
  const [showPermission, setShowPermission] = showPermissionState;
  const [geoLocation, setLocation] = useState({});

  const fileInput = useRef();
  const cameraInput = useRef();
  const sliderBottom = useRef();
  const backdrop = useRef();

  const [cropImgTemp, setTempCrop] = useState("");
  const [croppedImg, setCropImg] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    // setOpen(true);
    // setShowPermission((st) => ({ ...setConfirmFilename, state: false }));
    // sliderBottom.current.classList.remove("close");
    // backdrop.current.classList.remove("hide");

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      fileInput.current.click();
      setInput(true);
    } else {
      setShowPermission((st) => ({ ...setConfirmFilename, state: false }));
      sliderBottom.current.classList.remove("close");
      backdrop.current.classList.remove("hide");
    }
  };
  const handleClose = () => {
    // setOpen(false);
    setTimeout(() => {
      if (sliderBottom.current) {
        sliderBottom.current.classList.add("close");
        backdrop.current.classList.add("hide");
      }
    }, 100);
  };

  const handleRecapture = () => {
    fileInput.current.value = "";
    setCropImg("");
    setConfirmFilename("");
    setPdf("");
    setImage("");
    setInput(true);
    setFile && setFile("");
    cameraInput.current.click();
  };

  useEffect(() => {
    if (!open) {
      setIsCameraOpen(false);
      setImage("");
    }
  }, [open]);

  useEffect(() => {
    if (croppedImg !== "") {
      setFile && setFile(croppedImg, geoLocation);
    }
  }, [croppedImg]);

  useEffect(() => {
    if (pdfFile !== "") {
      setFile && setFile(pdfFile);
    }
  }, [pdfFile]);

  // const onSelectFile = async (e) => {
  //   onSelect && onSelect();
  //   if (e.target.files && e.target.files.length > 0) {
  //     if (!e.target.files[0].type.match("application/pdf")) {
  //       setError && setError("Please upload pdf file only");
  //       return false;
  //     } else if (e.target.files[0].size >= 5 * 1024 * 1024) {
  //       setError && setError("Select files below 5mb");
  //       return;
  //     }

  //     setFileName(e.target.files[0].name);
  //     setFile(await getBase64(e.target.files[0]));
  //   }
  // };

  const handleUserMedia = () => setTimeout(() => setLoading(false), 1_000);
  const { getImageWithLocation } = useImageWithLocation();

  return (
    <>
      <>
        {tempState === "" ? (
          <div
            className={`capture-upload-div-v3 pointer mb-2 p-3 ${
              error && "capture-error"
            } ${disabled && "capture-disabled"}`}
            onClick={() => {
              // if (permissions.camera === "PROMPT") {
              //   setShowPermission((st) => ({
              //     ...st,
              //     state: true,
              //     action: handleClickOpen,
              //   }));
              // } else {
              if (!disabled) {
                // handleClickOpen();
                cameraInput.current.click();
                setInput(true);
              }
              // }
            }}
          >
            <div
              className="upload-content-v2"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p ref={ref} className="upload-txt-v2">
                <CloudUploadIcon className="me-2" sx={{ fontSize: 25 }} />
                {text ? text : "Click here to Upload/Capture"}
              </p>
              {/* <p className="max-size-txt">Max File Size: 5 Mb</p> */}
            </div>
          </div>
        ) : (
          <>
            {!isInput && (
              <>
                <div
                  className={`w-100 bg-dark d-flex justify-content-center ${
                    error && "capture-error"
                  }`}
                  style={{ borderRadius: "8px", overflow: "hidden" }}
                >
                  <img
                    src={tempState}
                    alt=""
                    width={"100%"}
                    height={"200px"}
                    style={{ objectFit: "contain" }}
                  />
                </div>
                {errorTxt && (
                  <span className="error mt-2 mb-3">
                    <ErrorOutlineIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {errorTxt}
                  </span>
                )}
              </>
            )}

            {isInput && (
              <>
                <p className="text-center">
                  <DescriptionIcon style={{ fontSize: "50px" }} />
                </p>
                <p
                  className="text-center"
                  style={{
                    fontSize: "10px",
                    color: "gray",
                    padding: "0 50px",
                  }}
                >
                  {confirmFilename}
                </p>
              </>
            )}
            <p className="mb-4 text-center mt-2">
              <span className="recapture-btn" onClick={handleRecapture}>
                <CameraAltIcon className="me-2" /> Press to Re-capture/Upload
              </span>
            </p>
          </>
        )}
      </>

      <div
        className="slider-backdrop hide"
        ref={backdrop}
        onClick={() => {
          handleClose();
        }}
      ></div>
      <div ref={sliderBottom} class="slider close">
        <Grid container spacing={2}>
          <Grid className="content-center" item xs={6} sm={6}>
            <Button
              className="bottom-menu-button"
              onClick={() => {
                cameraInput.current.click();
                setInput(true);
              }}
            >
              <div>
                <CameraAltOutlinedIcon sx={{ fontSize: 40 }} />
                <p className="w-100">Camera</p>
              </div>
            </Button>
          </Grid>
          <Grid className="content-center" item xs={6} sm={6}>
            <Button
              className="bottom-menu-button"
              onClick={() => {
                fileInput.current.click();
                setInput(true);
              }}
            >
              <div>
                <FiImage className="bottom-menu-ico" />
                <p className="w-100">Gallery</p>
              </div>
            </Button>

            <input
              ref={cameraInput}
              accept="image/*"
              capture="environment"
              type="file"
              class="custom-file-input"
              // accept="application/pdf , image/png, image/jpg, image/jpeg"
              onChange={async (e) => {
                onSelect && onSelect();
                if (e.target.files[0]["type"].split("/")[0] == "image") {
                  if (e.target.files[0].type.match("image/svg")) {
                    alert("Not supported file");
                    return false;
                  }
                  setInput(false);
                  setImage(
                    await getBase64(await compressImage(e.target.files[0]))
                  );
                } else {
                  alert("Image only");
                  handleRecapture();
                }

                e.target.value = "";
              }}
              onClick={(e) => {
                e.target.value = "";
              }}
              hidden
            />

            <input
              ref={fileInput}
              type="file"
              class="custom-file-input"
              accept="application/pdf , image/png, image/jpg, image/jpeg"
              onChange={async (e) => {
                onSelect && onSelect();
                if (e.target.files[0].type.match("application/pdf")) {
                  setInput(true);
                  setPdf(await getBase64(e.target.files[0]));
                  setConfirmFilename(e.target.files[0].name);
                } else if (e.target.files[0]["type"].split("/")[0] == "image") {
                  if (e.target.files[0].type.match("image/svg")) {
                    alert("Not supported file");
                    return false;
                  }
                  setInput(false);
                  setImage(
                    await getBase64(await compressImage(e.target.files[0]))
                  );
                } else {
                  alert("Pdf/Image only");
                  handleRecapture();
                }
                e.target.value = "";
                handleClose();
              }}
              hidden
            />
          </Grid>
        </Grid>
      </div>

      <CropScreen
        open={captureImage !== "" && !isInput}
        image={captureImage}
        onClose={() => {
          setImage("");
        }}
        onCrop={async (file) => {
          if (isLocation) {
            setLoading(true);
            try {
              let data = await getLocationV2();
              let loc = {
                lat: data?.coords?.latitude,
                long: data?.coords?.longitude,
                imageLocation: true
              };
              setLocation(loc);
              setCropImg(await getImageWithLocation(loc, file));
            } catch (err) {
              enqueueSnackbar("Location not found", { variant: "error" });
              return false;
            }
            setLoading(false);
          } else {
            setCropImg(file);
          }
          setConfirmFilename("capture.jpg");
          setImage("");
          handleClose();
        }}
        onRecapture={() => {
          handleRecapture();
          fileInput.current.click();
        }}
        isLoading={isLoading}
      />
      {/* {renderCanvas()} */}
    </>
  );
}
